import _ from 'lodash'
import { Box, Checkbox } from '@mui/material'
import { red } from '@mui/material/colors'
import ColumnFilter from '../../components/column-filter'
import { ProductUtils } from '../../utils'
import { ISku, ISkuStats } from '../../shared/interfaces-v2'
import dayjs from '../../utils/dayjs.utils'
import { useLayerAsinSidebar } from './Layers/LayerAsinSidebar'
import { useLayerSkuSidebar } from './Layers/LayerSkuSidebar'

export const CUSTOM_CELL = {
  SELECTION: ({ row }: any) => {
    return (
      <Box
        sx={{
          background: (row.original as ISku).isDeleted ? red[50] : undefined,
        }}
      >
        <Checkbox {...row.getToggleRowSelectedProps()} />
      </Box>
    )
  },
  PRE_WRAP_TEXT: ({ value }: any) => (
    <div style={{ whiteSpace: 'pre-wrap' }}>{_.isNil(value) ? '-' : value}</div>
  ),
  IMAGE: ({ value }: any) => <img src={value} alt="" width={40} height={40} />,
  NUMBER: (decimal: number = 2) => {
    return ({ value }: any) =>
      _.isNil(value) ? '-' : Math.round(value * 10 ** decimal) / 10 ** decimal
  },
}

export interface ITableColumn {
  accessor: string
  Cell?: any
  Filter?: any
  disableSortBy?: boolean
  sortType?: Function
  fixedWidth?: number
  disabledVisibilitySetting?: boolean
}

export interface ISortTypeParams {
  original: ISku
}

export const PRODUCT_LABEL = {
  imageUrl: 'Image',
  asinCode: 'Asin',
  fnsku: 'FNSKU',
  details: 'Details',
  assignee: 'Assignee',
  cogs: 'COGS',
  ship: 'Ship',
  notes: 'Notes',
  lSet: 'L_SET',
  wSet: 'W_SET',
  hSet: 'H_SET',
  gwSet: 'GW_SET',
  lCtn: 'L_CTN',
  wCtn: 'W_CTN',
  hCtn: 'H_CTN',
  qtCtn: 'QT_CTN',
  gwCtn: 'GW_CTN',
  editAsin: 'Edit Asin',
  vwExpSet: 'VW Exp/Set',
  vwSeaSet: 'VW Sea/Set',
  cbmSet: 'CBM/set',
  avaFC: 'Ava+FC',
  sales30d: 'Sales 30d',
  inShip3: 'In ship, 3',
  close6: 'C6',
  close4: 'C4',
  close3d5: 'C3.5',
  close2d5: 'C2.5',
  close1d5: 'C1.5',
  close1: 'C1',
  close0d5: 'C0.5',
  inShip3Fw: 'In ship, 3, FW',
  close6Fw: 'C6, FW',
  close4Fw: 'C4, FW',
  close3d5Fw: 'C3.5, FW',
  close2d5Fw: 'C2.5, FW',
  close1d5Fw: 'C1.5, FW',
  close1Fw: 'C1, FW',
  close0d5Fw: 'C0.5, FW',
  waitForBuying: 'Wait for buying',
  assignmentDate: 'Assignment Date',
  goingToFw: 'Bought & Shipping',
  estimatedReceivedDate: 'Est. Received Date',
  inChineseStock: 'In Chinese Stock',
  receivedDate: 'Received Date',
  vwExp: 'V.W exp',
  vwSea: 'V.W sea',
  cbm: 'CBM',
  gw: 'G.W.',
  editSku: 'Edit Sku',
  'inboundItems.0.quantityShipped': 'SL1',
  'inboundItems.1.quantityShipped': 'SL2',
  'inboundItems.2.quantityShipped': 'SL3',
  'inboundItems.3.quantityShipped': 'SL4',
  'inboundItems.0.shipmentId': 'URL1',
  'inboundItems.1.shipmentId': 'URL2',
  'inboundItems.2.shipmentId': 'URL3',
  'inboundItems.3.shipmentId': 'URL4',
  'inboundItems.0.createdAt': 'Creation Date 1',
  'inboundItems.1.createdAt': 'Creation Date 2',
  'inboundItems.2.createdAt': 'Creation Date 3',
  'inboundItems.3.createdAt': 'Creation Date 4',
  'inboundItems.0.estimatedDeliveryDate': 'Estimate Delivery 1',
  'inboundItems.1.estimatedDeliveryDate': 'Estimate Delivery 2',
  'inboundItems.2.estimatedDeliveryDate': 'Estimate Delivery 3',
  'inboundItems.3.estimatedDeliveryDate': 'Estimate Delivery 4',
}

interface ITableRow {
  row: {
    original: ISkuStats
  }
}

const BASE_COLUMNS: ITableColumn[] = [
  {
    accessor: 'imageUrl',
    Cell: ({ row: { original } }: ITableRow) => {
      return <img src={original.imageUrl} alt="" width={40} height={40} />
    },
    disableSortBy: true,
    disabledVisibilitySetting: true,
  },
  {
    accessor: 'asinCode',
    disableSortBy: true,
    disabledVisibilitySetting: true,
  },
  {
    accessor: 'fnsku',
    Cell: CUSTOM_CELL.PRE_WRAP_TEXT,
    disableSortBy: true,
    disabledVisibilitySetting: true,
  },
  {
    accessor: 'details',
    Cell: ({ row: { original } }: ITableRow) => {
      return `${original.asinName ?? '[no-name]'} / ${original.price ?? '[no-price]'} USD`
    },
    fixedWidth: 400,
    disableSortBy: true,
  },
  {
    accessor: 'assignee',
    Filter: ColumnFilter,
    disableSortBy: true,
  },
  {
    accessor: 'editAsin',
    Cell: ({ row }: ITableRow) => {
      const { openSidebarEditAsin } = useLayerAsinSidebar()
      return (
        <button
          onClick={() => {
            openSidebarEditAsin(row.original.asinId)
          }}
          data-testid="btn-edit-asin"
        >
          Edit Asin
        </button>
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'cogs',
    disableSortBy: true,
  },
  {
    accessor: 'ship',
    disableSortBy: true,
  },
  {
    accessor: 'notes',
    disableSortBy: true,
  },
  {
    accessor: 'lSet',
    disableSortBy: true,
  },
  {
    accessor: 'wSet',
    disableSortBy: true,
  },
  {
    accessor: 'hSet',
    disableSortBy: true,
  },
  {
    accessor: 'gwSet',
    disableSortBy: true,
  },
  {
    accessor: 'lCtn',
    disableSortBy: true,
  },
  {
    accessor: 'wCtn',
    disableSortBy: true,
  },
  {
    accessor: 'hCtn',
    disableSortBy: true,
  },
  {
    accessor: 'qtCtn',
    disableSortBy: true,
  },
  {
    accessor: 'gwCtn',
    disableSortBy: true,
  },
  {
    accessor: 'vwExpSet',
    disableSortBy: true,
  },
  {
    accessor: 'vwSeaSet',
    disableSortBy: true,
  },
  {
    accessor: 'cbmSet',
    disableSortBy: true,
  },
  {
    accessor: 'avaFC',
  },
  {
    accessor: 'sales30d',
  },
  {
    accessor: 'inShip3',
  },
  {
    accessor: 'close6',
  },
  {
    accessor: 'close4',
  },
  {
    accessor: 'close3d5',
  },
  {
    accessor: 'close2d5',
  },
  {
    accessor: 'close1d5',
  },
  {
    accessor: 'close1',
  },
  {
    accessor: 'close0d5',
  },
  {
    accessor: 'inShip3Fw',
  },
  {
    accessor: 'close6Fw',
  },
  {
    accessor: 'close4Fw',
  },
  {
    accessor: 'close3d5Fw',
  },
  {
    accessor: 'close2d5Fw',
  },
  {
    accessor: 'close1d5Fw',
  },
  {
    accessor: 'close1Fw',
  },
  {
    accessor: 'close0d5Fw',
  },
  {
    accessor: 'waitForBuying',
  },
  {
    accessor: 'assignmentDate',
    disableSortBy: true,
  },
  {
    accessor: 'goingToFw',
  },
  {
    accessor: 'estimatedReceivedDate',
    disableSortBy: true,
  },
  {
    accessor: 'inChineseStock',
  },
  {
    accessor: 'receivedDate',
    disableSortBy: true,
  },
  {
    accessor: 'vwExp',
    disableSortBy: true,
  },
  {
    accessor: 'vwSea',
    disableSortBy: true,
  },
  {
    accessor: 'cbm',
    Cell: ({ row: { original } }: ITableRow) => {
      return _.isNil(original.cbm) ? '-' : _.round(original.cbm, 4)
    },
    disableSortBy: true,
  },
  {
    accessor: 'gw',
    Cell: ({ row: { original } }: ITableRow) => {
      return _.isNil(original.gw) ? '-' : _.round(original.gw)
    },
    disableSortBy: true,
  },
  {
    accessor: 'editSku',
    Cell: ({ row: { original } }: ITableRow) => {
      const { openSidebarEditSku } = useLayerSkuSidebar()
      return (
        <button
          onClick={() => {
            openSidebarEditSku(original.id)
          }}
          data-testid="btn-edit-sku"
        >
          Edit Sku
        </button>
      )
    },
    disableSortBy: true,
  },
]

const SHIPMENT_COLUMNS: ITableColumn[] = [0, 1, 2, 3].reduce(
  (columns: ITableColumn[], index: number) => {
    const columnGroup = [
      {
        accessor: `inboundItems.${index}.quantityShipped`,
        Cell: ({ row: { original } }: ITableRow) => {
          const quantityShipped = original.inboundItems[index]?.quantityShipped
          const quantityReceived = original.inboundItems[index]?.quantityReceived
          return _.isNil(original.inboundItems[index])
            ? '-'
            : `${quantityReceived}/${quantityShipped}`
        },
      },
      {
        accessor: `inboundItems.${index}.shipmentId`,
        Cell: ({ row: { original } }: ITableRow) => {
          const shipmentId = original.inboundItems[index]?.shipmentId
          return !_.isNil(shipmentId) ? (
            <a href={ProductUtils.generateShipmentUrl(shipmentId)} rel="noreferrer" target="_blank">
              {shipmentId}
            </a>
          ) : (
            '-'
          )
        },
        fixedWidth: 125,
        disableSortBy: true,
      },
      {
        accessor: `inboundItems.${index}.createdAt`,
        Cell: ({ row: { original } }: ITableRow) => {
          const createdAt = original.inboundItems[index]?.createdAt
          return createdAt ? dayjs(createdAt).format('YYYY-MM-DD') : '-'
        },
      },
      {
        accessor: `inboundItems.${index}.estimatedDeliveryDate`,
        Cell: ({ row: { original } }: ITableRow) => {
          return original.inboundItems[index]?.estimatedDeliveryDate ?? '-'
        },
      },
    ]
    return [...columns, ...columnGroup]
  },
  [],
)

export const PRODUCT_TABLE_COLUMNS: ITableColumn[] = [...BASE_COLUMNS, ...SHIPMENT_COLUMNS].map(
  (column) => {
    return column.Cell
      ? column
      : {
          ...column,
          Cell: ({ row: { original } }: ITableRow) => {
            return _.get(original, column.accessor) ?? '-'
          },
        }
  },
)

export const VISIBILITY_PRODUCT_TABLE_COLUMNS = PRODUCT_TABLE_COLUMNS.filter((column) => {
  return !column.disabledVisibilitySetting
})

export interface IEditableField {
  key: string
  inputType: 'number' | 'text' | 'date'
}

export const STICKY_COLUMNS = [
  {
    id: 'selection',
    width: 42,
    left: 0,
  },
  {
    id: 'imageUrl',
    width: 40,
    left: 51,
  },
  {
    id: 'asin.asinCode',
    width: 100,
    left: 100,
  },
  {
    id: 'fnsku',
    width: 100,
    left: 209,
  },
]
