import ProductsContextProvider from '../../contexts/products/products.provider'
import { LayerAsinSidebar } from './Layers/LayerAsinSidebar'
import { LayerSkuSidebar } from './Layers/LayerSkuSidebar'
import Products from './TableProducts'

export default function ProductsPage() {
  return (
    <ProductsContextProvider>
      <Products />
      <LayerAsinSidebar />
      <LayerSkuSidebar />
    </ProductsContextProvider>
  )
}
