import { KeywordAttribute, Operator, SearchTermAttribute } from '../enums'

export const XDAYS_SUPPORTED_ATTRIBUTES = [
  SearchTermAttribute.spend,
  SearchTermAttribute.profit,

  KeywordAttribute.spend,
  KeywordAttribute.sales,
  KeywordAttribute.clicks,
  KeywordAttribute.purchases, // orders
  KeywordAttribute.acos,
  KeywordAttribute.profit,
]

export const OPERATOR_LABELS = {
  [Operator.EQ]: 'Equal',
  [Operator.GT]: 'Greater than',
  [Operator.LT]: 'Less than',
  [Operator.GTE]: 'Greater than or equal',
  [Operator.LTE]: 'Less than or equal',
}
