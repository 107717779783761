import { createRef, useCallback, useImperativeHandle, useState } from 'react'
import ModalConfigAutoprice from '../Modals/ModalConfigAutoprice'
import ModalEditCompetition from '../Modals/ModalEditCompetition'
import ModalAddCompetitor from '../Modals/ModalAddCompetitor'

const layerCompetitionModalRef = createRef<any>()

export const useLayerCompetitionModal = () => {
  const openModalConfigAutoprice = useCallback((competitionId: string) => {
    layerCompetitionModalRef.current?.openModalConfigAutoprice(competitionId)
  }, [])

  const openModalEditCompetition = useCallback((competitionId: string) => {
    layerCompetitionModalRef.current?.openModalEditCompetition(competitionId)
  }, [])

  const openModalAddCompetitor = useCallback((competitionId: string) => {
    layerCompetitionModalRef.current?.openModalAddCompetitor(competitionId)
  }, [])

  return { openModalConfigAutoprice, openModalEditCompetition, openModalAddCompetitor }
}

export const LayerCompetitionModal = () => {
  const [competitionId, setCompetitionId] = useState<string>()
  const [openedModalConfigAutoprice, setOpenedModalConfigAutoprice] = useState(false)
  const [openModalEditCompetition, setOpenModalEditCompetition] = useState(false)
  const [openModalAddCompetitor, setOpenModalAddCompetitor] = useState(false)

  useImperativeHandle(
    layerCompetitionModalRef,
    () => ({
      openModalConfigAutoprice: (competitionId: string) => {
        setCompetitionId(competitionId)
        setOpenedModalConfigAutoprice(true)
      },
      closeModalConfigAutoprice: () => {
        setCompetitionId(undefined)
        setOpenedModalConfigAutoprice(false)
      },
      openModalEditCompetition: (competitionId: string) => {
        setCompetitionId(competitionId)
        setOpenModalEditCompetition(true)
      },
      closeModalEditCompetition: () => {
        setCompetitionId(undefined)
        setOpenModalEditCompetition(false)
      },
      openModalAddCompetitor: (competitionId: string) => {
        setCompetitionId(competitionId)
        setOpenModalAddCompetitor(true)
      },
      closeModalAddCompetitor: () => {
        setCompetitionId(undefined)
        setOpenModalAddCompetitor(false)
      },
    }),
    [setOpenedModalConfigAutoprice],
  )

  return (
    <>
      {competitionId && (
        <>
          <ModalConfigAutoprice
            competitionId={competitionId}
            openHandler={{ value: openedModalConfigAutoprice, set: setOpenedModalConfigAutoprice }}
          />
          <ModalEditCompetition
            competitionId={competitionId}
            openHandler={{ value: openModalEditCompetition, set: setOpenModalEditCompetition }}
          />
          <ModalAddCompetitor
            competitionId={competitionId}
            openHandler={{ value: openModalAddCompetitor, set: setOpenModalAddCompetitor }}
          />
        </>
      )}
    </>
  )
}
