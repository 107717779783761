import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import dayjs from '../../utils/dayjs.utils'
import { useAccountStore } from '../../hooks'
import { useSearchAutopriceLogs } from '../../hooks/api/useAutopriceLog'

export default function AutopriceLogList() {
  const { activeAccount } = useAccountStore()
  const { data: autopriceLogs = [] } = useSearchAutopriceLogs({
    accountId: activeAccount?.id ?? '',
    from: dayjs().subtract(10, 'day').startOf('day').toISOString(),
  })

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Report</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {autopriceLogs.map((autopriceLog) => (
            <TableRow
              key={autopriceLog.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" data-testid="autoprice-log-date">
                {dayjs(autopriceLog.createdAt).format('DD-MM-YYYY HH:mm')}
              </TableCell>
              <TableCell data-testid="autoprice-log-report">{autopriceLog.report}</TableCell>
              <TableCell data-testid="autoprice-log-item-details">
                <div dangerouslySetInnerHTML={{ __html: autopriceLog.message }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
