import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { IconButton, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useForm } from 'react-hook-form'
import { ICompetitor } from '../../../shared/interfaces'
import { useGetCompetitor, useUpdateCompetitor, useCompetitionStore } from '../../../hooks'
import { useEffect } from 'react'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: '80vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

interface ModalEditCompetitorProps {
  competitorId: string
  openHandler: {
    value: boolean
    set: Function
  }
}

interface FormEditCompetitor {
  notes?: string
  x: number
}

export default function ModalEditCompetitor({
  openHandler,
  competitorId,
}: ModalEditCompetitorProps) {
  const { register, setValue, handleSubmit } = useForm<FormEditCompetitor>()
  const { mutate: update } = useUpdateCompetitor(competitorId)
  const { data: competitor } = useGetCompetitor(competitorId)
  const replaceCompetitor = useCompetitionStore((state) => state.replaceCompetitor)

  useEffect(() => {
    if (!competitor) return
    setValue('notes', competitor.notes)
    setValue('x', competitor.x)
  }, [competitor, setValue])

  const onSubmit = (data: FormEditCompetitor) => {
    if (!competitor) return
    update(
      {
        notes: data.notes,
        x: data.x,
      },
      {
        onSuccess: (updatedCompetitorResp) => {
          const updatedCompetitor: ICompetitor = {
            ...competitor,
            notes: updatedCompetitorResp.notes,
            x: updatedCompetitorResp.x,
          }
          replaceCompetitor(updatedCompetitor)
          openHandler.set(false)
        },
      },
    )
  }

  if (!competitor) return null
  return (
    <Modal
      id="edit-competitor-modal"
      open={openHandler.value}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableRestoreFocus
      data-testid={`modal-edit-competitor`}
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={() => openHandler.set(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          data-testid="btn-close-modal"
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" data-testid="title-modal" variant="h6" component="h2">
          Edit competitor - {competitor.asinCode}
        </Typography>
        <Box
          id="edit-competitor-modal-form"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            {...register('notes')}
            defaultValue={competitor.notes}
            variant="standard"
            margin="normal"
            fullWidth
            label="Notes"
            autoFocus
          />
          <TextField
            {...register('x', {
              valueAsNumber: true,
              required: true,
            })}
            defaultValue={competitor.x}
            required
            variant="standard"
            margin="normal"
            fullWidth
            label="x"
            type="number"
            inputProps={{
              step: 0.1,
            }}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
