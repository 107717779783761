import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { Avatar, Divider, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { IAccount } from '../shared/interfaces'
import _ from 'lodash'
import { DRAWER_WIDTH } from '../App'
import { AccountUtils } from '../utils'
import { useAccountStore } from '../hooks/store/useAccountStore'
import { useAuthStore } from '../hooks/store/useAuthStore'
import { useGetAllAccounts } from '../hooks/api/useAccount'

interface TopBarProps {
  setIsSidebarOpen: Function
  isSidbarOpen: boolean
}

export default function TopBar({ setIsSidebarOpen, isSidbarOpen }: TopBarProps) {
  const removeAccessToken = useAuthStore((state) => state.removeAccessToken)
  const { activeAccount, setActiveAccount } = useAccountStore()
  const { data: accounts = [] } = useGetAllAccounts()
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElAccount, setAnchorElAccount] = useState<null | HTMLElement>(null)

  useEffect(() => {
    if (!_.isEmpty(accounts) && _.isNil(activeAccount)) {
      setActiveAccount(accounts[0])
    }
  }, [accounts])

  const switchAccount = (acc: IAccount) => {
    setActiveAccount(acc)
    handleCloseSettingMenu()
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccount(event.currentTarget)
  }

  const logout = () => {
    removeAccessToken()
    handleCloseSettingMenu()
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseSettingMenu = () => {
    setAnchorElAccount(null)
  }

  return (
    <AppBar
      position="fixed"
      color="default"
      sx={{
        zIndex: 1300,
        ...(isSidbarOpen && {
          marginLeft: `${DRAWER_WIDTH}px`,
          width: `calc(100% - ${DRAWER_WIDTH}px)`,
        }),
      }}
      elevation={2}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setIsSidebarOpen(true)}
          edge="start"
          sx={{
            marginRight: 5,
            ...(isSidbarOpen && { display: 'none' }),
          }}
          data-testid="open-sidebar-button"
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ display: { xs: 'none', sm: 'block' } }}
          data-testid="app-name"
        >
          {AccountUtils.getAccountName(activeAccount?.id ?? '')}
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Menu open={Boolean(anchorElNav)}>
            <MenuItem onClick={handleCloseNavMenu}>
              <Typography textAlign="center">Product</Typography>
            </MenuItem>
            <MenuItem onClick={handleCloseNavMenu}>
              <Typography textAlign="center">PPC</Typography>
            </MenuItem>
          </Menu>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0 }}
              data-testid="open-setting-button"
            >
              <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px', minWidth: '150px' }}
            id="menu-appbar"
            anchorEl={anchorElAccount}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElAccount)}
            onClose={handleCloseSettingMenu}
          >
            {accounts.map((acc: IAccount) => {
              return (
                <MenuItem
                  key={acc.id}
                  onClick={() => {
                    switchAccount(acc)
                  }}
                  disabled={acc.id === activeAccount?.id}
                  data-testid={`account-${acc.id}`}
                >
                  <Typography textAlign="center">{AccountUtils.getAccountName(acc?.id)}</Typography>
                </MenuItem>
              )
            })}
            <Divider />
            <MenuItem onClick={logout}>
              <Typography textAlign="center" data-testid="logout-button">
                Logout
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
