import { useQuery } from '@tanstack/react-query'
import { AuthRequest } from './request'
import { QUERY_KEY } from '../../constants'
import { ICampaignAlert } from '../../shared/interfaces-v2'

export interface SearchCampaignAlertsPayload {
  accountId?: string
  from?: string
}

export const useSearchCampaignAlerts = (payload: SearchCampaignAlertsPayload) => {
  const { accountId, from } = payload
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_CAMPAIGN_ALERTS, accountId, from],
    queryFn: async (): Promise<ICampaignAlert[]> => {
      const { data } = await AuthRequest.post(`campaign-alerts/search`, payload)
      return data
    },
    enabled: !!accountId,
  })
}
