import { create } from 'zustand'
import { IAccount } from '../../shared/interfaces'

interface IAccountStore {
  activeAccount: IAccount | null
  setActiveAccount: (account: IAccount) => void
}

const getActiveAccountFromStorage = () => {
  const account = localStorage.getItem('account')
  if (!account) return null
  try {
    const accountJson = JSON.parse(account)
    const id = accountJson?.id
    if (!id) return null
    return JSON.parse(account)
  } catch (error) {
    return null
  }
}

export const useAccountStore = create<IAccountStore>((set) => ({
  activeAccount: getActiveAccountFromStorage(),
  setActiveAccount: (newAccount: IAccount) => {
    localStorage.setItem('account', JSON.stringify(newAccount))
    set({ activeAccount: newAccount })
  },
}))
