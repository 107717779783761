import { useDisclosure } from '@mantine/hooks'
import { createRef, useCallback, useImperativeHandle, useState } from 'react'
import SidebarEditSku from '../Modals/SidebarEditSku'

const layerSidebarSkuRef = createRef<any>()

export const useLayerSkuSidebar = () => {
  const openSidebarEditSku = useCallback((skuId: string) => {
    layerSidebarSkuRef.current?.openSidebarEditSku(skuId)
  }, [])

  return { openSidebarEditSku }
}

export const LayerSkuSidebar = () => {
  const [skuId, setSkuId] = useState<string>()
  const [opened, { open, close }] = useDisclosure(false)

  const closeSidebarEditSku = () => {
    setSkuId(undefined)
    close()
  }

  useImperativeHandle(
    layerSidebarSkuRef,
    () => ({
      openSidebarEditSku: (newSkuId: string) => {
        setSkuId(newSkuId)
        open()
      },
    }),
    [setSkuId, open],
  )

  return (
    <>{skuId && <SidebarEditSku opened={opened} onClose={closeSidebarEditSku} skuId={skuId} />}</>
  )
}
