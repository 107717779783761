import { useEffect } from 'react'
import { PRODUCT_LABEL } from '../constants'
import { Controller, useForm } from 'react-hook-form'
import { REGEX } from '../../../shared/constants'
import { UpdateAsinDto } from '../../../hooks/api/dto'
import {
  Box,
  Button,
  Drawer,
  Grid,
  Group,
  Loader,
  LoadingOverlay,
  Text,
  TextInput,
} from '@mantine/core'
import { useGetAsin, useSkuStatsStore, useUpdateAsin } from '../../../hooks'
import { DRAWER_WIDTH } from '../../../App'

interface IEditAsinForm {
  cogs: string
  ship: string
  notes: string
  assignee: string

  lSet: string
  wSet: string
  hSet: string
  gwSet: string
  lCtn: string
  wCtn: string
  hCtn: string
  gwCtn: string
  qtCtn: string
}

interface SidebarEditAsinProps {
  opened: boolean
  onClose: () => void
  asinId: string
}

const SidebarEditAsin = ({ opened, onClose, asinId }: SidebarEditAsinProps) => {
  const { handleSubmit, setValue, control } = useForm<IEditAsinForm>()
  const { mutate: updateAsin, isPending } = useUpdateAsin(asinId)
  const { data: asin, isFetching } = useGetAsin(asinId)

  const updateSkusStats = useSkuStatsStore((state) => state.updateSkusStats)

  useEffect(() => {
    if (asin) {
      setValue('cogs', `${asin.cogs ?? ''}`)
      setValue('ship', asin.ship)
      setValue('notes', asin.notes)
      setValue('assignee', asin.assignee)

      setValue('lSet', `${asin.containerSpec?.lSet ?? ''}`)
      setValue('wSet', `${asin.containerSpec?.wSet ?? ''}`)
      setValue('hSet', `${asin.containerSpec?.hSet ?? ''}`)
      setValue('gwSet', `${asin.containerSpec?.gwSet ?? ''}`)
      setValue('lCtn', `${asin.containerSpec?.lCtn ?? ''}`)
      setValue('wCtn', `${asin.containerSpec?.wCtn ?? ''}`)
      setValue('hCtn', `${asin.containerSpec?.hCtn ?? ''}`)
      setValue('gwCtn', `${asin.containerSpec?.gwCtn ?? ''}`)
      setValue('qtCtn', `${asin.containerSpec?.qtCtn ?? ''}`)
    }
  }, [asin])

  const containerSpecFieldKey = [
    'lSet',
    'wSet',
    'hSet',
    'gwSet',
    'lCtn',
    'wCtn',
    'hCtn',
    'gwCtn',
    'qtCtn',
  ] as const

  const onSubmit = (data: IEditAsinForm) => {
    const updateAsinDto: UpdateAsinDto = {
      cogs: Number(data.cogs),
      ship: data.ship,
      notes: data.notes,
      assignee: data.assignee,
      containerSpec: {
        lSet: data.lSet ? Number(data.lSet) : undefined,
        wSet: data.wSet ? Number(data.wSet) : undefined,
        hSet: data.hSet ? Number(data.hSet) : undefined,
        gwSet: data.gwSet ? Number(data.gwSet) : undefined,
        lCtn: data.lCtn ? Number(data.lCtn) : undefined,
        wCtn: data.wCtn ? Number(data.wCtn) : undefined,
        hCtn: data.hCtn ? Number(data.hCtn) : undefined,
        gwCtn: data.gwCtn ? Number(data.gwCtn) : undefined,
        qtCtn: data.qtCtn ? Number(data.qtCtn) : undefined,
      },
    }
    if (asin) {
      updateAsin(updateAsinDto, {
        onSuccess: (updatedAsin) => {
          updateSkusStats(updatedAsin.skusStats)
          onClose()
        },
      })
    }
  }

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      title={`Edit ASIN (${asin?.asinCode ?? ''})`}
      position="right"
      styles={{
        content: {
          height: `calc(100vh - ${DRAWER_WIDTH / 4}px)`,
          marginTop: DRAWER_WIDTH / 4, // todo: move when refactoring
        },
        title: {
          fontWeight: 700,
        },
      }}
    >
      <LoadingOverlay visible={isFetching || isPending} loaderProps={{ children: <Loader /> }} />
      <Box id="form-edit-asin" component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Col span={6}>
            <Controller
              name="cogs"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  label="Cogs"
                  onChange={({ target: { value: inputValue } }) => {
                    if (REGEX.NUMBER.test(inputValue)) {
                      onChange(inputValue)
                    }
                  }}
                  value={value}
                  data-testid="input-cogs"
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="assignee"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  label="Assignee"
                  onChange={onChange}
                  value={value}
                  data-testid="input-assignee"
                />
              )}
            />
          </Grid.Col>
          <Grid.Col>
            <Controller
              name="ship"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  label="Ship"
                  onChange={onChange}
                  value={value}
                  data-testid="input-ship"
                />
              )}
            />
          </Grid.Col>
          <Grid.Col>
            <Controller
              name="notes"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  label="Notes"
                  onChange={onChange}
                  value={value}
                  data-testid="input-notes"
                />
              )}
            />
          </Grid.Col>
        </Grid>
        <Text variant="h6" mt={16} fw={600}>
          Container Specification
        </Text>
        <Grid mt={4}>
          {containerSpecFieldKey.map((fieldKey) => {
            return (
              <Grid.Col span={6} key={fieldKey}>
                <Controller
                  name={fieldKey}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      label={PRODUCT_LABEL[fieldKey]}
                      onChange={({ target: { value: inputValue } }) => {
                        if (REGEX.NUMBER.test(inputValue)) {
                          onChange(inputValue)
                        }
                      }}
                      value={value}
                      data-testid={`input-${fieldKey}`}
                    />
                  )}
                />
              </Grid.Col>
            )
          })}
        </Grid>
        <Group mt={24}>
          <Button type="submit" fullWidth>
            Save
          </Button>
        </Group>
      </Box>
    </Drawer>
  )
}

export default SidebarEditAsin
