import { useMutation, useQuery } from '@tanstack/react-query'
import { AuthRequest } from './request'
import { ICompetition, ICompetitor } from '../../shared/interfaces'
import { UpdateCompetitorDto } from './dto'
import { QUERY_KEY } from '../../constants'

export interface CreateCompetitorDto {
  competitionId: string
  asinCode: string
  x: number
}

export const useCreateCompetitor = () => {
  return useMutation({
    mutationFn: async (payload: CreateCompetitorDto): Promise<ICompetition> => {
      const { data } = await AuthRequest.post(`competitors`, payload)
      return data
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}

export const useGetCompetitor = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.GET_ONE_COMPETITOR, id],
    queryFn: async (): Promise<ICompetitor> => {
      const { data } = await AuthRequest.get(`competitors/${id}`)
      return data
    },
  })
}

export const useUpdateCompetitor = (id: string) => {
  return useMutation({
    mutationFn: async (updateCompetitorDto: UpdateCompetitorDto): Promise<ICompetitor> => {
      const { data } = await AuthRequest.patch(`competitors/${id}`, updateCompetitorDto)
      return data
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}

export const useRemoveCompetitor = (id: string) => {
  return useMutation({
    mutationFn: async () => {
      const { data } = await AuthRequest.delete(`competitors/${id}`)
      return data
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}
