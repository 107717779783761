import { useState } from 'react'
import { AioProductsContext } from './aio-products.context'
import { IAioSku } from '../../shared/interfaces-v2'

export default function AioProductsContextProvider(props: { children: React.ReactNode }) {
  const [aioProducts, setAioProducts] = useState<IAioSku[]>([])
  const [hiddenColumnIds, setHiddenColumnIds] = useState<string[]>(
    JSON.parse(localStorage.getItem('aio_hidden_columns_ids') ?? '[]'),
  )

  const saveHiddenColumnIds = (columnIds: string[]) => {
    setHiddenColumnIds(columnIds)
    localStorage.setItem('aio_hidden_columns_ids', JSON.stringify(columnIds))
  }

  return (
    <AioProductsContext.Provider
      value={{
        aioProducts,
        setAioProducts,

        hiddenColumnIds,
        saveHiddenColumnIds,
      }}
    >
      {props.children}
    </AioProductsContext.Provider>
  )
}
