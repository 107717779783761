import {
  Box,
  Card,
  CardActions,
  CardMedia,
  IconButton,
  Rating,
  Stack,
  Typography,
} from '@mui/material'
import { ICompetition } from '../../shared/interfaces'
import _ from 'lodash'
import CrawlingStatusIcon from './IconCrawlingStatus'
import EditIcon from '@mui/icons-material/Edit'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import { CompetitionUtils } from '../../utils'
import { COMPETITOR_LIMIT } from '../../shared/constants'
import { useLayerCompetitionModal } from './Layers/LayerCompetitionModal'

interface ICompetitionObjectiveProps {
  competition: ICompetition
}

export default function CompetitionObjective({ competition }: ICompetitionObjectiveProps) {
  const { openModalConfigAutoprice, openModalEditCompetition, openModalAddCompetitor } =
    useLayerCompetitionModal()

  return (
    <Card sx={{ p: 1 }}>
      <Stack
        sx={{ fontSize: 24 }}
        pb={1}
        direction="row"
        alignItems="center"
        gap={1}
        data-testid={`competition-asin-${competition.id}`}
      >
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            window.open(`https://amazon.com/dp/${competition.asinCode}`, '_blank')
          }}
        >
          {competition.asinCode}
        </span>
        {!_.isNil(competition.crAsin.crawlingStatus) && (
          <CrawlingStatusIcon crawlingStatus={competition.crAsin.crawlingStatus} />
        )}
      </Stack>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Box
            component="span"
            sx={{ fontSize: 20 }}
            color="error.main"
            data-testid={`competition-price-${competition.id}`}
          >
            {`$${competition.crAsin.price || 'N/A'}`}
          </Box>
          &nbsp;
          <Box component="span" color={'grey.500'} sx={{ fontSize: 16 }}>
            {CompetitionUtils.renderPriceUpdatedAt(_.get(competition, 'crAsin.priceUpdatedAt', ''))}
          </Box>
          <Typography
            variant="body2"
            color="text.secondary"
            data-testid={`competition-ships-from-${competition.id}`}
          >
            {_.isNil(competition.crAsin?.shipsFrom)
              ? 'N/A'
              : CompetitionUtils.isFBA(_.get(competition, 'crAsin.shipsFrom', ''))
              ? 'FBA'
              : 'FBM'}
          </Typography>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Rating
              sx={{ display: 'flex' }}
              name="size-small"
              value={competition.crAsin?.rating}
              precision={0.5}
              size="small"
              readOnly
              data-testid={`competition-rating-${competition.id}`}
            />
            <Typography
              component={'span'}
              color="text.secondary"
              data-testid={`competition-review-count-${competition.id}`}
            >
              ({_.isNil(competition.crAsin?.reviewCount) ? 'N/A' : competition.crAsin?.reviewCount})
            </Typography>
          </Stack>
        </Box>
        <CardMedia
          component="img"
          sx={{
            height: 50,
            width: 'auto',
            display: 'block',
            margin: 'auto',
          }}
          image={competition.imageUrl}
          data-testid={`competition-image-${competition.id}`}
        />
      </Box>
      <Typography
        variant="body2"
        color="text.secondary"
        data-testid={`competition-rank-${competition.id}`}
      >
        Rank:{' '}
        {_.isEmpty(competition.crAsin?.bestSellersRank) ? '-' : competition.crAsin?.bestSellersRank}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        data-testid={`competition-notes-${competition.id}`}
      >
        Notes: {_.isEmpty(competition.notes) ? '-' : competition.notes}
      </Typography>
      <CardActions sx={{ p: 0 }}>
        <IconButton
          color={competition.enabledAutoprice ? 'primary' : 'secondary'}
          title="Autoprice config"
          onClick={() => {
            openModalConfigAutoprice(competition.id)
          }}
          data-testid={`config-autoprice-${competition.id}`}
        >
          <PriceCheckIcon />
        </IconButton>
        <IconButton
          color="secondary"
          title="Edit"
          onClick={() => {
            openModalEditCompetition(competition.id)
          }}
          data-testid={`edit-competition-${competition.id}`}
        >
          <EditIcon />
        </IconButton>
        {_.size(competition.competitors) < COMPETITOR_LIMIT && (
          <IconButton
            color="secondary"
            title="Add competitor"
            onClick={() => {
              openModalAddCompetitor(competition.id)
            }}
            data-testid={`add-competitor-${competition.id}`}
          >
            <PersonAddAlt1Icon />
          </IconButton>
        )}
      </CardActions>
    </Card>
  )
}
