import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AuthRequest } from './request'
import { QUERY_KEY } from '../../constants'
import { IActionConfig, IScript } from '../../shared/interfaces-v2'
import { ActionType, Operator, ScriptType } from '../../shared/enums'

export const useSearchScripts = () => {
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_SCRIPTS],
    queryFn: async (): Promise<IScript[]> => {
      const { data } = await AuthRequest.post(`scripts/search`)
      return data
    },
  })
}

export const useGetScript = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.GET_ONE_SCRIPT, id],
    queryFn: async (): Promise<IScript> => {
      const { data } = await AuthRequest.get(`scripts/${id}`)
      return data
    },
    enabled: !!id,
  })
}

export interface CreateScriptPayload {
  id: string
  name: string
  description?: string
  type: ScriptType
  cases: Array<{
    enabledAlert: boolean
    conditions?: Array<{
      attribute: string
      xDays?: number
      operator: Operator
      value: string
    }>
    action?: {
      type: ActionType
      config: IActionConfig
    }
  }>
}

export const useCreateScript = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (payload: CreateScriptPayload) => {
      const { data } = await AuthRequest.post(`scripts`, payload)
      return data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY.SEARCH_SCRIPTS] })
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}

export interface UpdateScriptPayload extends Omit<CreateScriptPayload, 'id'> {}

export const useUpdateScript = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (payload: UpdateScriptPayload) => {
      const { data } = await AuthRequest.patch(`scripts/${id}`, payload)
      return data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY.SEARCH_SCRIPTS] })
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY.GET_ONE_SCRIPT, id] })
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}

export const useDeleteScript = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async () => {
      const { data } = await AuthRequest.delete(`scripts/${id}`)
      return data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY.SEARCH_SCRIPTS] })
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}
