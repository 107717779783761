import { create } from 'zustand'
import { ISkuStats } from '../../shared/interfaces-v2'

interface ISkuStatsStore {
  skuStats: ISkuStats[]
  setSkuStats: (skuStats: ISkuStats[]) => void
  updateSkusStats: (updatedSkusStats: ISkuStats[]) => void
  removeSkusStats: (ids: string[]) => void
  restoreSkusStats: (ids: string[]) => void
}

export const useSkuStatsStore = create<ISkuStatsStore>((set) => ({
  skuStats: [],
  setSkuStats: (skuStats: ISkuStats[]) => {
    set({ skuStats })
  },
  updateSkusStats: (updatedSkusStats: ISkuStats[]) => {
    set(({ skuStats }) => {
      const newSkusStats = skuStats.map((prevSkuStats) => {
        const matchedItem = updatedSkusStats.find((updated) => updated.id === prevSkuStats.id)
        return matchedItem ?? prevSkuStats
      })

      return { skuStats: newSkusStats }
    })
  },
  removeSkusStats: (ids: string[]) => {
    set(({ skuStats }) => {
      const newSkusStats = skuStats.filter((prevSkuStats) => !ids.includes(prevSkuStats.id))
      return { skuStats: newSkusStats }
    })
  },
  restoreSkusStats: (ids: string[]) => {
    set(({ skuStats }) => {
      const newSkusStats = skuStats.map((prevSkuStats) => {
        return ids.includes(prevSkuStats.id)
          ? {
              ...prevSkuStats,
              isDeleted: false,
            }
          : prevSkuStats
      })
      return { skuStats: newSkusStats }
    })
  },
}))
