import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { IconButton, Switch, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEffect } from 'react'
import {
  useGetCompetition,
  useUpdateCompetition,
  useCompetitionStore,
  useUpsertPriceConfig,
} from '../../../hooks'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: '80vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

interface ModalConfigAutopriceProps {
  openHandler: {
    value: boolean
    set: Function
  }
  competitionId: string
}

interface FormProps {
  xMax: number
  xMin: number
  xMinFba: number
  sub: number
  maxPriceLimit: number
  minPriceLimit: number
}

export default function ModalConfigAutoprice({
  openHandler,
  competitionId,
}: ModalConfigAutopriceProps) {
  const { data: competition } = useGetCompetition(competitionId)
  const { mutate: update } = useUpdateCompetition(competitionId)
  const { register, handleSubmit, setValue } = useForm<FormProps>({})
  useEffect(() => {
    if (competition) {
      setValue('xMax', competition.priceConfig?.xMax)
      setValue('xMin', competition.priceConfig?.xMin)
      setValue('xMinFba', competition.priceConfig?.xMinFba)
      setValue('sub', competition.priceConfig?.sub)
      setValue('maxPriceLimit', competition.priceConfig?.maxPriceLimit)
      setValue('minPriceLimit', competition.priceConfig?.minPriceLimit)
    }
  }, [competition])
  const replaceCompetition = useCompetitionStore((state) => state.replaceCompetition)
  const { mutate: upsertPriceConfig } = useUpsertPriceConfig(competitionId)

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    if (!competition) return
    upsertPriceConfig(
      {
        xMax: data.xMax,
        xMin: data.xMin,
        xMinFba: data.xMinFba,
        sub: data.sub,
        maxPriceLimit: data.maxPriceLimit,
        minPriceLimit: data.minPriceLimit,
      },
      {
        onSuccess: () => {
          alert('Successfully set autoprice config!')
        },
      },
    )
  }

  const switchEnabledAutoprice = async () => {
    if (!competition) return
    update(
      {
        enabledAutoprice: !competition.enabledAutoprice,
      },
      {
        onSuccess: (updatedCompetition) => {
          replaceCompetition(updatedCompetition)
        },
      },
    )
  }

  return (
    <Modal
      open={openHandler.value}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableRestoreFocus
      id="config-autoprice-modal"
      data-testid="modal-config-autoprice"
    >
      {competition ? (
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={() => openHandler.set(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            data-testid="btn-close-modal"
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" data-testid="title-modal" variant="h6" component="h2">
            Autoprice ({competition.asinCode})
            <Switch
              checked={competition.enabledAutoprice}
              onChange={switchEnabledAutoprice}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Typography>
          <Box
            id="configure-price-form"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              {...register('xMax', {
                required: true,
                valueAsNumber: true,
              })}
              required
              type="number"
              variant="standard"
              margin="normal"
              fullWidth
              label="xMax"
              autoFocus
            />
            <TextField
              {...register('xMin', {
                required: true,
                valueAsNumber: true,
              })}
              required
              type="number"
              variant="standard"
              margin="normal"
              fullWidth
              label="xMin"
            />
            <TextField
              {...register('xMinFba', {
                required: true,
                valueAsNumber: true,
              })}
              required
              type="number"
              variant="standard"
              margin="normal"
              fullWidth
              label="xMinFba"
            />
            <TextField
              {...register('sub', {
                required: true,
                valueAsNumber: true,
              })}
              required
              type="number"
              variant="standard"
              margin="normal"
              fullWidth
              label="sub"
            />
            <TextField
              {...register('maxPriceLimit', {
                required: true,
                valueAsNumber: true,
              })}
              required
              type="number"
              variant="standard"
              margin="normal"
              fullWidth
              label="maxPriceLimit"
            />
            <TextField
              {...register('minPriceLimit', {
                valueAsNumber: true,
              })}
              required
              type="number"
              variant="standard"
              margin="normal"
              fullWidth
              label="minPriceLimit"
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Save
            </Button>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Modal>
  )
}
