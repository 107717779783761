import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ITask } from '../../shared/interfaces'
import { AuthRequest } from './request'
import { QUERY_KEY } from '../../constants'
import { SearchTaskDto } from './dto'

export const useSearchTasks = (searchTaskDto: SearchTaskDto) => {
  const { accountId, from } = searchTaskDto
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_TASKS, accountId, from],
    queryFn: async (): Promise<ITask[]> => {
      const { data } = await AuthRequest.post(`tasks/search`, searchTaskDto)
      return data
    },
    enabled: !!accountId,
  })
}

export const useExecuteTask = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (id: string): Promise<ITask> => {
      const { data } = await AuthRequest.post(`tasks/${id}/execute`)
      return data
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.SEARCH_TASKS],
      })
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}
