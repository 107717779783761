export * from './script.constant'

export const ROUTES = {
  LOGIN: {
    PATH: '/login',
  },
  HOME: {
    PATH: '/',
  },
  DASHBOARD: {
    PATH: '/dashboard',
  },
  PRODUCTS: {
    PATH: '/products',
  },
  COMPETITION: {
    PATH: '/competitions',
  },
  AUTOPTICE_LOG: {
    PATH: '/autoprice-logs',
  },
  TASK: {
    PATH: '/tasks',
  },
  PPC: {
    PATH: '/ppc',
  },
  AUTOCAMPAIGN_LOG: {
    PATH: '/script-execution-logs',
  },
  SCRIPT: {
    PATH: '/scripts',
  },
  CAMPAIGN_ALERT: {
    PATH: '/campaign-alerts',
  },
}

export const COMPETITOR_LIMIT = 5

export const REGEX = {
  NUMBER: /^[0-9.]*$/,
}

export const DATE_FORMAT = {
  API: 'YYYY-MM-DD',
}

export const SCENARIO_2_STOPLOSS_DEFAULT = 0.1

export const MARKET_TIMEZONE = 'America/Los_Angeles'
