import dayjs from '../../utils/dayjs.utils'
import { useAccountStore } from '../../hooks'
import { Table } from '@mantine/core'
import { useSearchScriptExecutionLogs } from '../../hooks/api/useAutocampaignLog'
import { IScriptExecutionLog } from '../../shared/interfaces'
import { MARKET_TIMEZONE } from '../../shared/constants'

export default function TableScriptExecutionLog() {
  const { activeAccount } = useAccountStore()
  const { data: scriptExecutionLogs } = useSearchScriptExecutionLogs({
    accountId: activeAccount?.id ?? '',
    from: dayjs().subtract(10, 'day').startOf('day').toISOString(),
  })

  return (
    <Table stickyHeader stickyHeaderOffset={64} verticalSpacing="sm">
      <Table.Thead>
        <Table.Tr>
          <Table.Th>No.</Table.Th>
          <Table.Th>Message</Table.Th>
          <Table.Th style={{ textAlign: 'center' }}>Time</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {(scriptExecutionLogs ?? []).map(
          (scriptExecutionLog: IScriptExecutionLog, index: number) => (
            <Table.Tr key={scriptExecutionLog.id}>
              <Table.Td>{index + 1}</Table.Td>
              <Table.Td>
                <div dangerouslySetInnerHTML={{ __html: scriptExecutionLog.message }}></div>
              </Table.Td>
              <Table.Td style={{ textAlign: 'center' }}>
                {dayjs(scriptExecutionLog.createdAt).tz(MARKET_TIMEZONE).format('DD-MM-YYYY HH:mm')}
              </Table.Td>
            </Table.Tr>
          ),
        )}
      </Table.Tbody>
    </Table>
  )
}
