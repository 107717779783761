import { createContext } from 'react'

interface ProductsContextProps {
  selectedSkuIds: string[]
  setSelectedSkuIds: (ids: string[]) => void

  hiddenColumnIds: string[]
  saveHiddenColumnIds: (columnIds: string[]) => void
}

export const ProductsContext = createContext<ProductsContextProps>({
  selectedSkuIds: [],
  setSelectedSkuIds: () => {},

  hiddenColumnIds: [],
  saveHiddenColumnIds: () => {},
})
