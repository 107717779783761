import { Control, Controller } from 'react-hook-form'
import { NumberInput } from '@mantine/core'
import { IFormScript } from '../type'

interface Props {
  index: number
  control: Control<IFormScript, any>
}

const ActionConfigMaxBid = ({ index, control }: Props) => {
  return (
    <Controller
      control={control}
      name={`cases.${index}.actionConfig.maxBid`}
      render={({ field: { onChange, value } }) => (
        <NumberInput value={value} onChange={onChange} label="Max Bid" required hideControls />
      )}
    />
  )
}
export default ActionConfigMaxBid
