import {
  Box,
  Card,
  CardActions,
  CardMedia,
  IconButton,
  Rating,
  Stack,
  Typography,
} from '@mui/material'
import { ICompetitor } from '../../shared/interfaces'
import _ from 'lodash'
import CrawlingStatusIcon from './IconCrawlingStatus'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { CompetitionUtils } from '../../utils'
import { useRemoveCompetitor, useCompetitionStore } from '../../hooks'
import { useLayerCompetitorModal } from './Layers/LayerCompetitorModal'

interface ICardCompetitorProps {
  competitor: ICompetitor
}

const CardCompetitor = ({ competitor }: ICardCompetitorProps) => {
  const removeCompetitor = useCompetitionStore((state) => state.removeCompetitor)
  const { mutate: remove, isPending: isRemoving } = useRemoveCompetitor(competitor.id)
  const { openModalEditCompetitor } = useLayerCompetitorModal()

  const handleRemoveCompetitor = async () => {
    if (confirm('Are you sure you want to remove this competitor?')) {
      remove(undefined, {
        onSuccess: () => {
          removeCompetitor(competitor.id)
        },
      })
    }
  }

  return (
    <Card sx={{ p: 1 }}>
      <Stack
        sx={{ fontSize: 24 }}
        pb={1}
        direction="row"
        alignItems="center"
        gap={1}
        data-testid={`competitor-asin-${competitor.competitionId}`}
      >
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            window.open(`https://amazon.com/dp/${competitor.asinCode}`, '_blank')
          }}
        >
          {competitor.asinCode}
        </span>
        {!_.isNil(competitor.crAsin.crawlingStatus) && (
          <CrawlingStatusIcon crawlingStatus={competitor.crAsin.crawlingStatus} />
        )}
      </Stack>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Box
            component="span"
            sx={{ fontSize: 20 }}
            color="error.main"
            data-testid={`competitor-price-${competitor.id}`}
          >
            {`$${competitor.crAsin.price || 'N/A'}`}
          </Box>
          &nbsp;
          <Box component="span" color={'grey.500'} sx={{ fontSize: 16 }}>
            {CompetitionUtils.renderPriceUpdatedAt(_.get(competitor, 'crAsin.priceUpdatedAt', ''))}
          </Box>
          <Typography
            variant="body2"
            color="text.secondary"
            data-testid={`competitor-ships-from-${competitor.id}`}
          >
            {_.isNil(competitor.crAsin?.shipsFrom)
              ? 'N/A'
              : CompetitionUtils.isFBA(_.get(competitor, 'crAsin.shipsFrom', ''))
              ? 'FBA'
              : 'FBM'}
          </Typography>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Rating
              name="size-small"
              value={competitor.crAsin?.rating}
              precision={0.5}
              size="small"
              readOnly
              data-testid={`competitor-rating-${competitor.id}`}
            />
            <Typography
              component={'span'}
              color="text.secondary"
              data-testid={`competitor-review-count-${competitor.id}`}
            >
              ({_.isNil(competitor.crAsin?.reviewCount) ? 'N/A' : competitor.crAsin?.reviewCount})
            </Typography>
          </Stack>
        </Box>
        <CardMedia
          component="img"
          sx={{
            height: 50,
            width: 'auto',
            display: 'block',
            margin: 'auto',
          }}
          image={competitor.crAsin?.imageUrl}
          data-testid={`competitor-image-${competitor.id}`}
        />
      </Box>
      <Typography
        variant="body2"
        color="text.secondary"
        data-testid={`competitor-rank-${competitor.id}`}
      >
        Rank:{' '}
        {_.isEmpty(competitor.crAsin?.bestSellersRank) ? '-' : competitor.crAsin?.bestSellersRank}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        data-testid={`competitor-notes-${competitor.id}`}
      >
        Notes: {_.isEmpty(competitor.notes) ? '-' : competitor.notes}
      </Typography>
      <CardActions sx={{ p: 0 }}>
        <IconButton
          color="secondary"
          title="Edit"
          onClick={() => {
            openModalEditCompetitor(competitor.id)
          }}
          data-testid={`btn-open-modal-edit-competitor-${competitor.id}`}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color="error"
          title="Remove"
          onClick={handleRemoveCompetitor}
          data-testid={`btn-remove-competitor-${competitor.id}`}
          disabled={isRemoving}
        >
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  )
}

export default CardCompetitor
