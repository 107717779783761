import _ from 'lodash'
import ColumnFilter from '../../components/column-filter'
import {
  showQuantity,
  sumWithDetails,
  toLineTextFromArr,
  toLineUrlFromShipmentIdArr,
  toNumberFromArrSum,
} from './custom-cell'
import { AccountUtils, ProductUtils } from '../../utils'
import { IAioSku } from '../../shared/interfaces-v2'
import dayjs from '../../utils/dayjs.utils'

export const PRODUCT_LABEL = {
  imageUrl: 'Image',
  'asin.asinCode': 'Asin',
  fnsku: 'FNSKU',
  account: 'Account',
  details: 'Details',
  'asin.assignee': 'Assignee',
  cogs: 'COGS',
  'asin.ship': 'Ship',
  'asin.notes': 'Notes',
  lSet: 'L_SET',
  wSet: 'W_SET',
  hSet: 'H_SET',
  gwSet: 'GW_SET',
  lCtn: 'L_CTN',
  wCtn: 'W_CTN',
  hCtn: 'H_CTN',
  qtCtn: 'QT_CTN',
  gwCtn: 'GW_CTN',
  vwExpSet: 'VW Exp/Set',
  vwSeaSet: 'VW Sea/Set',
  cbmSet: 'CBM/set',
  avaFC: 'Ava+FC',
  sales30d: 'Sales 30d',
  inShip3: 'In ship, 3',
  close6: 'C6',
  close4: 'C4',
  close3d5: 'C3.5',
  close2d5: 'C2.5',
  close1d5: 'C1.5',
  close1: 'C1',
  close0d5: 'C0.5',
  inShip3Fw: 'In ship, 3, FW',
  close6Fw: 'C6, FW',
  close4Fw: 'C4, FW',
  close3d5Fw: 'C3.5, FW',
  close2d5Fw: 'C2.5, FW',
  close1d5Fw: 'C1.5, FW',
  close1Fw: 'C1, FW',
  close0d5Fw: 'C0.5, FW',
  waitForBuying: 'Wait for buying',
  assignmentDate: 'Assignment Date',
  goingToFw: 'Bought & Shipping',
  estimatedReceivedDate: 'Est. Received Date',
  inChineseStock: 'In Chinese Stock',
  receivedDate: 'Received Date',
  vwExp: 'V.W exp',
  vwSea: 'V.W sea',
  cbm: 'CBM',
  gw: 'G.W.',
  'shipments.0.quantityOrdered': 'SL1',
  'shipments.0.url': 'URL1',
  'shipments.0.shipOutDate': 'Ship Out 1',
  'shipments.0.estimatedDeliveryDate': 'Estimate Delivery 1',
  'shipments.1.quantityOrdered': 'SL2',
  'shipments.1.url': 'URL2',
  'shipments.1.shipOutDate': 'Ship Out 2',
  'shipments.1.estimatedDeliveryDate': 'Estimate Delivery 2',
  'shipments.2.quantityOrdered': 'SL3',
  'shipments.2.url': 'URL3',
  'shipments.2.shipOutDate': 'Ship Out 3',
  'shipments.2.estimatedDeliveryDate': 'Estimate Delivery 3',
  'shipments.3.quantityOrdered': 'SL4',
  'shipments.3.url': 'URL4',
  'shipments.3.shipOutDate': 'Ship Out 4',
  'shipments.3.estimatedDeliveryDate': 'Estimate Delivery 4',
  'inboundShipmentItems.0.quantity': 'SL1',
  'inboundShipmentItems.1.quantity': 'SL2',
  'inboundShipmentItems.2.quantity': 'SL3',
  'inboundShipmentItems.3.quantity': 'SL4',
  'inboundShipmentItems.0.shipmentId': 'URL1',
  'inboundShipmentItems.1.shipmentId': 'URL2',
  'inboundShipmentItems.2.shipmentId': 'URL3',
  'inboundShipmentItems.3.shipmentId': 'URL4',
  'inboundShipmentItems.0.creationDate': 'Creation Date 1',
  'inboundShipmentItems.1.creationDate': 'Creation Date 2',
  'inboundShipmentItems.2.creationDate': 'Creation Date 3',
  'inboundShipmentItems.3.creationDate': 'Creation Date 4',
  'inboundShipmentItems.0.estimatedDeliveryDate': 'Estimate Delivery 1',
  'inboundShipmentItems.1.estimatedDeliveryDate': 'Estimate Delivery 2',
  'inboundShipmentItems.2.estimatedDeliveryDate': 'Estimate Delivery 3',
  'inboundShipmentItems.3.estimatedDeliveryDate': 'Estimate Delivery 4',
}

interface ITableRow {
  row: {
    original: IAioSku
  }
}

export interface ISortTypeParams {
  original: IAioSku
}

const BASIC_COLUMNS = [
  {
    accessor: 'imageUrl',
    Cell: ({ row: { original } }: ITableRow) => {
      return <img src={original.asin.imageUrl} alt="" width={40} height={40} />
    },
    disableSortBy: true,
    disabledVisibilitySetting: true,
  },
  {
    accessor: 'asin.asinCode',
    disableSortBy: true,
    disabledVisibilitySetting: true,
  },
  {
    accessor: 'fnsku',
    Cell: ({ row: { original } }: ITableRow) => {
      const fnskus = [original, ...original.others].map((sku) => sku.fnsku)
      return toLineTextFromArr(fnskus)
    },
    disableSortBy: true,
    disabledVisibilitySetting: true,
  },
  {
    accessor: 'account',
    Cell: ({ row: { original } }: ITableRow) => {
      const accountIds = [original, ...original.others].map((sku) =>
        AccountUtils.getAccountName(sku.asin.accountId),
      )
      return toLineTextFromArr(accountIds)
    },
    disableSortBy: true,
    disabledVisibilitySetting: true,
  },
  {
    accessor: 'details',
    Cell: ({ row: { original } }: ITableRow) => {
      const name = original.asin.name ?? '[no-name]'
      const price = original.price ?? '[no-price]'
      return `${name} / ${price} USD`
    },
    fixedWidth: 400,
    disableSortBy: true,
  },
  {
    accessor: 'asin.assignee',
    Filter: ColumnFilter,
    disableSortBy: true,
  },
  {
    accessor: 'cogs',
    Cell: ({ row: { original } }: ITableRow) => {
      return _.round(original.asin.cogs, 2)
    },
    disableSortBy: true,
  },
  {
    accessor: 'asin.ship',
    disableSortBy: true,
  },
  {
    accessor: 'asin.notes',
    disableSortBy: true,
  },
  {
    accessor: 'lSet',
    Cell: ({ row: { original } }: ITableRow) => {
      return original.asin.containerSpec?.lSet ?? '-'
    },
    disableSortBy: true,
  },
  {
    accessor: 'wSet',
    Cell: ({ row: { original } }: ITableRow) => {
      return original.asin.containerSpec?.wSet ?? '-'
    },
    disableSortBy: true,
  },
  {
    accessor: 'hSet',
    Cell: ({ row: { original } }: ITableRow) => {
      return original.asin.containerSpec?.hSet ?? '-'
    },
    disableSortBy: true,
  },
  {
    accessor: 'gwSet',
    Cell: ({ row: { original } }: ITableRow) => {
      return original.asin.containerSpec?.gwSet ?? '-'
    },
    disableSortBy: true,
  },
  {
    accessor: 'lCtn',
    Cell: ({ row: { original } }: ITableRow) => {
      return original.asin.containerSpec?.lCtn ?? '-'
    },
    disableSortBy: true,
  },
  {
    accessor: 'wCtn',
    Cell: ({ row: { original } }: ITableRow) => {
      return original.asin.containerSpec?.wCtn ?? '-'
    },
    disableSortBy: true,
  },
  {
    accessor: 'hCtn',
    Cell: ({ row: { original } }: ITableRow) => {
      return original.asin.containerSpec?.hCtn ?? '-'
    },
    disableSortBy: true,
  },
  {
    accessor: 'qtCtn',
    Cell: ({ row: { original } }: ITableRow) => {
      return original.asin.containerSpec?.qtCtn ?? '-'
    },
    disableSortBy: true,
  },
  {
    accessor: 'gwCtn',
    Cell: ({ row: { original } }: ITableRow) => {
      return original.asin.containerSpec?.gwCtn ?? '-'
    },
    disableSortBy: true,
  },
  {
    accessor: 'vwExpSet',
    Cell: ({ row: { original } }: ITableRow) => {
      const vwExpSet = ProductUtils.calcVwExpSet(original.asin.containerSpec)
      return _.isNil(vwExpSet) ? '-' : _.round(vwExpSet, 3)
    },
    disableSortBy: true,
  },
  {
    accessor: 'vwSeaSet',
    Cell: ({ row: { original } }: ITableRow) => {
      const vwSeaSet = ProductUtils.calcVwSeaSet(original.asin.containerSpec)
      return _.isNil(vwSeaSet) ? '-' : _.round(vwSeaSet, 3)
    },
    disableSortBy: true,
  },
  {
    accessor: 'cbmSet',
    Cell: ({ row: { original } }: ITableRow) => {
      const cbmSet = ProductUtils.calcCbmSet(original.asin.containerSpec)
      return _.isNil(cbmSet) ? '-' : _.round(cbmSet, 5)
    },
    disableSortBy: true,
  },
  {
    accessor: 'avaFC',
    Cell: ({ row: { original } }: ITableRow) => {
      const avaFCs = [original, ...original.others].map((sku) => ProductUtils.calcAvaFC(sku))
      const sumValue = ProductUtils.sum(avaFCs)
      return sumWithDetails(avaFCs, sumValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const avaFCs1 = [a.original, ...a.original.others].map((sku) => ProductUtils.calcAvaFC(sku))
      const sumValue1 = ProductUtils.sum(avaFCs1)
      const avaFCs2 = [b.original, ...b.original.others].map((sku) => ProductUtils.calcAvaFC(sku))
      const sumValue2 = ProductUtils.sum(avaFCs2)
      return sumValue1 - sumValue2
    },
  },
  {
    accessor: 'sales30d',
    Cell: ({ row: { original } }: ITableRow) => {
      const sales30ds = [original, ...original.others].map((sku) => sku.sales30d)
      const sumValue = ProductUtils.sum(sales30ds)
      return sumWithDetails(sales30ds, sumValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const sales30ds1 = [a.original, ...a.original.others].map((sku) => sku.sales30d)
      const sumValue1 = ProductUtils.sum(sales30ds1)
      const sales30ds2 = [b.original, ...b.original.others].map((sku) => sku.sales30d)
      const sumValue2 = ProductUtils.sum(sales30ds2)
      return sumValue1 - sumValue2
    },
  },
  {
    accessor: 'inShip3',
    Cell: ({ row: { original } }: ITableRow) => {
      const values = [original, ...original.others].map((sku) => ProductUtils.calcInShip3(sku))
      const sumValue = ProductUtils.sum(values)
      return sumWithDetails(values, sumValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valuesA = [a.original, ...a.original.others].map((sku) => ProductUtils.calcInShip3(sku))
      const sumValueA = ProductUtils.sum(valuesA)
      const valuesB = [b.original, ...b.original.others].map((sku) => ProductUtils.calcInShip3(sku))
      const sumValueB = ProductUtils.sum(valuesB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'close6',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 6),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 6),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 6),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'close4',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 4),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 4),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 4),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'close3d5',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 3.5),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 3.5),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 3.5),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'close2d5',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 2.5),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 2.5),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 2.5),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'close1d5',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 1.5),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 1.5),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 1.5),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'close1',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 1),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 1),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 1),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'close0d5',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 0.5),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 0.5),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseValue(sku, 0.5),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'inShip3Fw',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 3),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 3),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 3),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'close6Fw',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 6),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 6),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 6),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'close4Fw',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 4),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 4),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 4),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'close3d5Fw',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 3.5),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 3.5),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 3.5),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'close2d5Fw',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 2.5),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 2.5),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 2.5),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'close1d5Fw',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 1.5),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 1.5),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 1.5),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'close1Fw',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 1),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 1),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 1),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'close0d5Fw',
    Cell: ({ row: { original } }: ITableRow) => {
      const closeValue = [original, ...original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 0.5),
      )
      return toNumberFromArrSum(closeValue)
    },
    sortDescFirst: true,
    sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
      const valueA = [a.original, ...a.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 0.5),
      )
      const sumValueA = ProductUtils.sum(valueA)
      const valueB = [b.original, ...b.original.others].map((sku) =>
        ProductUtils.calcCloseFwValue(sku, 0.5),
      )
      const sumValueB = ProductUtils.sum(valueB)
      return sumValueA - sumValueB
    },
  },
  {
    accessor: 'waitForBuying',
    Cell: ({ row: { original } }: ITableRow) => {
      const waitForBuyings = [original, ...original.others].map((sku) => sku.waitForBuying)
      const sumValue = ProductUtils.sum(waitForBuyings)
      return sumWithDetails(waitForBuyings, sumValue)
    },
    sortDescFirst: true,
    sortType: (a: any, b: any) => {
      const waitForBuyings1 = [a.original, ...a.original.others].map((sku) => sku.waitForBuying)
      const sumValue1 = ProductUtils.sum(waitForBuyings1)
      const waitForBuyings2 = [b.original, ...b.original.others].map((sku) => sku.waitForBuying)
      const sumValue2 = ProductUtils.sum(waitForBuyings2)
      return sumValue1 - sumValue2
    },
  },
  {
    accessor: 'assignmentDate',
    Cell: ({ row: { original } }: ITableRow) => {
      const assignmentDates = [original, ...original.others].map((sku) => sku.assignmentDate)
      return toLineTextFromArr(assignmentDates)
    },
    disableSortBy: true,
  },
  {
    accessor: 'goingToFw',
    Cell: ({ row: { original } }: ITableRow) => {
      const goingToFws = [original, ...original.others].map((sku) => sku.goingToFw)
      const sumValue = ProductUtils.sum(goingToFws)
      return sumWithDetails(goingToFws, sumValue)
    },
    sortDescFirst: true,
    sortType: (a: any, b: any) => {
      const goingToFws1 = [a.original, ...a.original.others].map((sku) => sku.goingToFw)
      const sumValue1 = ProductUtils.sum(goingToFws1)
      const goingToFws2 = [b.original, ...b.original.others].map((sku) => sku.goingToFw)
      const sumValue2 = ProductUtils.sum(goingToFws2)
      return sumValue1 - sumValue2
    },
  },
  {
    accessor: 'estimatedReceivedDate',
    Cell: ({ row: { original } }: ITableRow) => {
      const estimatedReceivedDates = [original, ...original.others].map(
        (sku) => sku.estimatedReceivedDate,
      )
      return toLineTextFromArr(estimatedReceivedDates)
    },
    disableSortBy: true,
  },
  {
    accessor: 'inChineseStock',
    Cell: ({ row: { original } }: ITableRow) => {
      const inChineseStocks = [original, ...original.others].map((sku) => sku.inChineseStock)
      const sumValue = ProductUtils.sum(inChineseStocks)
      return sumWithDetails(inChineseStocks, sumValue)
    },
    sortDescFirst: true,
    sortType: (a: any, b: any) => {
      const inChineseStocks1 = [a.original, ...a.original.others].map((sku) => sku.inChineseStock)
      const sumValue1 = ProductUtils.sum(inChineseStocks1)
      const inChineseStocks2 = [b.original, ...b.original.others].map((sku) => sku.inChineseStock)
      const sumValue2 = ProductUtils.sum(inChineseStocks2)
      return sumValue1 - sumValue2
    },
  },
  {
    accessor: 'receivedDate',
    Cell: ({ row: { original } }: ITableRow) => {
      const receivedDates = [original, ...original.others].map((sku) => sku.receivedDate)
      return toLineTextFromArr(receivedDates)
    },
    disableSortBy: true,
  },
  {
    accessor: 'vwExp',
    Cell: ({ row: { original } }: ITableRow) => {
      const vwExps = [original, ...original.others].map((sku) => ProductUtils.calcVwExp(sku))
      return toNumberFromArrSum(vwExps)
    },
    disableSortBy: true,
  },
  {
    accessor: 'vwSea',
    Cell: ({ row: { original } }: ITableRow) => {
      const vwSeas = [original, ...original.others].map((sku) => ProductUtils.calcVwSea(sku))
      return toNumberFromArrSum(vwSeas)
    },
    disableSortBy: true,
  },
  {
    accessor: 'cbm',
    Cell: ({ row: { original } }: ITableRow) => {
      const cbms = [original, ...original.others].map((sku) => ProductUtils.calcCbm(sku))
      return toNumberFromArrSum(cbms, 4)
    },
    disableSortBy: true,
  },
  {
    accessor: 'gw',
    Cell: ({ row: { original } }: ITableRow) => {
      const gws = [original, ...original.others].map((sku) => ProductUtils.calcGw(sku))
      return toNumberFromArrSum(gws)
    },
    disableSortBy: true,
  },
]

const SHIPMENT_COLUMNS = [0, 1, 2, 3].reduce((columns: any[], index: number) => {
  const columnGroup = [
    {
      accessor: `inboundShipmentItems.${index}.quantity`,
      Cell: ({ row: { original } }: ITableRow) => {
        const quantityShippeds = [original, ...original.others].map(
          (sku) => sku.inboundItems[index]?.quantityShipped,
        )
        const quantityReceiveds = [original, ...original.others].map(
          (sku) => sku.inboundItems[index]?.quantityReceived,
        )
        return showQuantity(quantityShippeds, quantityReceiveds)
      },
      sortDescFirst: true,
      sortType: (a: ISortTypeParams, b: ISortTypeParams) => {
        const valuesA = [a.original, ...a.original.others].map(
          (sku) => sku.inboundItems[index]?.quantityShipped,
        )
        const sumValueA = ProductUtils.sum(valuesA)
        const valuesB = [b.original, ...b.original.others].map(
          (sku) => sku.inboundItems[index]?.quantityShipped,
        )
        const sumValueB = ProductUtils.sum(valuesB)
        return sumValueA - sumValueB
      },
    },
    {
      accessor: `inboundShipmentItems.${index}.shipmentId`,
      Cell: ({ row: { original } }: ITableRow) => {
        const shipmentIds = [original, ...original.others].map(
          (sku) => sku.inboundItems[index]?.shipmentId,
        )
        return toLineUrlFromShipmentIdArr(shipmentIds)
      },
      fixedWidth: 125,
      disableSortBy: true,
    },
    {
      accessor: `inboundShipmentItems.${index}.creationDate`,
      Cell: ({ row: { original } }: ITableRow) => {
        const creationDates = [original, ...original.others].map((sku) => {
          const createdAt = sku.inboundItems[index]?.createdAt
          const creationDate = createdAt ? dayjs(createdAt).format('YYYY-MM-DD') : '-'
          return creationDate
        })
        return toLineTextFromArr(creationDates)
      },
      sortDescFirst: true,
      sortType: (a: ISortTypeParams, b: ISortTypeParams, _: any, desc: boolean) => {
        const defaultForUndefined = desc ? 0 : Infinity
        const aCreatedAtsUnix = [a.original, ...a.original.others].map((sku) => {
          const createdAt = sku.inboundItems[index]?.createdAt
          return createdAt ? dayjs(createdAt).unix() : defaultForUndefined
        })
        const bCreatedAtsUnix = [b.original, ...b.original.others].map((sku) => {
          const createdAt = sku.inboundItems[index]?.createdAt
          return createdAt ? dayjs(createdAt).unix() : defaultForUndefined
        })
        const aEarlestCreatedAt = Math.min(...aCreatedAtsUnix)
        const bEarlestCreatedAt = Math.min(...bCreatedAtsUnix)
        return aEarlestCreatedAt - bEarlestCreatedAt
      },
    },
    {
      accessor: `inboundShipmentItems.${index}.estimatedDeliveryDate`,
      Cell: ({ row: { original } }: ITableRow) => {
        const estimatedDeliveryDates = [original, ...original.others].map(
          (sku) => sku.inboundItems[index]?.estimatedDeliveryDate,
        )
        return toLineTextFromArr(estimatedDeliveryDates)
      },
      sortDescFirst: true,
      sortType: (a: ISortTypeParams, b: ISortTypeParams, _: any, desc: boolean) => {
        const defaultForUndefined = desc ? 0 : Infinity
        const estimatedDeliveryDatesUnix = [a.original, ...a.original.others].map((sku) => {
          const estimatedReceivedDate = sku.inboundItems[index]?.estimatedDeliveryDate
          return estimatedReceivedDate ? dayjs(estimatedReceivedDate).unix() : defaultForUndefined
        })
        const bEstimatedDeliveryDatesUnix = [b.original, ...b.original.others].map((sku) => {
          const estimatedReceivedDate = sku.inboundItems[index]?.estimatedDeliveryDate
          return estimatedReceivedDate ? dayjs(estimatedReceivedDate).unix() : defaultForUndefined
        })
        const aEarlestEstimatedDeliveryDate = Math.min(...estimatedDeliveryDatesUnix)
        const bEarlestEstimatedDeliveryDate = Math.min(...bEstimatedDeliveryDatesUnix)
        return aEarlestEstimatedDeliveryDate - bEarlestEstimatedDeliveryDate
      },
    },
  ]
  return [...columns, ...columnGroup]
}, [])

export const PRODUCT_TABLE_COLUMNS = [...BASIC_COLUMNS, ...SHIPMENT_COLUMNS]

export const VISIBILITY_PRODUCT_TABLE_COLUMNS = PRODUCT_TABLE_COLUMNS.filter((column) => {
  return !column.disabledVisibilitySetting
})

export const STICKY_COLUMNS = [
  {
    id: 'imageUrl',
    width: '40px',
    left: '0px',
  },
  {
    id: 'asin',
    width: '100px',
    left: '49px',
  },
  {
    id: 'fnsku',
    width: '100px',
    left: '158px',
  },
  {
    id: 'server',
    left: '267px',
  },
]
