import { useDisclosure } from '@mantine/hooks'
import { createRef, useCallback, useImperativeHandle, useState } from 'react'
import SidebarEditAsin from '../Modals/SidebarEditAsin'

const layerSidebarAsinRef = createRef<any>()

export const useLayerAsinSidebar = () => {
  const openSidebarEditAsin = useCallback((asinId: string) => {
    layerSidebarAsinRef.current?.openSidebarEditAsin(asinId)
  }, [])

  return { openSidebarEditAsin }
}

export const LayerAsinSidebar = () => {
  const [asinId, setAsinId] = useState<string>()
  const [opened, { open, close }] = useDisclosure(false)

  const closeSidebarEditAsin = () => {
    setAsinId(undefined)
    close()
  }

  useImperativeHandle(
    layerSidebarAsinRef,
    () => ({
      openSidebarEditAsin: (newAsinId: string) => {
        setAsinId(newAsinId)
        open()
      },
    }),
    [setAsinId, open],
  )

  return (
    <>
      {asinId && <SidebarEditAsin opened={opened} onClose={closeSidebarEditAsin} asinId={asinId} />}
    </>
  )
}
