import { useMutation, useQuery } from '@tanstack/react-query'
import { QUERY_KEY } from '../../constants'
import { AuthRequest } from './request'
import { SearchAsinsDto, UpdateAsinDto, UpdateByAsinCodeDto } from './dto'
import { IAsin, ISkuStats } from '../../shared/interfaces-v2'

export const useSearchAsins = (payload: SearchAsinsDto) => {
  const { accountId } = payload
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_ASINS, accountId],
    queryFn: async (): Promise<IAsin[]> => {
      const { data } = await AuthRequest.post(`asins/search`, payload)
      return data
    },
    enabled: false,
  })
}

export const useGetAsin = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.GET_ONE_ASIN, id],
    queryFn: async (): Promise<IAsin> => {
      const { data } = await AuthRequest.get(`asins/${id}`)
      return data
    },
  })
}

export const useUpdateAsin = (id: string) => {
  return useMutation({
    mutationFn: async (
      updateAsinDto: UpdateAsinDto,
    ): Promise<IAsin & { skusStats: ISkuStats[] }> => {
      const { data } = await AuthRequest.patch(`asins/${id}`, updateAsinDto)
      return data
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}

export const useUpdateAsinByAsinCode = (accountId: string) => {
  return useMutation({
    mutationFn: async (payload: UpdateByAsinCodeDto): Promise<IAsin> => {
      const { data } = await AuthRequest.patch(
        `accounts/${accountId}/asins/update-by-asin-code`,
        payload,
      )
      return data
    },
  })
}
