import { TableCell, TableRow, styled } from '@mui/material'
import { grey } from '@mui/material/colors'
import { STICKY_COLUMNS } from './constants'

export const ProductTableCell = styled(TableCell)`
  padding: 4px;
  border-right: 1px solid ${grey[100]};
`

const StripedTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white',
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.grey[50],
  },
}))

export default function ProductRow({ row }: any) {
  return (
    <StripedTableRow
      {...row.getRowProps()}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      {row.cells.map((cell: any, idx: number) => {
        return (
          <ProductTableCell
            key={cell.column.id}
            data-testid={cell.column.id}
            size="small"
            align="center"
            {...cell.getCellProps()}
            sx={
              idx < STICKY_COLUMNS.length
                ? {
                    position: 'sticky',
                    left: STICKY_COLUMNS[idx].left,
                    zIndex: 1,
                    background: grey[50],
                    minWidth: STICKY_COLUMNS[idx].width,
                    maxWidth: STICKY_COLUMNS[idx].width,
                  }
                : {
                    minWidth: cell.column.fixedWidth ?? 100,
                    maxWidth: cell.column.fixedWidth ?? 100,
                  }
            }
          >
            {cell.render('Cell')}
          </ProductTableCell>
        )
      })}
    </StripedTableRow>
  )
}
