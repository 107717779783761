import { LayerCampaign } from './Layers/LayerCampaign'
import { LayerCreateFullCampaigns } from './Layers/LayerCreateFullCampaigns'
import TablePPC from './TablePPC'

export default function PagePPC() {
  return (
    <>
      <TablePPC />
      <LayerCreateFullCampaigns />
      <LayerCampaign />
    </>
  )
}
