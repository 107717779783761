/* eslint-disable react/jsx-key */
import _ from 'lodash'
import { useState, useEffect, useContext } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputBase,
  TablePagination,
  Toolbar,
  styled,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import SettingsIcon from '@mui/icons-material/Settings'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ProductRow from './RowProduct'
import { CUSTOM_CELL, PRODUCT_LABEL, PRODUCT_TABLE_COLUMNS, STICKY_COLUMNS } from './constants'
import BulkActionDropdown from './DropdownBulkAction'
import {
  useFilters,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table'
import { grey } from '@mui/material/colors'
import { Outlet } from 'react-router-dom'
import { ProductsContext } from '../../contexts/products/products.context'
import TableSettingsModal from './ModalTableSettings'
import CsvExport from './ModalExportCsv'
import CsvImport from './ModalImportCsv'
import { ISkuStats } from '../../shared/interfaces-v2'
import { useAccountStore, useSearchSkusStats, useSkuStatsStore } from '../../hooks'

const HeaderTableCell = styled(TableCell)`
  position: sticky;
  top: 0px;
  height: 50px;
  padding: 4px;
  border-right: 1px solid ${grey[100]};
  background: ${grey[50]};
`

const FilterHeaderTableCell = styled(TableCell)`
  position: sticky;
  padding: 4px;
  border-right: 1px solid ${grey[100]};
  background: ${grey[50]};
  top: 58.5px;
`

type SortField =
  | 'avaFC'
  | 'sales30d'
  | 'inShip3'
  | 'close6'
  | 'close4'
  | 'close3d5'
  | 'close2d5'
  | 'close1d5'
  | 'close1'
  | 'close0d5'
  | 'inShip3Fw'
  | 'close6Fw'
  | 'close4Fw'
  | 'close3d5Fw'
  | 'close2d5Fw'
  | 'close1d5Fw'
  | 'close1Fw'
  | 'close0d5Fw'
  | 'waitForBuying'
  | 'goingToFw'
  | 'inChineseStock'
  | 'inboundItems.0.quantityShipped'
  | 'inboundItems.0.createdAt'
  | 'inboundItems.0.estimatedDeliveryDate'
  | 'inboundItems.1.quantityShipped'
  | 'inboundItems.1.createdAt'
  | 'inboundItems.1.estimatedDeliveryDate'
  | 'inboundItems.2.quantityShipped'
  | 'inboundItems.2.createdAt'
  | 'inboundItems.2.estimatedDeliveryDate'
  | 'inboundItems.3.quantityShipped'
  | 'inboundItems.3.createdAt'
  | 'inboundItems.3.estimatedDeliveryDate'

type SortElementType = 'string' | 'number' | 'date'

const SORT_TYPE_MAP: Record<SortElementType, SortField[]> = {
  number: [
    'avaFC',
    'sales30d',
    'inShip3',
    'close6',
    'close4',
    'close3d5',
    'close2d5',
    'close1d5',
    'close1',
    'close0d5',
    'inShip3Fw',
    'close6Fw',
    'close4Fw',
    'close3d5Fw',
    'close2d5Fw',
    'close1d5Fw',
    'close1Fw',
    'close0d5Fw',
    'waitForBuying',
    'goingToFw',
    'inChineseStock',
    'inboundItems.0.quantityShipped',
    'inboundItems.1.quantityShipped',
    'inboundItems.2.quantityShipped',
    'inboundItems.3.quantityShipped',
  ],
  string: [],
  date: [
    'inboundItems.0.estimatedDeliveryDate',
    'inboundItems.1.estimatedDeliveryDate',
    'inboundItems.2.estimatedDeliveryDate',
    'inboundItems.3.estimatedDeliveryDate',
    'inboundItems.0.createdAt',
    'inboundItems.1.createdAt',
    'inboundItems.2.createdAt',
    'inboundItems.3.createdAt',
  ],
}

enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

interface ISortConfig {
  field: SortField
  direction: SortDirection
}

export default function Products() {
  const { activeAccount } = useAccountStore()
  const [keyword, setKeyword] = useState<string>('')
  const [showDeleted, setShowDeleted] = useState<boolean>(false)
  const [sortConfig, setSortConfig] = useState<ISortConfig | null>(null)
  const { setSelectedSkuIds } = useContext(ProductsContext)
  const [matchedProducts, setMatchedProducts] = useState<ISkuStats[]>([])
  const [openTableSettingsModal, setOpenTableSettingsModal] = useState<boolean>(false)

  const { hiddenColumnIds } = useContext(ProductsContext)

  const { skuStats, setSkuStats } = useSkuStatsStore()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    page,
    gotoPage,
    setPageSize,
    setHiddenColumns,
    state: { pageSize, pageIndex },
  } = useTable(
    {
      // @ts-expect-error
      columns: PRODUCT_TABLE_COLUMNS,
      data: matchedProducts,
      initialState: {
        pageSize: 50,
        hiddenColumns: hiddenColumnIds,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Add the selection column to the beginning of the visible columns
        {
          id: 'selection',
          disableResizing: true,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: CUSTOM_CELL.SELECTION,
          disableSortBy: true,
        },
        ...columns,
      ])
    },
  )

  useEffect(() => {
    setHiddenColumns(hiddenColumnIds)
  }, [hiddenColumnIds])

  const { data: fetchedSkusStats } = useSearchSkusStats(
    {
      accountId: activeAccount?.id ?? '',
      isDeleted: showDeleted ? undefined : false,
    },
    {
      enabled: !!activeAccount,
    },
  )
  useEffect(() => {
    if (fetchedSkusStats) {
      setSkuStats(fetchedSkusStats)
    }
  }, [fetchedSkusStats])

  useEffect(() => {
    setSelectedSkuIds(selectedFlatRows.map((row) => row.original.id))
  }, [selectedFlatRows])

  useEffect(() => {
    const newMatchedProducts = skuStats.filter((skuStats: ISkuStats) => {
      return isMatchedKeyword(keyword, skuStats)
    })
    sort(newMatchedProducts, sortConfig)
    setMatchedProducts([...newMatchedProducts])
  }, [skuStats, keyword, sortConfig])

  const handleChangeKeyword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setKeyword(event.target.value.toLocaleLowerCase())
  }

  const isMatchedKeyword = (keyword: string, product: ISkuStats) => {
    if (_.isEmpty(keyword)) {
      return true
    }
    const skuFieldsToSearch = ['asinName', 'skuCode', 'fnsku', 'asinCode']
    const inboundItemFieldsToSearch = ['shipmentId']
    const textForSearching: string[] = []
    skuFieldsToSearch.forEach((field) => {
      const fieldValue = _.get(product, field)
      textForSearching.push(fieldValue)
    })
    inboundItemFieldsToSearch.forEach((field) => {
      _.forEach(product.inboundItems, (inboundItem) => {
        const fieldValue = _.get(inboundItem, field)
        textForSearching.push(fieldValue)
      })
    })
    return textForSearching.some((text) => {
      return _.includes(_.toLower(text), keyword)
    })
  }

  const sort = (skuStats: ISkuStats[], sortConfig: ISortConfig | null) => {
    if (!sortConfig) return
    const { field, direction } = sortConfig

    let sortType: SortElementType | null = null
    for (const [key, value] of Object.entries(SORT_TYPE_MAP)) {
      if (value.includes(field)) {
        sortType = key as SortElementType
      }
    }
    const directionNum = direction === SortDirection.ASC ? 1 : -1
    skuStats.sort((a, b) => {
      switch (sortType) {
        case 'string': {
          const aValue = _.get(a, field, '') as string
          const bValue = _.get(b, field, '') as string
          return aValue.localeCompare(bValue) * directionNum
        }
        case 'number': {
          const aValue = _.get(a, field, 0)
          const bValue = _.get(b, field, 0)
          return (Number(aValue) - Number(bValue)) * directionNum
        }
        case 'date': {
          const aValue = _.get(a, field, '') as string
          const bValue = _.get(b, field, '') as string
          if (!aValue && !bValue) {
            return 0
          } else if (!aValue) {
            return 1
          } else if (!bValue) {
            return -1
          }
          return (new Date(aValue).getTime() - new Date(bValue).getTime()) * directionNum
        }
        default:
          throw new Error(`Invalid sort type: ${sortType}`)
      }
    })
  }

  const onSort = (field: any) => {
    setSortConfig((prev) => {
      const newSortConfig =
        !prev?.field || prev?.field !== field
          ? {
              field,
              direction: SortDirection.DESC,
            }
          : {
              field,
              direction:
                prev?.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
            }
      return newSortConfig
    })
  }

  return (
    <>
      <Toolbar disableGutters>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Paper
            id="search-form"
            component="form"
            sx={{ display: 'flex', alignItems: 'center', width: 400 }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              value={keyword}
              onChange={handleChangeKeyword}
              data-testid="search-input"
            />
            <IconButton
              type="button"
              sx={{ p: '10px' }}
              aria-label="search"
              data-testid="search-button"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <BulkActionDropdown />
          <Button onClick={() => setShowDeleted((value) => !value)}>
            {showDeleted ? 'Hide' : 'Show'} Deleted
          </Button>
          {matchedProducts.length > 0 && <CsvExport />}
          {matchedProducts.length > 0 && <CsvImport />}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <TablePagination
          component="div"
          count={rows.length}
          page={pageIndex}
          onPageChange={(event, newPageIndex) => {
            gotoPage(newPageIndex)
          }}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(event) => {
            setPageSize(parseInt(event.target.value))
          }}
          rowsPerPageOptions={[25, 50, 100, 200]}
          data-testid="table-pagination"
        />
        <Button
          id="open-table-settings-button"
          onClick={() => {
            setOpenTableSettingsModal(true)
          }}
        >
          <SettingsIcon />
        </Button>
      </Toolbar>
      <TableContainer sx={{ height: 'calc(100vh - 170px)' }}>
        <Table {...getTableProps()} stickyHeader id="table-products">
          <TableHead>
            {headerGroups.map((headerGroup, headerGroupIdx) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroupIdx}>
                {headerGroup.headers.map((column, idx) => {
                  return (
                    <HeaderTableCell
                      onClick={() => {
                        if (!column.disableSortBy) {
                          onSort(column.id)
                        }
                      }}
                      size="small"
                      align="center"
                      {...column.getHeaderProps()}
                      sx={
                        idx < STICKY_COLUMNS.length
                          ? {
                              left: STICKY_COLUMNS[idx].left,
                              zIndex: 100,
                              minWidth: STICKY_COLUMNS[idx].width,
                              maxWidth: STICKY_COLUMNS[idx].width,
                            }
                          : {
                              minWidth: _.get(column, 'fixedWidth') ?? 100,
                              maxWidth: _.get(column, 'fixedWidth') ?? 100,
                            }
                      }
                      style={{ cursor: column.disableSortBy ? 'default' : 'pointer' }}
                      data-testid={`header-cell-${column.id}`}
                    >
                      {column.id === 'selection'
                        ? column.render('Header')
                        : _.get(PRODUCT_LABEL, column.id)}
                      {sortConfig?.field === column.id ? (
                        sortConfig.direction === SortDirection.ASC ? (
                          <KeyboardArrowUpIcon
                            sx={{ verticalAlign: 'top' }}
                            data-testid={`arrow-up${column.id}`}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            sx={{ verticalAlign: 'top' }}
                            data-testid={`arrow-down${column.id}`}
                          />
                        )
                      ) : (
                        ''
                      )}
                    </HeaderTableCell>
                  )
                })}
              </TableRow>
            ))}
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, idx) => {
                  return (
                    <FilterHeaderTableCell
                      size="small"
                      align="center"
                      {...column.getHeaderProps()}
                      sx={
                        idx < STICKY_COLUMNS.length
                          ? {
                              left: STICKY_COLUMNS[idx].left,
                              zIndex: 100,
                              minWidth: STICKY_COLUMNS[idx].width,
                              maxWidth: STICKY_COLUMNS[idx].width,
                            }
                          : {}
                      }
                      data-testid={`filter-header-cell-${column.id}`}
                    >
                      {column.Filter ? column.render('Filter') : null}
                    </FilterHeaderTableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return <ProductRow row={row} key={row.original.id} />
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Outlet />
      <TableSettingsModal
        openHandler={{
          value: openTableSettingsModal,
          set: setOpenTableSettingsModal,
        }}
      />
    </>
  )
}
