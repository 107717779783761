import { useMutation, useQuery } from '@tanstack/react-query'
import { QUERY_KEY } from '../../constants'
import { AuthRequest } from './request'
import { ISku, ISkuStats } from '../../shared/interfaces-v2'
import { SearchSkusDto, UpdateByFnskuDto, UpdateSkuDto } from './dto'
import { SearchSkusQuery } from './query'
import { CustomQueryOptions } from './type'

export const useSearchSkus = <T extends ISku = ISku>(
  payload: SearchSkusDto,
  query: SearchSkusQuery,
  queryOptions: CustomQueryOptions = {},
) => {
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_SKUS, payload, query],
    queryFn: async (): Promise<T[]> => {
      const { data } = await AuthRequest.post(`skus/search`, payload, {
        params: query,
      })
      return data
    },
    ...queryOptions,
  })
}

export const useSearchSkusStats = (
  payload: SearchSkusDto,
  queryOptions: CustomQueryOptions = {},
) => {
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_SKUS, payload],
    queryFn: async (): Promise<ISkuStats[]> => {
      const { data } = await AuthRequest.post(`skus/search-stats`, payload)
      return data
    },
    ...queryOptions,
  })
}

export const useGetSku = (id: string, queryOptions: CustomQueryOptions = {}) => {
  return useQuery({
    queryKey: [QUERY_KEY.GET_ONE_ASIN, id],
    queryFn: async (): Promise<ISku> => {
      const { data } = await AuthRequest.get(`skus/${id}`)
      return data
    },
    ...queryOptions,
  })
}

export const useUpdateSku = (id: string) => {
  return useMutation({
    mutationFn: async (updateSkuDto: UpdateSkuDto): Promise<ISku & { skuStats: ISkuStats }> => {
      const { data } = await AuthRequest.patch(`skus/${id}`, updateSkuDto)
      return data
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}

export const useUpdateSkuByFnsku = (accountId: string) => {
  return useMutation({
    mutationFn: async (payload: UpdateByFnskuDto) => {
      const { data } = await AuthRequest.patch(
        `accounts/${accountId}/skus/update-by-fnsku`,
        payload,
      )
      return data
    },
  })
}

export const useSoftDeleteSkus = () => {
  return useMutation({
    mutationFn: async (ids: string[]) => {
      const { data } = await AuthRequest.post(`skus/soft-delete`, { ids })
      return data
    },
    onError: (error: any) => {
      alert(error)
    },
  })
}

export const useRestoreSkus = () => {
  return useMutation({
    mutationFn: async (ids: string[]) => {
      const { data } = await AuthRequest.post(`skus/restore`, { ids })
      return data
    },
    onError: (error: any) => {
      alert(error)
    },
  })
}
