import { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Checkbox, FormControlLabel, Grid, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { ITableColumn, PRODUCT_LABEL, VISIBILITY_PRODUCT_TABLE_COLUMNS } from './constants'
import { ProductsContext } from '../../contexts/products/products.context'

function getAllKeys(obj: any, parentKey = '') {
  let keys: string[] = []

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((item: any, index: number) => {
        const nestedKeys = getAllKeys(item, `${parentKey}${key}.${index}.`)
        keys = keys.concat(nestedKeys)
      })
    } else if (typeof obj[key] === 'object') {
      const nestedKeys = getAllKeys(obj[key], `${parentKey}${key}.`)
      keys = keys.concat(nestedKeys)
    } else {
      keys.push(`${parentKey}${key}`)
    }
  }

  return keys
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: '80vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

interface TableSettingsModalProps {
  openHandler: {
    value: boolean
    set: Function
  }
}

interface IVisibilitySetting {
  id: string
  label: string
  visibility: boolean
}

export default function TableSettingsModal({ openHandler }: TableSettingsModalProps) {
  const { register, handleSubmit } = useForm<Record<string, boolean>>()
  const { hiddenColumnIds, saveHiddenColumnIds } = useContext(ProductsContext)

  const [visibilitySettings, setVisibilitySettings] = useState<IVisibilitySetting[]>([])

  const onSubmit = async (data: Record<string, boolean>) => {
    const allKeys = getAllKeys(data)
    const columnIdsShouldHidden = allKeys.filter((key: string) => !_.get(data, key))
    saveHiddenColumnIds(columnIdsShouldHidden)
    openHandler.set(false)
  }

  useEffect(() => {
    const columns = VISIBILITY_PRODUCT_TABLE_COLUMNS.map(
      (column: ITableColumn): IVisibilitySetting => {
        return {
          id: column.accessor,
          label: _.get(PRODUCT_LABEL, column.accessor, ''),
          visibility: !hiddenColumnIds.includes(column.accessor),
        }
      },
    )
    setVisibilitySettings(columns)
  }, [hiddenColumnIds])

  return (
    <Modal
      open={openHandler.value}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id="table-settings-modal"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={() => openHandler.set(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          data-test-id="close-table-settings-modal-button"
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Visibility Configuration
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
          data-test-id="table-settings-form"
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {visibilitySettings
                .slice(0, visibilitySettings.length / 2)
                .map((visibilitySetting: IVisibilitySetting) => (
                  <div key={visibilitySetting.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...register(visibilitySetting.id)}
                          defaultChecked={visibilitySetting.visibility}
                          data-test-id={`${visibilitySetting.id}-checkbox`}
                        />
                      }
                      label={visibilitySetting.label}
                    />
                  </div>
                ))}
            </Grid>
            <Grid item xs={6}>
              {visibilitySettings
                .slice(visibilitySettings.length / 2)
                .map((visibilitySetting: IVisibilitySetting) => (
                  <div key={visibilitySetting.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...register(visibilitySetting.id)}
                          defaultChecked={visibilitySetting.visibility}
                          data-test-id={`${visibilitySetting.id}-checkbox`}
                        />
                      }
                      label={visibilitySetting.label}
                    />
                  </div>
                ))}
            </Grid>
          </Grid>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
