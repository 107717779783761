import { ThemeProvider } from '@mui/material'
import AutopriceLogList from './TableAutopriceLogs'
import { muiTheme } from '../../shared/themes'

export default function PageAutopriceLog() {
  return (
    <ThemeProvider theme={muiTheme}>
      <AutopriceLogList />
    </ThemeProvider>
  )
}
