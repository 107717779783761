import dayjs from '../../utils/dayjs.utils'
import { useAccountStore } from '../../hooks'
import _ from 'lodash'
import { ActionIcon, Grid, JsonInput, Table, useMantineTheme } from '@mantine/core'
import { useSearchCampaignAlerts } from '../../hooks/api/useCampaignAlert'
import { LuFileJson } from 'react-icons/lu'
import { useState } from 'react'
import { ICampaignAlert } from '../../shared/interfaces-v2'
import { MARKET_TIMEZONE } from '../../shared/constants'

export default function TableCampaignAlert() {
  const theme = useMantineTheme()
  const { activeAccount } = useAccountStore()
  const { data: campaignAlerts } = useSearchCampaignAlerts({
    accountId: activeAccount?.id ?? '',
    from: dayjs().subtract(3, 'day').startOf('day').toISOString(),
  })

  const [activeCampaignAlert, setActiveCampaignAlert] = useState<ICampaignAlert>()

  return (
    <Grid>
      <Grid.Col span={8}>
        <Table stickyHeader stickyHeaderOffset={64} highlightOnHover verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Time</Table.Th>
              <Table.Th>Message</Table.Th>
              <Table.Th style={{ textAlign: 'center' }}>Context</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {_.map(campaignAlerts, (campaignAlert) => (
              <Table.Tr
                key={campaignAlert.id}
                bg={campaignAlert.id === activeCampaignAlert?.id ? theme.colors.neutral[0] : ''}
              >
                <Table.Td>
                  {dayjs(campaignAlert.createdAt).tz(MARKET_TIMEZONE).format('DD-MM-YYYY HH:mm')}
                </Table.Td>
                <Table.Td>
                  <div dangerouslySetInnerHTML={{ __html: campaignAlert.message }} />
                </Table.Td>
                <Table.Td style={{ textAlign: 'center' }}>
                  <ActionIcon
                    onClick={() => {
                      if (activeCampaignAlert?.id !== campaignAlert.id) {
                        setActiveCampaignAlert(campaignAlert)
                      } else {
                        setActiveCampaignAlert(undefined)
                      }
                    }}
                  >
                    <LuFileJson />
                  </ActionIcon>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Grid.Col>
      <Grid.Col span={4}>
        {activeCampaignAlert && (
          <JsonInput
            value={JSON.stringify(activeCampaignAlert.context, null, 2)}
            autosize
            readOnly
          />
        )}
      </Grid.Col>
    </Grid>
  )
}
