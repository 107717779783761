import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ICompetition } from '../../shared/interfaces'
import { QUERY_KEY } from '../../constants'
import { AuthRequest } from './request'
import { SearchCompetitionsDto, UpdateCompetitionDto, UpsertPriceConfigDto } from './dto'
import { SearchCompetitionsQuery } from './query'
import _ from 'lodash'

export const useSearchCompetitions = (
  searchPayload: SearchCompetitionsDto,
  searchQuery: SearchCompetitionsQuery,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_COMPETITIONS, searchPayload, searchQuery],
    queryFn: async (): Promise<ICompetition[]> => {
      const { populate } = searchQuery
      const { data } = await AuthRequest.post(`competitions/search`, searchPayload, {
        params: { populate },
      })
      return data
    },
    enabled,
  })
}

export const useGetCompetition = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.GET_ONE_COMPETITION, id],
    queryFn: async (): Promise<ICompetition> => {
      const { data } = await AuthRequest.get(`competitions/${id}`)
      return data
    },
  })
}

export const useUpdateCompetition = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (payload: UpdateCompetitionDto): Promise<ICompetition> => {
      const { data } = await AuthRequest.patch(`competitions/${id}`, payload)
      return data
    },
    onSuccess: (updatedCompetition: ICompetition) => {
      queryClient.setQueriesData(
        {
          queryKey: [QUERY_KEY.GET_ONE_COMPETITION, id],
        },
        updatedCompetition,
      )
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}

export const useUpsertPriceConfig = (competitionId: string) => {
  return useMutation({
    mutationFn: async (payload: UpsertPriceConfigDto): Promise<any> => {
      const { data } = await AuthRequest.post(`competitions/${competitionId}/price-config`, payload)
      return data
    },
    onError: (error: any) => {
      alert(_.get(error, 'response.data.message', 'Something went wrong'))
    },
  })
}
