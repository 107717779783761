import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'
import { ActionIcon, Box, Flex, Switch, Text, useMantineTheme } from '@mantine/core'
import { IFormScript } from './type'
import ConditionFields, { DEFAULT_CONDITION } from './ConditionFields'

import { IoMdClose } from 'react-icons/io'
import SetUpAction from './SetupAction'
import { Divider } from '@mui/material'

interface Props {
  formController: UseFormReturn<IFormScript, any>
}

const defaultCase: IFormScript['cases'][number] = {
  enabledAlert: false,
  actionConfig: {},
  conditions: [DEFAULT_CONDITION],
}

const CaseFields = ({ formController }: Props) => {
  const theme = useMantineTheme()
  const { control } = formController
  const {
    fields: caseFields,
    append: appendCase,
    remove: removeCase,
  } = useFieldArray({
    control,
    name: 'cases',
  })

  return (
    <Box>
      {caseFields.map((field, index) => (
        <Box
          key={field.id}
          style={{
            border: `1px solid ${theme.colors.neutral[3]}`,
            borderRadius: 4,
            padding: 8,
            marginTop: 16,
            position: 'relative',
          }}
        >
          <Text fw={700}>Case {index + 1}</Text>
          {caseFields.length > 1 && (
            <ActionIcon
              variant="default"
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                border: 'none',
              }}
            >
              <IoMdClose onClick={() => removeCase(index)} />
            </ActionIcon>
          )}
          <ConditionFields index={index} formController={formController} />
          <Divider />
          <SetUpAction index={index} formController={formController} />
          <Controller
            control={control}
            name={`cases.${index}.enabledAlert`}
            render={({ field: { onChange, value } }) => (
              <Switch
                styles={{
                  root: {
                    display: 'inline-block',
                  },
                }}
                checked={value}
                onChange={onChange}
                label="Turn on alert for this case"
                size="xs"
                mt={8}
              />
            )}
          />
        </Box>
      ))}
      <Flex justify="flex-end">
        <Text
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            color: theme.colors.information[6],
          }}
          onClick={() => appendCase({ ...defaultCase })}
        >
          Add case
        </Text>
      </Flex>
    </Box>
  )
}
export default CaseFields
