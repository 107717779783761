import { useMutation } from '@tanstack/react-query'
import { PublicRequest } from './request'
import _ from 'lodash'

interface ILoginPayload {
  username: string
  password: string
}

interface ILoginResponse {
  accessToken: string
}

export const useLogin = () => {
  return useMutation({
    mutationFn: async (payload: ILoginPayload): Promise<ILoginResponse> => {
      const { username, password } = payload
      const { data } = await PublicRequest.post('auth/login', {
        username,
        password,
      })
      return data
    },
    onError: (error: any) => {
      if (_.get(error, 'response.status') === 401) {
        alert('Invalid username or password')
      } else {
        alert(_.get(error, 'response.data.message', 'Something went wrong'))
      }
    },
  })
}
