import { useDisclosure } from '@mantine/hooks'
import { createRef, useCallback, useImperativeHandle } from 'react'
import ModalCreateFullCampaigns from '../Modals/ModalCreateFullCampaigns'

const layerCreateFullCampaignsRef = createRef<any>()

export const useLayerCreateFullCampaigns = () => {
  const openModalCreateFullCampaigns = useCallback(() => {
    layerCreateFullCampaignsRef.current?.openModalCreateFullCampaigns()
  }, [])

  return { openModalCreateFullCampaigns }
}

export const LayerCreateFullCampaigns = () => {
  const [opened, { open, close }] = useDisclosure(false)

  useImperativeHandle(
    layerCreateFullCampaignsRef,
    () => ({
      openModalCreateFullCampaigns: () => {
        open()
      },
    }),
    [open],
  )

  return (
    <>
      <ModalCreateFullCampaigns opened={opened} onClose={close} />
    </>
  )
}
