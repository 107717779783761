import { useMutation } from '@tanstack/react-query'
import { AuthRequest } from './request'
import {
  SponsoredProductsCreateOrUpdateMatchType,
  SponsoredProductsTargetingType,
} from '../../shared/enums'
import {
  SponsoredProductsCampaign,
  SponsoredProductsCreateCampaign,
  SponsoredProductsAdGroup,
  SponsoredProductsCreateAdGroup,
  SponsoredProductsProductAd,
  SponsoredProductsCreateProductAd,
  SponsoredProductsKeyword,
  SponsoredProductsCreateKeyword,
} from '../../shared/types'

export interface CreateFullCampaignsDto {
  items: Array<{
    targetingType: SponsoredProductsTargetingType
    asin: string
    sku: string
    bid: number
    budget: number
    keywords?: Array<{
      text: string
      matchType: SponsoredProductsCreateOrUpdateMatchType
    }>
  }>
}

interface ConvertedError<T> {
  index: number
  text: string
  payloadItem: T
}

export interface CreateFullCampaignsResp {
  createdCampaigns: SponsoredProductsCampaign[]
  failedCampaigns: Array<ConvertedError<SponsoredProductsCreateCampaign>>
  createdAdGroups: SponsoredProductsAdGroup[]
  failedAdGroups: Array<ConvertedError<SponsoredProductsCreateAdGroup>>
  createdProductAds: SponsoredProductsProductAd[]
  failedProductAds: Array<ConvertedError<SponsoredProductsCreateProductAd>>
  createdKeywords: SponsoredProductsKeyword[]
  failedKeywords: Array<ConvertedError<SponsoredProductsCreateKeyword>>
}

export const useCreateFullCampaigns = (accountId: string) => {
  return useMutation({
    mutationFn: async (payload: CreateFullCampaignsDto): Promise<CreateFullCampaignsResp> => {
      const { data } = await AuthRequest.post(`account/${accountId}/full-campaigns`, payload)
      return data
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}
