import { useEffect } from 'react'
import { PRODUCT_LABEL } from '../constants'
import { Controller, useForm } from 'react-hook-form'
import { DATE_FORMAT, REGEX } from '../../../shared/constants'
import { UpdateSkuDto } from '../../../hooks/api/dto'
import { useGetSku, useSkuStatsStore, useUpdateSku } from '../../../hooks'
import { Box, Button, Drawer, Grid, Group, Loader, LoadingOverlay, TextInput } from '@mantine/core'
import { DRAWER_WIDTH } from '../../../App'
import dayjs from '../../../utils/dayjs.utils'
import { DatePickerInput } from '@mantine/dates'

interface IEditSkuForm {
  waitForBuying: string
  assignmentDate: Date | null
  goingToFw: string
  estimatedReceivedDate: Date | null
  inChineseStock: string
  receivedDate: Date | null
}

interface SidebarEditSkuProps {
  opened: boolean
  onClose: () => void
  skuId: string
}

const SidebarEditSku = ({ opened, onClose, skuId }: SidebarEditSkuProps) => {
  const { data: sku, isFetching } = useGetSku(skuId)
  const { mutate: update, isPending } = useUpdateSku(skuId)

  const { setValue, control, handleSubmit } = useForm<IEditSkuForm>({
    defaultValues: {
      waitForBuying: '',
      assignmentDate: null,
      goingToFw: '',
      estimatedReceivedDate: null,
      inChineseStock: '',
      receivedDate: null,
    },
  })

  const updateSkusStats = useSkuStatsStore((state) => state.updateSkusStats)

  useEffect(() => {
    if (sku) {
      setValue('waitForBuying', `${sku.waitForBuying}`)
      setValue('assignmentDate', sku.assignmentDate ? new Date(sku.assignmentDate) : null)
      setValue('goingToFw', `${sku.goingToFw}`)
      setValue(
        'estimatedReceivedDate',
        sku.estimatedReceivedDate ? new Date(sku.estimatedReceivedDate) : null,
      )
      setValue('inChineseStock', `${sku.inChineseStock}`)
      setValue('receivedDate', sku.receivedDate ? new Date(sku.receivedDate) : null)
    }
  }, [sku])

  const onSubmit = (data: IEditSkuForm) => {
    const {
      waitForBuying,
      assignmentDate,
      goingToFw,
      estimatedReceivedDate,
      inChineseStock,
      receivedDate,
    } = data
    if (Number(waitForBuying) > 0 && !assignmentDate) {
      alert('Please fill Assignment Date')
      return
    }
    if (Number(goingToFw) > 0 && !estimatedReceivedDate) {
      alert('Please fill Estimated Received Date')
      return
    }
    if (Number(inChineseStock) > 0 && !receivedDate) {
      alert('Please fill Received Date')
      return
    }

    const updateSkuDto: UpdateSkuDto = {
      waitForBuying: Number(waitForBuying),
      assignmentDate: assignmentDate ? dayjs(assignmentDate).format(DATE_FORMAT.API) : null,
      goingToFw: Number(goingToFw),
      estimatedReceivedDate: estimatedReceivedDate
        ? dayjs(estimatedReceivedDate).format(DATE_FORMAT.API)
        : null,
      inChineseStock: Number(inChineseStock),
      receivedDate: receivedDate ? dayjs(receivedDate).format(DATE_FORMAT.API) : null,
    }

    if (sku) {
      update(updateSkuDto, {
        onSuccess: (updatedSku) => {
          updateSkusStats([updatedSku.skuStats])
          onClose()
        },
      })
    }
  }

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      title={`Edit SKU (${sku?.fnsku ?? ''})`}
      position="right"
      styles={{
        content: {
          height: `calc(100vh - ${DRAWER_WIDTH / 4}px)`,
          marginTop: DRAWER_WIDTH / 4, // todo: move when refactoring
        },
        title: {
          fontWeight: 700,
        },
      }}
    >
      <LoadingOverlay visible={isFetching || isPending} loaderProps={{ children: <Loader /> }} />
      <Box id="form-edit-sku" component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Col span={6}>
            <Controller
              name="waitForBuying"
              control={control}
              render={({ field: { onChange, value: waitForBuyingValue } }) => (
                <TextInput
                  value={waitForBuyingValue}
                  onChange={({ target: { value: inputValue } }) => {
                    if (REGEX.NUMBER.test(inputValue)) {
                      onChange(inputValue)
                    }
                  }}
                  label={PRODUCT_LABEL.waitForBuying}
                  data-testid="input-waitForBuying"
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="assignmentDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePickerInput
                  clearable
                  value={value}
                  onChange={onChange}
                  label={PRODUCT_LABEL.assignmentDate}
                  data-testid="input-assignmentDate"
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="goingToFw"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  value={value}
                  onChange={({ target: { value: inputValue } }) => {
                    if (REGEX.NUMBER.test(inputValue)) {
                      onChange(inputValue)
                    }
                  }}
                  label={PRODUCT_LABEL.goingToFw}
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="estimatedReceivedDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePickerInput
                  clearable
                  value={value}
                  onChange={onChange}
                  label={PRODUCT_LABEL.estimatedReceivedDate}
                  data-testid="input-estimatedReceivedDate"
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="inChineseStock"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  value={value}
                  onChange={({ target: { value: inputValue } }) => {
                    if (REGEX.NUMBER.test(inputValue)) {
                      onChange(inputValue)
                    }
                  }}
                  label={PRODUCT_LABEL.inChineseStock}
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="receivedDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePickerInput
                  clearable
                  value={value}
                  onChange={onChange}
                  label={PRODUCT_LABEL.receivedDate}
                  data-testid="input-receivedDate"
                />
              )}
            />
          </Grid.Col>
        </Grid>
        <Group mt={24}>
          <Button type="submit" fullWidth>
            Save
          </Button>
        </Group>
      </Box>
    </Drawer>
  )
}

export default SidebarEditSku
