export enum ScriptType {
  SP_SEARCH_TERM = 'SP_SEARCH_TERM',
  SP_KEYWORD = 'SP_KEYWORD',
}

export enum Operator {
  EQ = 'EQ',
  GT = 'GT',
  LT = 'LT',
  GTE = 'GTE',
  LTE = 'LTE',
}

export enum ActionType {
  UPDATE_BID_PERCENTAGE = 'UPDATE_BID_PERCENTAGE',
  UPDATE_BID_FIXED_VALUE = 'UPDATE_BID_FIXED_VALUE',
  UPDATE_BID_FIXED_DELTA = 'UPDATE_BID_FIXED_DELTA',
  UPDATE_BID_ACOS_XDAYS = 'UPDATE_BID_ACOS_XDAYS',
  SWITCH_SCRIPT = 'SWITCH_SCRIPT',
}

export enum SearchTermAttribute {
  // primitive
  matchType = 'matchType',

  // xDays primitive
  spend = 'spend',

  // xDays custom
  profit = 'profit',

  // custom
  budget = 'budget',
  profitNoAds = 'profitNoAds',
  isTarget = 'isTarget',
  availableNumber = 'availableNumber',
}

export enum KeywordAttribute {
  // primitive
  impressions = 'impressions',

  // xDays primitive
  spend = 'spend',
  sales = 'sales',
  clicks = 'clicks',
  purchases = 'purchases', // orders

  // xDays custom
  acos = 'acos',
  profit = 'profit',

  // calculation custom
  profitNoAds = 'profitNoAds',
  isTarget = 'isTarget',

  // DB API custom
  availableNumber = 'availableNumber',

  // external API custom
  currentBid = 'currentBid',
}
