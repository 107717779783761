import axios, { HttpStatusCode } from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL

export const PublicRequest = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
})

export const AuthRequest = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
})

AuthRequest.interceptors.request.use((request) => {
  request.headers.Authorization = `bearer ${localStorage.getItem('accessToken')}`
  return request
})

AuthRequest.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === HttpStatusCode.Unauthorized) {
      localStorage.removeItem('accessToken')
      location.reload()
    }
    return await Promise.reject(error)
  },
)
