import { createTheme } from '@mui/material'

export const muiTheme = createTheme({
  palette: {
    primary: {
      50: '#eff6ff',
      100: '#dbeafe',
      200: '#bfdbfe',
      300: '#93c5fd',
      400: '#60a5fa',
      500: '#3b82f6',
      600: '#2563eb',
      700: '#1d4ed8',
      800: '#1e40af',
      900: '#1e3a8a',
      A100: '#dbeafe',
      A200: '#bfdbfe',
      A400: '#60a5fa',
      A700: '#3b82f6',
    },
    secondary: {
      50: '#f9fafb',
      100: '#f3f4f6',
      200: '#e5e7eb',
      300: '#d1d5db',
      400: '#9ca3af',
      500: '#6b7280',
      600: '#4b5563',
      700: '#374151',
      800: '#1f2937',
      900: '#111827',
      A100: '#f3f4f6',
      A200: '#e5e7eb',
      A400: '#9ca3af',
      A700: '#374151',
    },
    info: {
      50: '#eaf1fb',
      100: '#d5e3f6',
      200: '#c0d5f2',
      300: '#abc7ed',
      400: '#97b9e9',
      500: '#82aae4',
      600: '#2e72d2',
      700: '#255ba8',
      800: '#1c447e',
      900: '#122e54',
      A100: '#d5e3f6',
      A200: '#c0d5f2',
      A400: '#97b9e9',
      A700: '#255ba8',
    },
    success: {
      50: '#f0fdf4',
      100: '#d1fae3',
      200: '#a5f3c7',
      300: '#6ee7a3',
      400: '#36d37a',
      500: '#0ea44f',
      600: '#08873f',
      700: '#047234',
      800: '#06602d',
      900: '#064c24',
      A100: '#d1fae3',
      A200: '#a5f3c7',
      A400: '#36d37a',
      A700: '#047234',
    },
    warning: {
      50: '#fff9eb',
      100: '#fff3d6',
      200: '#fee6a9',
      300: '#fdd672',
      400: '#fbc53c',
      500: '#db8112',
      600: '#b26609',
      700: '#965a0f',
      800: '#804c0d',
      900: '#663f0f',
      A100: '#fff3d6',
      A200: '#fee6a9',
      A400: '#fbc53c',
      A700: '#965a0f',
    },
    error: {
      50: '#fef1f1',
      100: '#fee1e1',
      200: '#fec8c8',
      300: '#fca6a6',
      400: '#f87272',
      500: '#ef4343',
      600: '#e02d2d',
      700: '#ba2525',
      800: '#981b1b',
      900: '#861313',
      A100: '#fee1e1',
      A200: '#fec8c8',
      A400: '#f87272',
      A700: '#ba2525',
    },
  },
})
