import { Box, Button, Flex, Table, Text, useMantineTheme } from '@mantine/core'
import { useSearchScripts } from '../../hooks/api/useScript'
import { useDisclosure } from '@mantine/hooks'
import ModalScript from './ModalScript/ModalScript'
import { useState } from 'react'
import { IScript } from '../../shared/interfaces-v2'

const ListScripts = () => {
  const theme = useMantineTheme()
  const { data: scripts } = useSearchScripts()
  const [activeScript, setActiveScript] = useState<IScript>()

  const [openedModalScript, { open: openModalScript, close: closeModalScript }] = useDisclosure()

  function closeModalScriptAndReset() {
    setActiveScript(undefined)
    closeModalScript()
  }

  function openEditScript(script: IScript) {
    setActiveScript(script)
    openModalScript()
  }

  function openCreateScript() {
    setActiveScript(undefined)
    openModalScript()
  }

  return (
    <>
      <Box maw={1200} mx="auto">
        <Flex>
          <Button ml="auto" variant="outline" onClick={openCreateScript}>
            Create Script
          </Button>
        </Flex>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>ID</Table.Th>
              <Table.Th>Name</Table.Th>
              <Table.Th>Type</Table.Th>
              <Table.Th>Description</Table.Th>
              <Table.Th>Action</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {scripts?.map((script) => {
              return (
                <Table.Tr key={script.id}>
                  <Table.Td>{script.id}</Table.Td>
                  <Table.Td>{script.name}</Table.Td>
                  <Table.Td>{script.type}</Table.Td>
                  <Table.Td>{script.description}</Table.Td>
                  <Table.Td>
                    <Text
                      c={theme.colors.blue[6]}
                      styles={{
                        root: {
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => openEditScript(script)}
                    >
                      Details
                    </Text>
                  </Table.Td>
                </Table.Tr>
              )
            })}
          </Table.Tbody>
        </Table>
      </Box>
      {openedModalScript && (
        <ModalScript
          script={activeScript}
          opened={openedModalScript}
          onClose={closeModalScriptAndReset}
        />
      )}
    </>
  )
}

export default ListScripts
