import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { IconButton, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useForm } from 'react-hook-form'
import { ICompetition } from '../../../shared/interfaces'
import { useGetCompetition, useUpdateCompetition, useCompetitionStore } from '../../../hooks'
import { useEffect } from 'react'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: '80vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

interface ModalEditCompetitionProps {
  competitionId: string
  openHandler: {
    value: boolean
    set: Function
  }
}

interface FormEditCompetitionProps {
  notes: string
}

const ModalEditCompetition = ({ openHandler, competitionId }: ModalEditCompetitionProps) => {
  const { register, handleSubmit, setValue } = useForm<FormEditCompetitionProps>()
  const replaceCompetition = useCompetitionStore((state) => state.replaceCompetition)
  const { mutate: update } = useUpdateCompetition(competitionId)
  const { data: competition } = useGetCompetition(competitionId)

  useEffect(() => {
    if (competition) {
      setValue('notes', competition.notes)
    }
  }, [competition, setValue])

  const onSubmit = (data: FormEditCompetitionProps) => {
    update(
      { notes: data.notes },
      {
        onSuccess: (updatedCompetition: ICompetition) => {
          replaceCompetition(updatedCompetition)
          openHandler.set(false)
        },
      },
    )
  }

  if (!competition) return null
  return (
    <Modal
      id="edit-competition-modal"
      open={openHandler.value}
      disableRestoreFocus
      data-testid="modal-edit-competition"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={() => openHandler.set(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          data-testid="btn-close-modal"
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Edit competition ({competition.asinCode})
        </Typography>
        <Box
          id="edit-competition-form"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            {...register('notes')}
            variant="standard"
            margin="normal"
            fullWidth
            label="Notes"
            autoFocus
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalEditCompetition
