import { useMutation, useQuery } from '@tanstack/react-query'
import { AuthRequest } from './request'
import { QUERY_KEY } from '../../constants'
import { IAcCampaign } from '../../shared/interfaces-v2'
import { ScenarioType } from '../../shared/enums'
import { CustomQueryOptions } from './type'

interface SetProfitFlagPayload {
  campaignId: number
  accountId: string
  profitFlag: boolean
}

interface SetAutocampaignPayload {
  campaignId: number
  accountId: string
  scenarioType: ScenarioType
  scenarioConfig: any
}

interface RemoveAutocampaignPayload {
  campaignId: number
  accountId: string
}

interface SearchAcCampaignsPayload {
  accountId: string
  campaignId?: number
}

export const useSearchAcCampaigns = (
  payload: SearchAcCampaignsPayload,
  queryOptions: CustomQueryOptions = {},
) => {
  const { accountId, campaignId } = payload
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_AC_CAMPAIGNS, accountId, campaignId],
    queryFn: async (): Promise<IAcCampaign[]> => {
      const { data } = await AuthRequest.post(`ac-campaigns/search`, payload)
      return data
    },
    ...queryOptions,
  })
}

export const useSetProfitFlag = () => {
  return useMutation({
    mutationFn: async (payload: SetProfitFlagPayload) => {
      const { data } = await AuthRequest.post(`ac-campaigns/set-profit-flag`, payload)
      return data
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}

export const useSetAutocampaign = () => {
  return useMutation({
    mutationFn: async (payload: SetAutocampaignPayload): Promise<IAcCampaign> => {
      const { data } = await AuthRequest.post(`ac-campaigns/set-autocampaign`, payload)
      return data
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}

export const useRemoveAutocampaign = () => {
  return useMutation({
    mutationFn: async (payload: RemoveAutocampaignPayload): Promise<IAcCampaign> => {
      const { data } = await AuthRequest.post(`ac-campaigns/remove-autocampaign`, payload)
      return data
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}
