import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { DRAWER_WIDTH, MINI_DRAWER_WIDTH } from '../App'
import { styled, useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { ROUTES } from '../shared/constants'
import { FaProductHunt, FaAdversal, FaTasks } from 'react-icons/fa'
import { MdDashboard, MdOutlineSportsMartialArts, MdOutlinePriceChange } from 'react-icons/md'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  ...theme.mixins.toolbar,
}))

interface SideBarProps {
  isSidbarOpen: boolean
  setIsSidbarOpen: Function
}

interface SidebarNavProps {
  iconElem: JSX.Element
  text: string
  routerPath: string
}

export default function SideBar({ isSidbarOpen, setIsSidbarOpen }: SideBarProps) {
  const theme = useTheme()

  const SidebarNav = ({ iconElem, text, routerPath }: SidebarNavProps) => {
    return (
      <ListItem
        disablePadding
        component={Link}
        to={routerPath}
        sx={{ display: 'block' }}
        data-testid="sidebar-nav-item"
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: isSidbarOpen ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isSidbarOpen ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            {iconElem}
          </ListItemIcon>
          <ListItemText
            primary={text}
            sx={{
              opacity: isSidbarOpen ? 1 : 0,
              color: 'text.primary',
            }}
          />
        </ListItemButton>
      </ListItem>
    )
  }

  return (
    <Drawer
      variant="permanent"
      open={isSidbarOpen}
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(isSidbarOpen && {
          width: DRAWER_WIDTH,
          overflowX: 'hidden',
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            overflowX: 'hidden',
          },
        }),
        ...(!isSidbarOpen && {
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowX: 'hidden',
          width: `${MINI_DRAWER_WIDTH}px`,
          '& .MuiDrawer-paper': {
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: `${MINI_DRAWER_WIDTH}px`,
          },
        }),
      }}
    >
      <DrawerHeader>
        <IconButton onClick={() => setIsSidbarOpen(false)} data-testid="close-sidebar-button">
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <SidebarNav
          iconElem={<MdDashboard />}
          text="Dashboard"
          routerPath={ROUTES.DASHBOARD.PATH}
        />
        <Divider />
        <SidebarNav
          iconElem={<FaProductHunt />}
          text="Products"
          routerPath={ROUTES.PRODUCTS.PATH}
        />
        <SidebarNav iconElem={<FaTasks />} text="Tasks" routerPath={ROUTES.TASK.PATH} />
        <SidebarNav
          iconElem={<MdOutlineSportsMartialArts />}
          text="Competitions"
          routerPath={ROUTES.COMPETITION.PATH}
        />
        <SidebarNav
          iconElem={<MdOutlinePriceChange />}
          text="Autoprice Logs"
          routerPath={ROUTES.AUTOPTICE_LOG.PATH}
        />
        <SidebarNav iconElem={<FaAdversal />} text="PPC" routerPath={ROUTES.PPC.PATH} />
      </List>
    </Drawer>
  )
}
