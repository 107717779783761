import { DatePickerInput } from '@mantine/dates'
import {
  Button,
  Flex,
  Loader,
  LoadingOverlay,
  ScrollArea,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core'
import RowRpCampaign from './RowRpCampaign'
import { FaSearch, FaSort } from 'react-icons/fa'
import dayjs from '../../utils/dayjs.utils'
import { useAccountStore, usePPCStore, useSearchRpCampaignSummaries } from '../../hooks'
import { DATE_FORMAT } from '../../shared/constants'
import { useEffect, useState } from 'react'
import { useDebouncedState } from '@mantine/hooks'
import { IRpCampaignSummary } from '../../shared/interfaces-v2'
import _ from 'lodash'
import { useLayerCreateFullCampaigns } from './Layers/LayerCreateFullCampaigns'
import { Link } from 'react-router-dom'

type SortField =
  | 'ssAsinCode'
  | 'spend'
  | 'profit'
  | 'sales'
  | 'purchases'
  | 'unitsSoldClicks'
  | 'impressions'
  | 'clicks'
  | 'scenarioType'
  | 'totalCogs'

type SortElementType = 'string' | 'number'

const SORT_TYPE_MAP: Record<SortField, SortElementType> = {
  ssAsinCode: 'string',
  spend: 'number',
  profit: 'number',
  sales: 'number',
  purchases: 'number',
  unitsSoldClicks: 'number',
  impressions: 'number',
  clicks: 'number',
  scenarioType: 'string',
  totalCogs: 'number',
}

enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

interface ISortConfig {
  field: SortField
  direction: SortDirection
}

interface ISortableTableThProps {
  children: React.ReactNode
  onSort: () => void
}

const SortableTableTh = ({ children, onSort }: ISortableTableThProps) => {
  const theme = useMantineTheme()
  return (
    <Table.Th onClick={onSort}>
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        {children}&nbsp;
        <FaSort color={theme.colors.gray[6]} />
      </div>
    </Table.Th>
  )
}

const TablePPC = () => {
  const automationV2 = localStorage.getItem('automationV2')

  const theme = useMantineTheme()
  const activeAccount = useAccountStore((state) => state.activeAccount)
  const { rpCampaignSummaries, setRpCampaignSummaries } = usePPCStore()
  const yesterday = dayjs().tz('America/Los_Angeles').startOf('day').subtract(1, 'day').toDate()
  const { openModalCreateFullCampaigns } = useLayerCreateFullCampaigns()
  const [dateRange, setDateRange] = useState<[Date, Date]>([yesterday, yesterday])
  const { data: fetchedRpCampaignSummaries, isFetching } = useSearchRpCampaignSummaries({
    startDate: dayjs(dateRange[0]).format(DATE_FORMAT.API),
    endDate: dayjs(dateRange[1]).format(DATE_FORMAT.API),
    accountId: activeAccount?.id ?? '',
  })
  const [searchKeyword, setSearchKeyword] = useDebouncedState('', 500)
  const [matchedRpCampaignSummaries, setMatchedRpCampaignSummaries] = useState<
    IRpCampaignSummary[]
  >([])
  const [sortConfig, setSortConfig] = useState<ISortConfig | null>(null)

  const calcProfit = (
    rpCampaignSummaries: IRpCampaignSummary[] = [],
    ignoreProfitFlag: boolean,
  ) => {
    let totalProfit = 0
    rpCampaignSummaries.forEach((rpCampaignSummary) => {
      if (ignoreProfitFlag || rpCampaignSummary.profitFlag) {
        totalProfit += rpCampaignSummary.profit
      }
    })
    return _.round(totalProfit, 2)
  }

  useEffect(() => {
    if (fetchedRpCampaignSummaries) {
      setRpCampaignSummaries(fetchedRpCampaignSummaries)
    }
  }, [fetchedRpCampaignSummaries])

  useEffect(() => {
    let newRpCampaignSummaries
    if (!searchKeyword) {
      newRpCampaignSummaries = rpCampaignSummaries ?? []
    } else {
      const newMatchedRpCampaignSummaries = (rpCampaignSummaries ?? []).filter(
        (rpCampaignSummary) => {
          const { name, ssAsinCode, campaignId } = rpCampaignSummary
          const isMatchedName = name.toLowerCase().includes(searchKeyword.toLowerCase())
          const isMatchedSsAsinCode = (ssAsinCode ?? '')
            .toLowerCase()
            .includes(searchKeyword.toLowerCase())
          const isMatchedCampaignId = `${campaignId ?? ''}`.includes(searchKeyword)
          return isMatchedName || isMatchedSsAsinCode || isMatchedCampaignId
        },
      )
      newRpCampaignSummaries = newMatchedRpCampaignSummaries
    }
    sort(newRpCampaignSummaries, sortConfig)
    setMatchedRpCampaignSummaries([...newRpCampaignSummaries])
  }, [searchKeyword, rpCampaignSummaries, sortConfig])

  const sort = (rpSummaries: IRpCampaignSummary[], sortConfig: ISortConfig | null) => {
    if (!sortConfig) return
    const { field, direction } = sortConfig
    const sortType = SORT_TYPE_MAP[field]
    const directionNum = direction === SortDirection.ASC ? 1 : -1
    rpSummaries.sort((a, b) => {
      switch (sortType) {
        case 'string': {
          const aValue = (a[field] as string) ?? ''
          const bValue = (b[field] as string) ?? ''
          return aValue.localeCompare(bValue) * directionNum
        }
        case 'number': {
          const aValue = (a[field] as number) ?? 0
          const bValue = (b[field] as number) ?? 0
          return (Number(aValue) - Number(bValue)) * directionNum
        }
        default:
          return 0
      }
    })
  }

  const onSort = (field: SortField) => {
    setSortConfig((prev) => {
      const newSortConfig =
        !prev?.field || prev?.field !== field
          ? {
              field,
              direction: SortDirection.DESC,
            }
          : {
              field,
              direction:
                prev?.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
            }
      return newSortConfig
    })
  }

  return (
    <>
      <Flex gap={8}>
        <TextInput
          defaultValue={searchKeyword}
          onChange={(event) => {
            setSearchKeyword(event.currentTarget.value)
          }}
          rightSection={<FaSearch />}
          placeholder="Search..."
        />
        <DatePickerInput
          placeholder="Select date range..."
          miw={200}
          defaultValue={dateRange}
          type="range"
          allowSingleDateInRange
          onChange={(value) => {
            const [from, to] = value
            if (from && to) {
              setDateRange([from, to])
            }
          }}
        />
        <Button variant="default" onClick={openModalCreateFullCampaigns}>
          Create campaigns
        </Button>
        <Button variant="default" component={Link} to="/script-execution-logs">
          Auto Logs
        </Button>
        <Text
          styles={{
            root: {
              marginLeft: 'auto',
            },
          }}
        >
          Total profits: {calcProfit(matchedRpCampaignSummaries, false)} /{' '}
          {calcProfit(matchedRpCampaignSummaries, true)}
        </Text>
      </Flex>
      <ScrollArea
        style={{
          border: 1,
          borderStyle: 'solid',
          borderColor: theme.colors.neutral[2],
        }}
        mt={8}
        w="100%"
        h="calc(100vh - 136px)"
      >
        <LoadingOverlay visible={isFetching} loaderProps={{ children: <Loader /> }} />
        <Table
          id="table-ppc"
          withColumnBorders
          striped
          stickyHeader
          styles={{
            table: {
              borderCollapse: 'separate',
              borderSpacing: 0,
            },
            th: {
              textWrap: 'nowrap',
              textAlign: 'center',
            },
            td: {
              textAlign: 'center',
            },
          }}
        >
          <Table.Thead>
            <Table.Tr>
              <Table.Th />
              <Table.Th>Name</Table.Th>
              <Table.Th>Img</Table.Th>
              <SortableTableTh onSort={() => onSort('profit')}>Profit</SortableTableTh>
              <Table.Th>ASIN</Table.Th>
              <Table.Th>Status</Table.Th>
              <SortableTableTh onSort={() => onSort('spend')}>Ad spend</SortableTableTh>
              <Table.Th>Keyword Bid</Table.Th>
              <Table.Th>Bidding Strategy</Table.Th>
              <Table.Th>Budget Amount</Table.Th>
              <Table.Th>Profit per unit no Ads</Table.Th>
              <Table.Th>Top of search IS</Table.Th>
              <SortableTableTh onSort={() => onSort('impressions')}>Impressions</SortableTableTh>
              <SortableTableTh onSort={() => onSort('clicks')}>Clicks</SortableTableTh>
              <Table.Th>Click through rate</Table.Th>
              <Table.Th>Cost per click</Table.Th>
              <SortableTableTh onSort={() => onSort('sales')}>PPC Sales</SortableTableTh>
              <SortableTableTh onSort={() => onSort('purchases')}>Orders</SortableTableTh>
              <SortableTableTh onSort={() => onSort('unitsSoldClicks')}>Units</SortableTableTh>
              <Table.Th>Referral Fee</Table.Th>
              <Table.Th>Fba Fee</Table.Th>
              <SortableTableTh onSort={() => onSort('totalCogs')}>Total Cogs</SortableTableTh>
              <Table.Th>ACOS</Table.Th>
              <Table.Th>Win ACOS</Table.Th>
              <Table.Th>ROAS</Table.Th>
              <Table.Th>Win ROAS</Table.Th>
              <SortableTableTh onSort={() => onSort('scenarioType')}>Automation</SortableTableTh>
              {automationV2 && <Table.Th>Automation 2</Table.Th>}
              <Table.Th>Profit Flag</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {matchedRpCampaignSummaries
              ? matchedRpCampaignSummaries.map((item: IRpCampaignSummary) => (
                  <RowRpCampaign key={item.campaignId} rpCampaign={item} dateRange={dateRange} />
                ))
              : null}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </>
  )
}

export default TablePPC
