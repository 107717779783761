import { Button, Loader, Switch, Table, useMantineTheme } from '@mantine/core'
import { CiSquarePlus, CiSquareMinus } from 'react-icons/ci'
import { useDisclosure } from '@mantine/hooks'
import { IRpCampaignSummary } from '../../shared/interfaces-v2'
import _ from 'lodash'
import { useAccountStore, usePPCStore, useSetProfitFlag } from '../../hooks'
import RpCampaignDetail from './RpCampaignDetail'
import { useLayerCampaign } from './Layers/LayerCampaign'
import { memo } from 'react'
import { ModalSetupScriptAutomation } from './Modals/ModalSetupScriptAutomation'

interface RowRpCampaignProps {
  rpCampaign: IRpCampaignSummary
  dateRange: [Date, Date]
}

const RowRpCampaign = ({ rpCampaign, dateRange }: RowRpCampaignProps) => {
  const automationV2 = localStorage.getItem('automationV2')

  const theme = useMantineTheme()
  const activeAccount = useAccountStore((state) => state.activeAccount)
  const [opened, { toggle }] = useDisclosure(false)
  const replaceRpCampaignSummary = usePPCStore((state) => state.replaceRpCampaignSummary)
  const { mutate: setProfitFlag, isPending: isSettingProfitFlag } = useSetProfitFlag()
  const { openModalSetupAutocampaign } = useLayerCampaign()

  const [
    openedModalSetupScriptAutomation,
    { open: openModalSetupScriptAutomation, close: closeModalSetupScriptAutomation },
  ] = useDisclosure(false)

  const onToggleProfitFlag = async (checked: boolean) => {
    if (!activeAccount) return
    setProfitFlag(
      {
        campaignId: rpCampaign.campaignId,
        accountId: activeAccount.id,
        profitFlag: checked,
      },
      {
        onSuccess: () => {
          replaceRpCampaignSummary({
            ...rpCampaign,
            profitFlag: checked,
          })
        },
      },
    )
  }

  return (
    <>
      <Table.Tr>
        <Table.Td onClick={toggle}>{opened ? <CiSquareMinus /> : <CiSquarePlus />}</Table.Td>
        <Table.Td
          style={{ textAlign: 'left' }}
        >{`${rpCampaign.name} (${rpCampaign.campaignId})`}</Table.Td>
        <Table.Td>
          <img src={rpCampaign.asinImageUrl} width={25} />
        </Table.Td>
        <Table.Td>{rpCampaign.profit}</Table.Td>
        <Table.Td
          title="multiple product ads"
          style={{ color: rpCampaign.isMultipleProdAd ? theme.colors.warning[6] : undefined }}
        >
          {rpCampaign.ssAsinCode}
        </Table.Td>
        <Table.Td>{rpCampaign.status}</Table.Td>
        <Table.Td>{rpCampaign.spend}</Table.Td>
        <Table.Td />
        <Table.Td>{rpCampaign.campaignBiddingStrategy}</Table.Td>
        <Table.Td>{rpCampaign.campaignBudgetAmount}</Table.Td>
        <Table.Td>{rpCampaign.unitProfitNoAds}</Table.Td>
        <Table.Td>{rpCampaign.topOfSearchIS}</Table.Td>
        <Table.Td>{rpCampaign.impressions}</Table.Td>
        <Table.Td>{rpCampaign.clicks}</Table.Td>
        <Table.Td>
          {rpCampaign.impressions
            ? _.round((rpCampaign.clicks / rpCampaign.impressions) * 100, 2)
            : '-'}
        </Table.Td>
        <Table.Td>
          {rpCampaign.clicks ? _.round(rpCampaign.cost / rpCampaign.clicks, 2) : '-'}
        </Table.Td>
        <Table.Td>{rpCampaign.sales}</Table.Td>
        <Table.Td>{rpCampaign.purchases}</Table.Td>
        <Table.Td>{rpCampaign.unitsSoldClicks}</Table.Td>
        <Table.Td>{rpCampaign.totalReferralFee}</Table.Td>
        <Table.Td>{rpCampaign.totalFbaFee}</Table.Td>
        <Table.Td>{rpCampaign.totalCogs}</Table.Td>
        <Table.Td>
          {rpCampaign.sales ? _.round(rpCampaign.spend / rpCampaign.sales, 2) : '-'}
        </Table.Td>
        <Table.Td>{rpCampaign.winAcos}</Table.Td>
        <Table.Td>
          {rpCampaign.spend ? _.round(rpCampaign.sales / rpCampaign.spend, 2) : '-'}
        </Table.Td>
        <Table.Td>{rpCampaign.winRoas}</Table.Td>
        <Table.Td>
          <Button
            size="xs"
            onClick={() => {
              openModalSetupAutocampaign(rpCampaign.campaignId)
            }}
          >
            {rpCampaign.scenarioType ?? '+'}
          </Button>
        </Table.Td>
        {automationV2 && (
          <Table.Td>
            <Button size="xs" onClick={openModalSetupScriptAutomation}>
              {rpCampaign.autoScriptId ?? '+'}
            </Button>
          </Table.Td>
        )}
        <Table.Td>
          {isSettingProfitFlag ? (
            <Loader size={16} />
          ) : (
            <Switch
              styles={{
                root: {
                  display: 'inline-block',
                },
                track: {
                  cursor: 'pointer',
                },
              }}
              onLabel="ON"
              offLabel="OFF"
              checked={rpCampaign.profitFlag}
              onChange={async (event) => {
                await onToggleProfitFlag(event.currentTarget.checked)
              }}
            />
          )}
        </Table.Td>
      </Table.Tr>
      {opened && <RpCampaignDetail campaignId={rpCampaign.campaignId} dateRange={dateRange} />}
      {openedModalSetupScriptAutomation && (
        <ModalSetupScriptAutomation
          campaignId={rpCampaign.campaignId}
          scriptAutomationId={rpCampaign.scriptAutomationId}
          scriptId={rpCampaign.autoScriptId}
          onClose={closeModalSetupScriptAutomation}
        />
      )}
    </>
  )
}

export default memo(RowRpCampaign)
