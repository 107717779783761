import { useQuery } from '@tanstack/react-query'
import { AuthRequest } from './request'
import { QUERY_KEY } from '../../constants'
import { IDetailRpCampaignSummary, IRpCampaignSummary } from '../../shared/interfaces-v2'

interface SearchSummariesPayload {
  startDate?: string
  endDate?: string
  accountId: string
}

interface SearchDetailSummariesPayload {
  startDate: string
  endDate: string
  accountId: string
  campaignId: number
}

export const useSearchRpCampaignSummaries = (payload: SearchSummariesPayload) => {
  const { startDate, endDate, accountId } = payload
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_RP_CAMPAIGN_SUMMARIES, startDate, endDate, accountId],
    queryFn: async (): Promise<IRpCampaignSummary[]> => {
      const { data } = await AuthRequest.post(`rp-campaigns/search-summaries`, payload)
      return data
    },
  })
}

export const useSearchRpCampaignDetailSummaries = (payload: SearchDetailSummariesPayload) => {
  const { startDate, endDate, accountId, campaignId } = payload
  return useQuery({
    queryKey: [
      QUERY_KEY.SEARCH_RP_CAMPAIGN_DETAIL_SUMMARIES,
      startDate,
      endDate,
      accountId,
      campaignId,
    ],
    queryFn: async (): Promise<IDetailRpCampaignSummary[]> => {
      const { data } = await AuthRequest.post(`rp-campaigns/search-detail-summaries`, payload)
      return data
    },
  })
}
