import { Tooltip } from '@mui/material'
import { CrawlingStatus } from '../../shared/enums'
import FiberNewIcon from '@mui/icons-material/FiberNew'
import GppBadIcon from '@mui/icons-material/GppBad'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart'
import RunCircleIcon from '@mui/icons-material/RunCircle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

interface CrawlingStatusIconProps {
  crawlingStatus: CrawlingStatus
}

export default function CrawlingStatusIcon({ crawlingStatus }: CrawlingStatusIconProps) {
  const crawlingStatusMapping = {
    [CrawlingStatus.NEW]: {
      icon: FiberNewIcon,
      title: 'New',
      color: 'primary.main',
    },
    [CrawlingStatus.IN_PROCESS]: {
      icon: RunCircleIcon,
      title: 'In process',
      color: 'primary.main',
    },
    [CrawlingStatus.SUCCESS]: {
      icon: CheckCircleIcon,
      title: 'Success',
      color: 'success.main',
    },
    [CrawlingStatus.FAILED]: {
      icon: ReportProblemIcon,
      title: 'Failed',
      color: 'warning.main',
    },
    [CrawlingStatus.PAGE_NOT_FOUND]: {
      icon: GppBadIcon,
      title: 'Page not found',
      color: 'error.main',
    },
    [CrawlingStatus.STOCK_UNAVAILABLE]: {
      icon: RemoveShoppingCartIcon,
      title: 'Stock unavailable',
      color: 'error.main',
    },
  }
  const IconObj = crawlingStatusMapping[crawlingStatus]
  return IconObj ? (
    <Tooltip title={IconObj.title}>
      <IconObj.icon sx={{ color: IconObj.color }} />
    </Tooltip>
  ) : null
}
