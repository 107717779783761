import { create } from 'zustand'
import { ICompetition, ICompetitor } from '../../shared/interfaces'

interface ICompetitionStore {
  competitions: ICompetition[]
  setCompetitions: (competitions: ICompetition[]) => void
  replaceCompetition: (competition: ICompetition) => void
  replaceCompetitor: (competitor: ICompetitor) => void
  removeCompetitor: (id: string) => void
}

export const useCompetitionStore = create<ICompetitionStore>((set) => ({
  competitions: [],
  setCompetitions: (newCompetitions: ICompetition[]) => {
    set({ competitions: newCompetitions })
  },
  replaceCompetition: (competition: ICompetition) => {
    set(({ competitions }) => {
      const updatedCompetitions = competitions.map((prevCompetition) => {
        if (competition.asinCode === prevCompetition.asinCode) {
          return competition
        }
        return prevCompetition
      })
      return { competitions: updatedCompetitions }
    })
  },
  replaceCompetitor: (competitor: ICompetitor) => {
    set(({ competitions }) => {
      const updatedCompetitions = competitions.map((prevCompetition) => {
        const outdatedCompetitor = prevCompetition.competitors.find((prevCompetitor) => {
          return competitor.id === prevCompetitor.id
        })
        if (outdatedCompetitor) {
          const updatedCompetitors = prevCompetition.competitors.map((prevCompetitor) => {
            if (competitor.id === prevCompetitor.id) {
              return competitor
            }
            return prevCompetitor
          })
          return {
            ...prevCompetition,
            competitors: updatedCompetitors,
          }
        }
        return prevCompetition
      })
      return { competitions: updatedCompetitions }
    })
  },
  removeCompetitor: (id: string) => {
    set(({ competitions }) => {
      const updatedCompetitions = competitions.map((prevCompetition) => {
        const removedCompetitor = prevCompetition.competitors.find((prevCompetitor) => {
          return id === prevCompetitor.id
        })
        if (removedCompetitor) {
          return {
            ...prevCompetition,
            competitors: prevCompetition.competitors.filter((prevCompetitor) => {
              return id !== prevCompetitor.id
            }),
          }
        }
        return prevCompetition
      })
      return { competitions: updatedCompetitions }
    })
  },
}))
