import { useState } from 'react'
import './App.css'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import TopBar from './components/topbar'
import { Box } from '@mui/material'
import LoginPage from './pages/PageLogin'
import AuthRoute from './components/auth-route'
import SideBar from './components/sidebar'
import { ROUTES } from './shared/constants'
import DashboardPage from './pages/PageDashboard'
import ProductsPage from './pages/PageProduct'
import CompetitionPage from './pages/PageCompetition'
import AutopriceLogPage from './pages/PageAutopriceLog'
import TaskPage from './pages/PageTask'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useAuthStore } from './hooks'
import PagePPC from './pages/PagePPC'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import './index.css' // todo: remove
import PageScriptExecutionLog from './pages/PageScriptExecutionLog'
import PageScript from './pages/PageScript'
import PageCampaignAlert from './pages/PageCampaignAlert'

export const DRAWER_WIDTH = 240
export const MINI_DRAWER_WIDTH = 57

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

function App() {
  const { isLoggedIn } = useAuthStore()
  const [isSidbarOpen, setIsSidebarOpen] = useState(false)

  return (
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        {isLoggedIn && (
          <>
            <TopBar isSidbarOpen={isSidbarOpen} setIsSidebarOpen={setIsSidebarOpen} />
            <SideBar isSidbarOpen={isSidbarOpen} setIsSidbarOpen={setIsSidebarOpen} />
          </>
        )}
        <Box
          id="main"
          mt={8}
          p={2}
          sx={{
            marginLeft: isSidbarOpen ? `${DRAWER_WIDTH}px` : `${MINI_DRAWER_WIDTH}px`,
          }}
        >
          <Routes>
            <Route path={ROUTES.LOGIN.PATH} element={<LoginPage />} />
            <Route path={ROUTES.HOME.PATH} element={<Navigate to={ROUTES.PRODUCTS.PATH} />} />
            <Route
              path={ROUTES.DASHBOARD.PATH}
              element={
                <AuthRoute>
                  <DashboardPage />
                </AuthRoute>
              }
            />
            <Route
              path={ROUTES.PRODUCTS.PATH}
              element={
                <AuthRoute>
                  <ProductsPage />
                </AuthRoute>
              }
            />
            <Route
              path={ROUTES.COMPETITION.PATH}
              element={
                <AuthRoute>
                  <CompetitionPage />
                </AuthRoute>
              }
            />
            <Route
              path={ROUTES.AUTOPTICE_LOG.PATH}
              element={
                <AuthRoute>
                  <AutopriceLogPage />
                </AuthRoute>
              }
            />
            <Route
              path={ROUTES.TASK.PATH}
              element={
                <AuthRoute>
                  <TaskPage />
                </AuthRoute>
              }
            />
            <Route
              path={ROUTES.PPC.PATH}
              element={
                <AuthRoute>
                  <PagePPC />
                </AuthRoute>
              }
            />
            <Route
              path={ROUTES.AUTOCAMPAIGN_LOG.PATH}
              element={
                <AuthRoute>
                  <PageScriptExecutionLog />
                </AuthRoute>
              }
            />
            <Route
              path={ROUTES.SCRIPT.PATH}
              element={
                <AuthRoute>
                  <PageScript />
                </AuthRoute>
              }
            />
            <Route
              path={ROUTES.CAMPAIGN_ALERT.PATH}
              element={
                <AuthRoute>
                  <PageCampaignAlert />
                </AuthRoute>
              }
            />
          </Routes>
        </Box>
      </HashRouter>
    </QueryClientProvider>
  )
}

export default App
