import _ from 'lodash'
import { CellProps } from 'react-table'
import { ProductUtils } from '../../../utils'

export function toLineUrlFromShipmentIdArr(values: string[]) {
  return values.map((shipmentId: string, idx: number) => {
    const url = ProductUtils.generateShipmentUrl(shipmentId)
    return (
      <div key={idx}>
        {!_.isNil(shipmentId) ? (
          <a href={url} target="_blank" title={url} rel="noreferrer">
            {shipmentId}
          </a>
        ) : (
          <span>-</span>
        )}
      </div>
    )
  })
}

export function toLineTextFromArr(values: Array<string | undefined>) {
  return (
    <div style={{ whiteSpace: 'pre' }}>
      {values.map((line) => (_.isNil(line) ? '-' : line)).join('\n')}
    </div>
  )
}

export function PreWrapTextCell(cell: CellProps<any>) {
  return <div style={{ whiteSpace: 'pre-wrap' }}>{cell.value}</div>
}

export function toImage({ cell }: CellProps<any>) {
  return <img src={cell.value} alt="" width={40} height={40} />
}

export function toNumber(decimal: number = 2) {
  return function (cell: CellProps<any>) {
    return _.isNil(cell.value) ? '-' : Math.round(cell.value * 10 ** decimal) / 10 ** decimal
  }
}

export function toNumberFromArrSum(values: Array<number | undefined>, decimal: number = 2) {
  const sum = values.reduce((partialSum: number, num: number | undefined) => {
    return _.isNil(num) ? partialSum : partialSum + num
  }, 0)
  return Math.round(sum * 10 ** decimal) / 10 ** decimal
}

// export function toNumberFromArrSumWithDetails(values: Array<number | undefined>, decimal: number = 2) {
//   const sum = values.reduce((partialSum: number, num: number | undefined) => {
//     return _.isNil(num) ? partialSum : partialSum + num
//   }, 0)
//   const title = values.map(num => _.isNil(num) ? '[-]' : num).join('\n')
//   return (
//     <div title={title}>
//       {Math.round(sum * 10 ** decimal) / 10 ** decimal}
//     </div>
//   )
// }

export function sumWithDetails(
  values: Array<number | undefined>,
  sum: number,
  decimal: number = 2,
) {
  const title = values.map((num) => (_.isNil(num) ? '[-]' : num)).join('\n')
  return <div title={title}>{Math.round(sum * 10 ** decimal) / 10 ** decimal}</div>
}

export function showQuantity(quantityShippeds: number[], quantityReceiveds: number[]) {
  const sumQuantityShipped = ProductUtils.sum(quantityShippeds)
  const sumQuantityReceived = ProductUtils.sum(quantityReceiveds)
  const title = _.map(quantityShippeds, (item, index) => {
    const quantityShipped = _.get(quantityShippeds, index)
    const quantityReceived = _.get(quantityReceiveds, index)
    return _.isNil(item) ? '[-]' : `${quantityReceived}/${quantityShipped}`
  }).join('\n')
  return <div title={title}>{`${sumQuantityReceived}/${sumQuantityShipped}`}</div>
}
