import { ThemeProvider } from '@mui/material'
import AioProductsContextProvider from '../../contexts/aio-products/aio-products.provider'
import ProductDashboard from './TableProducts'
import { muiTheme } from '../../shared/themes'

export default function DashboardPage() {
  return (
    <ThemeProvider theme={muiTheme}>
      <AioProductsContextProvider>
        <ProductDashboard />
      </AioProductsContextProvider>
    </ThemeProvider>
  )
}
