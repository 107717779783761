import { Controller, UseFormReturn } from 'react-hook-form'
import { ActionIcon, Flex, NumberInput, Select } from '@mantine/core'
import { IFormScript } from './type'
import {
  KeywordAttribute,
  Operator,
  ScriptType,
  SearchTermAttribute,
  SearchTermMatchType,
} from '../../../shared/enums'
import { FiMinus } from 'react-icons/fi'
import { useMemo } from 'react'
import { OPERATOR_LABELS, XDAYS_SUPPORTED_ATTRIBUTES } from '../../../shared/constants'

interface Props {
  caseIdx: number
  conditionIdx: number
  formController: UseFormReturn<IFormScript, any>
  allowRemove: boolean
  removeCondition: (index?: number | number[]) => void
}

const SetupCondition = ({
  caseIdx,
  conditionIdx,
  formController,
  allowRemove,
  removeCondition,
}: Props) => {
  const { control, watch } = formController

  const scriptType = watch('scriptType')
  const attributeOptions = useMemo(() => {
    switch (scriptType) {
      case ScriptType.SP_KEYWORD:
        return Object.values(KeywordAttribute)
      case ScriptType.SP_SEARCH_TERM:
        return Object.values(SearchTermAttribute)
      default:
        return []
    }
  }, [scriptType])
  const attribute = watch(`cases.${caseIdx}.conditions.${conditionIdx}.attribute`)
  const shouldShowXDays = XDAYS_SUPPORTED_ATTRIBUTES.includes(attribute)

  const valueOptions = useMemo(() => {
    switch (attribute) {
      case SearchTermAttribute.isTarget:
      case KeywordAttribute.isTarget:
        return ['true', 'false']
      case SearchTermAttribute.matchType:
        return Object.values(SearchTermMatchType)
      default:
        return undefined
    }
  }, [attribute])

  const operatorOptions = useMemo(() => {
    switch (attribute) {
      case SearchTermAttribute.isTarget:
      case KeywordAttribute.isTarget:
      case SearchTermAttribute.matchType:
        return [{ label: OPERATOR_LABELS[Operator.EQ], value: Operator.EQ }]
      default:
        return Object.values(Operator).map((op) => ({ label: OPERATOR_LABELS[op], value: op }))
    }
  }, [attribute])

  return (
    <Flex gap={8}>
      <Controller
        control={control}
        name={`cases.${caseIdx}.conditions.${conditionIdx}.attribute`}
        render={({
          field: { onChange: onChangeAttribute, value: attributeValue },
          fieldState: { error: attributeError },
        }) => (
          <>
            <Select
              data={attributeOptions}
              value={attributeValue}
              onChange={onChangeAttribute}
              label="Attribute"
              disabled={!!attributeValue}
              required
              flex={1}
              error={attributeError?.message}
            />
          </>
        )}
      />
      {shouldShowXDays && (
        <Controller
          control={control}
          name={`cases.${caseIdx}.conditions.${conditionIdx}.xDays`}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              data={Array.from({ length: 30 }, (_, i) => (i + 1).toString())}
              value={value?.toString()}
              onChange={onChange}
              allowDeselect={false}
              label="X Days"
              required
              flex={1}
              error={error?.message}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name={`cases.${caseIdx}.conditions.${conditionIdx}.operator`}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            data={operatorOptions}
            value={value}
            onChange={onChange}
            allowDeselect={false}
            label="Operator"
            flex={1}
            required
            error={error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={`cases.${caseIdx}.conditions.${conditionIdx}.value`}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return valueOptions ? (
            <Select
              data={valueOptions}
              value={value}
              onChange={onChange}
              allowDeselect={false}
              label="Value"
              flex={1}
              required
              error={error?.message}
            />
          ) : (
            <NumberInput
              value={value}
              onChange={onChange}
              label="Value"
              flex={1}
              required
              error={error?.message}
              hideControls
            />
          )
        }}
      />

      {allowRemove && (
        <ActionIcon
          variant="default"
          mt="auto"
          ml="auto"
          onClick={() => removeCondition(conditionIdx)}
          size={36}
        >
          <FiMinus />
        </ActionIcon>
      )}
    </Flex>
  )
}
export default SetupCondition
