import { ThemeProvider } from '@emotion/react'
import { LayerCompetitionModal } from './Layers/LayerCompetitionModal'
import { LayerCompetitorModal } from './Layers/LayerCompetitorModal'
import Competition from './TableCompetitions'
import { muiTheme } from '../../shared/themes'

export default function CompetitionPage() {
  return (
    <ThemeProvider theme={muiTheme}>
      <Competition />
      <LayerCompetitionModal />
      <LayerCompetitorModal />
    </ThemeProvider>
  )
}
