import { Control, Controller } from 'react-hook-form'
import { Select } from '@mantine/core'
import { IFormScript } from '../type'
import { useSearchScripts } from '../../../../hooks/api/useScript'

interface Props {
  index: number
  control: Control<IFormScript, any>
}

const ActionConfigScriptId = ({ index, control }: Props) => {
  const { data: script } = useSearchScripts()
  return (
    <Controller
      control={control}
      name={`cases.${index}.actionConfig.scriptId`}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Select
          data={script?.map((s) => s.id) ?? []}
          value={value}
          onChange={onChange}
          allowDeselect={false}
          label="Script ID"
          required
          error={error?.message}
        />
      )}
    />
  )
}
export default ActionConfigScriptId
