import { createRef, useCallback, useImperativeHandle, useState } from 'react'
import ModalEditCompetitor from '../Modals/ModalEditCompetitor'

const layerCompetitorModalRef = createRef<any>()

export const useLayerCompetitorModal = () => {
  const openModalEditCompetitor = useCallback((competitorId: string) => {
    layerCompetitorModalRef.current?.openModalEditCompetitor(competitorId)
  }, [])

  return { openModalEditCompetitor }
}

export const LayerCompetitorModal = () => {
  const [competitorId, setCompetitorId] = useState<string>()
  const [openedModalEditCompetitor, setOpenModalEditCompetitor] = useState(false)

  useImperativeHandle(
    layerCompetitorModalRef,
    () => ({
      openModalEditCompetitor: (competitorId: string) => {
        setCompetitorId(competitorId)
        setOpenModalEditCompetitor(true)
      },
    }),
    [],
  )

  return (
    <>
      {competitorId && (
        <ModalEditCompetitor
          competitorId={competitorId}
          openHandler={{ value: openedModalEditCompetitor, set: setOpenModalEditCompetitor }}
        />
      )}
    </>
  )
}
