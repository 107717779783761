import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { IconButton, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useState } from 'react'
import _ from 'lodash'
import { useCreateCompetitor, useCompetitionStore, useGetCompetition } from '../../../hooks'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: '80vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

interface ModalAddCompetitorProps {
  competitionId: string
  openHandler: {
    value: boolean
    set: Function
  }
}

export default function ModalAddCompetitor({
  openHandler,
  competitionId,
}: ModalAddCompetitorProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Record<string, any>>()
  const { mutate: createCompetitor, isPending: isCreating } = useCreateCompetitor()
  const replaceCompetition = useCompetitionStore((state) => state.replaceCompetition)
  const { data: competition, refetch: refetchCompetition } = useGetCompetition(competitionId)

  const [refetchCompetitionInterval, setRefetchCompetitionInterval] = useState<NodeJS.Timer>()

  const autoFetchCompetitions = () => {
    if (!_.isNil(refetchCompetitionInterval)) {
      clearInterval(refetchCompetitionInterval)
    }
    const interval = setInterval(async () => {
      await refetchCompetition().then(({ data: fetchedCompetition }) => {
        if (fetchedCompetition) {
          replaceCompetition(fetchedCompetition)
        }
      })
    }, 5 * 1000)
    setRefetchCompetitionInterval(interval)
    setTimeout(() => {
      clearInterval(interval)
    }, 5 * 60 * 1000)
  }

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    createCompetitor(
      {
        competitionId,
        asinCode: data.asin,
        x: data.x,
      },
      {
        onSuccess: (updatedCompetition) => {
          replaceCompetition(updatedCompetition)
          autoFetchCompetitions()
          reset()
          openHandler.set(false)
        },
      },
    )
  }

  if (!competition) return null
  return (
    <Modal
      id="add-competitor-modal"
      open={openHandler.value}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableRestoreFocus
      data-testid="modal-add-competitor"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={() => openHandler.set(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          data-testid="btn-close-modal"
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" data-testid="title-modal" variant="h6" component="h2">
          Add Competitor for {competition.asinCode}
        </Typography>
        <Box
          id="add-competitor-form"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            {...register('asin', {
              required: true,
              pattern: {
                value: /^[A-Z0-9]{10}$/,
                message: 'Invalid ASIN format. Please enter a 10-character alphanumeric value.',
              },
            })}
            variant="standard"
            margin="normal"
            required
            fullWidth
            label="ASIN"
            autoFocus
            error={Boolean(errors.asin)}
            helperText={errors?.asin?.message as string}
          />
          <TextField
            {...register('x', {
              required: true,
              valueAsNumber: true,
            })}
            type="number"
            variant="standard"
            margin="normal"
            required
            fullWidth
            label="x"
            inputProps={{
              step: 0.1,
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isCreating}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
