import { Controller, UseFormReturn } from 'react-hook-form'
import { Flex, Select } from '@mantine/core'
import { IFormScript } from './type'
import { ActionType } from '../../../shared/enums'
import ActionConfigXDays from './ActionConfigs/ActionConfigXDays'
import ActionConfigMinBid from './ActionConfigs/ActionConfigMinBid'
import ActionConfigMaxBid from './ActionConfigs/ActionConfigMaxBid'

import ActionConfigDelta from './ActionConfigs/ActionConfigDelta'
import ActionConfigMinDelta from './ActionConfigs/ActionConfigMinDelta'
import ActionConfigValue from './ActionConfigs/ActionConfigValue'
import ActionConfigPercentage from './ActionConfigs/ActionConfigPercentage'
import ActionConfigScriptId from './ActionConfigs/ActionConfigScriptId'

interface Props {
  index: number
  formController: UseFormReturn<IFormScript, any>
}

function getDefaultActionConfig(type: string) {
  switch (type) {
    case ActionType.UPDATE_BID_ACOS_XDAYS:
      return {
        xDays: '1',
        minBid: '0',
        maxBid: '999',
      }
    case ActionType.UPDATE_BID_FIXED_DELTA:
      return {
        delta: '',
        minBid: '0',
        maxBid: '999',
        minDelta: '0',
      }
    case ActionType.UPDATE_BID_FIXED_VALUE:
      return {
        value: 0.02,
      }
    case ActionType.UPDATE_BID_PERCENTAGE:
      return {
        percentage: '',
        minBid: '0',
        maxBid: '999',
        minDelta: '0',
      }
    default:
      return {}
  }
}

const SetUpAction = ({ index, formController }: Props) => {
  const { control, setValue, watch } = formController
  const actionType = watch(`cases.${index}.actionType`)
  return (
    <Flex gap={8}>
      <Controller
        control={control}
        name={`cases.${index}.actionType`}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            data={Object.values(ActionType)}
            value={value}
            onChange={(val) => {
              setValue(`cases.${index}.actionConfig`, getDefaultActionConfig(val ?? ''))
              onChange(val)
            }}
            label="Action"
            clearable
            error={error?.message}
            miw={240}
          />
        )}
      />
      {actionType === ActionType.UPDATE_BID_ACOS_XDAYS && (
        <>
          <ActionConfigXDays index={index} control={control} />
          <ActionConfigMinBid index={index} control={control} />
          <ActionConfigMaxBid index={index} control={control} />
        </>
      )}
      {actionType === ActionType.UPDATE_BID_FIXED_DELTA && (
        <>
          <ActionConfigDelta index={index} control={control} />
          <ActionConfigMinDelta index={index} control={control} />
          <ActionConfigMinBid index={index} control={control} />
          <ActionConfigMaxBid index={index} control={control} />
        </>
      )}
      {actionType === ActionType.UPDATE_BID_FIXED_VALUE && (
        <ActionConfigValue index={index} control={control} />
      )}
      {actionType === ActionType.UPDATE_BID_PERCENTAGE && (
        <>
          <ActionConfigPercentage index={index} control={control} />
          <ActionConfigMinBid index={index} control={control} />
          <ActionConfigMaxBid index={index} control={control} />
          <ActionConfigMinDelta index={index} control={control} />
        </>
      )}
      {actionType === ActionType.SWITCH_SCRIPT && (
        <ActionConfigScriptId index={index} control={control} />
      )}
    </Flex>
  )
}
export default SetUpAction
