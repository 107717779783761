import { Button, Menu, MenuItem, Modal } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LinearProgress from '@mui/material/LinearProgress'

import { useState } from 'react'
import { CSVLink } from 'react-csv'
import _ from 'lodash'
import { IAsin, ISku } from '../../shared/interfaces-v2'
import { useAccountStore, useSearchAsins, useSearchSkus } from '../../hooks'

export interface IExportedField {
  key: string
  label: string
}

export default function CsvExport() {
  const { activeAccount } = useAccountStore()
  const [openModal, setOpenModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [exportedData, setExportedData] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { refetch: refetchSearchAsins } = useSearchAsins({
    accountId: activeAccount?.id ?? '',
  })
  const { refetch: refetchSkus } = useSearchSkus(
    { accountId: activeAccount?.id },
    {},
    {
      enabled: false,
    },
  )

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const exportAsins = async () => {
    if (!activeAccount) return
    handleClose()
    setOpenModal(true)
    setIsLoading(true)
    try {
      const { data: fetchedAsins = [] } = await refetchSearchAsins()
      const EXPORTED_FIELDS: IExportedField[] = [
        { key: 'asinCode', label: 'ASIN' },
        { key: 'cogs', label: 'Cogs' },
        { key: 'ship', label: 'Ship' },
        { key: 'notes', label: 'Notes' },
        { key: 'assignee', label: 'Assignee' },
        { key: 'containerSpec.lSet', label: 'L_Set' },
        { key: 'containerSpec.wSet', label: 'W_Set' },
        { key: 'containerSpec.hSet', label: 'H_Set' },
        { key: 'containerSpec.gwSet', label: 'GW_Set' },
        { key: 'containerSpec.lCtn', label: 'L_Ctn' },
        { key: 'containerSpec.wCtn', label: 'W_Ctn' },
        { key: 'containerSpec.hCtn', label: 'H_Ctn' },
        { key: 'containerSpec.qtCtn', label: 'QT_Ctn' },
        { key: 'containerSpec.gwCtn', label: 'GW_Ctn' },
      ]
      const headerKeys = EXPORTED_FIELDS.map((field) => field.key)
      const headerTitles = EXPORTED_FIELDS.map((field) => field.label)
      const exportDataRows = [headerKeys, headerTitles]
      fetchedAsins.forEach((asin: Omit<IAsin, 'skus'>) => {
        const row = EXPORTED_FIELDS.map(({ key }) => _.get(asin, key, ''))
        exportDataRows.push(row)
      })
      setExportedData(exportDataRows)
    } catch (error) {
      alert(error)
    } finally {
      setIsLoading(false)
    }
  }

  const exportSkus = async () => {
    if (!activeAccount) return
    handleClose()
    setOpenModal(true)
    setIsLoading(true)
    try {
      const { data: fetchedSkus = [] } = await refetchSkus()
      const EXPORTED_FIELDS: IExportedField[] = [
        { key: 'fnsku', label: 'SKU' },
        { key: 'waitForBuying', label: 'Wait For Buying' },
        { key: 'goingToFw', label: 'Going To FW' },
        { key: 'estimatedReceivedDate', label: 'Estimated Received Date' },
        { key: 'inChineseStock', label: 'In Chinese Stock' },
      ]
      const headerKeys = EXPORTED_FIELDS.map((field) => field.key)
      const headerTitles = EXPORTED_FIELDS.map((field) => field.label)
      const exportedDataRows = [headerKeys, headerTitles]
      fetchedSkus.forEach((sku: Omit<ISku, 'asin' | 'inboundItems'>) => {
        const row = EXPORTED_FIELDS.map(({ key }) => _.get(sku, key, ''))
        exportedDataRows.push(row)
      })
      setExportedData(exportedDataRows)
    } catch (error) {
      alert(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Button
        data-testid="export-csv-dropdown-button"
        aria-controls={open ? 'csv-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Export CSV
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="csv-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'csv-button',
        }}
      >
        <MenuItem data-testid="export-asin-csv-button" onClick={exportAsins}>
          Export ASIN
        </MenuItem>
        <MenuItem data-testid="export-sku-csv-button" onClick={exportSkus}>
          Export SKU
        </MenuItem>
      </Menu>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: 400,
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fff',
            padding: '2rem',
            textAlign: 'center',
          }}
        >
          {isLoading ? (
            <LinearProgress />
          ) : (
            <CSVLink
              data={exportedData}
              filename="exported-product.csv"
              className="hidden"
              target="_blank"
            >
              <Button
                data-testid="download-csv-button"
                variant="contained"
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                Download
              </Button>
            </CSVLink>
          )}
        </div>
      </Modal>
    </>
  )
}
