import { Controller, useForm } from 'react-hook-form'
import { REGEX, SCENARIO_2_STOPLOSS_DEFAULT } from '../../../shared/constants'
import { Box, Button, Group, Loader, LoadingOverlay, Modal, Select, TextInput } from '@mantine/core'
import {
  useAccountStore,
  usePPCStore,
  useRemoveAutocampaign,
  useSearchAcCampaigns,
  useSetAutocampaign,
} from '../../../hooks'
import { ScenarioType } from '../../../shared/enums'
import { useEffect } from 'react'
import _ from 'lodash'

interface ISetupAutocampaignForm {
  scenarioType: ScenarioType
  stoploss: string
}

interface ModalSetupCampaignProps {
  campaignId: number
  opened: boolean
  onClose: () => void
}

const ModalSetupAutocampaign = ({ campaignId, opened, onClose }: ModalSetupCampaignProps) => {
  const { activeAccount } = useAccountStore()
  const { handleSubmit, control, setValue, setError } = useForm<ISetupAutocampaignForm>({
    defaultValues: {
      scenarioType: '' as ScenarioType,
      stoploss: SCENARIO_2_STOPLOSS_DEFAULT.toString(),
    },
  })
  const updateRpCampaignSummary = usePPCStore((state) => state.updateRpCampaignSummary)
  const { data: acCampaigns, isFetching } = useSearchAcCampaigns({
    accountId: activeAccount?.id ?? '',
    campaignId,
  })
  const [campaign] = acCampaigns ?? []
  const { mutate: setAutocampaign, isPending: isSettingAutocampaign } = useSetAutocampaign()
  const { mutate: removeAutocampaign, isPending: isRemovingAutocampaign } = useRemoveAutocampaign()

  useEffect(() => {
    if (campaign) {
      setValue('scenarioType', campaign.scenarioType)
      if (campaign.scenarioConfig?.stoploss) {
        setValue('stoploss', campaign.scenarioConfig.stoploss)
      }
    }
  }, [campaign])

  const onSubmit = async (data: ISetupAutocampaignForm) => {
    if (!activeAccount) return
    const { scenarioType, stoploss } = data
    if (!scenarioType) {
      setError('scenarioType', { message: 'Please select scenario type' })
      return
    }
    let scenarioConfig
    switch (scenarioType) {
      case ScenarioType.SCENARIO_1:
        break
      case ScenarioType.SCENARIO_2:
        scenarioConfig = {
          stoploss: Number(stoploss),
        }
        break
    }
    setAutocampaign(
      {
        campaignId,
        scenarioType: data.scenarioType,
        scenarioConfig,
        accountId: activeAccount.id,
      },
      {
        onSuccess: ({ scenarioType }) => {
          updateRpCampaignSummary(campaignId, { scenarioType })
          onClose()
        },
      },
    )
  }

  const handleRemoveAutocampaign = () => {
    if (!activeAccount) return
    removeAutocampaign(
      { campaignId, accountId: activeAccount.id },
      {
        onSuccess: ({ scenarioType }) => {
          updateRpCampaignSummary(campaignId, { scenarioType })
          onClose()
        },
      },
    )
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnClickOutside={false}
      title="Setup auto campaign"
      centered
    >
      <LoadingOverlay
        visible={isFetching || isSettingAutocampaign || isRemovingAutocampaign}
        loaderProps={{ children: <Loader size="xs" /> }}
      />
      <Box id="form-setup-autocampaign" component="form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="scenarioType"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <Select
                  label="Scenario type"
                  placeholder="Pick value"
                  value={value}
                  onChange={onChange}
                  data={[...Object.values(ScenarioType)]}
                  data-testid="select-scenario-type"
                  required
                  error={error?.message}
                />
                {value === ScenarioType.SCENARIO_2 && (
                  <Controller
                    name="stoploss"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        name="stoploss"
                        label="Stop loss"
                        mt={8}
                        onChange={({ target: { value: inputValue } }) => {
                          if (REGEX.NUMBER.test(inputValue)) {
                            onChange(inputValue)
                          }
                        }}
                        onBlur={() => {
                          if (value) {
                            const stoploss = _.round(Number(value), 2)
                            setValue('stoploss', isNaN(stoploss) ? '' : stoploss.toString())
                          }
                        }}
                        value={value}
                        data-testid="input-stoploss"
                        required
                      />
                    )}
                  />
                )}
              </>
            )
          }}
        />
        <Group mt={24} justify="flex-end">
          {campaign?.scenarioType && (
            <Button variant="default" data-testid="btn-remove" onClick={handleRemoveAutocampaign}>
              Remove
            </Button>
          )}
          <Button type="submit" data-testid="btn-submit">
            Save
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}

export default ModalSetupAutocampaign
