import { createContext } from 'react'
import { IAioSku } from '../../shared/interfaces-v2'

interface AioProductsContextProps {
  aioProducts: IAioSku[]
  setAioProducts: (aioSkus: IAioSku[]) => void
  hiddenColumnIds: string[]
  saveHiddenColumnIds: (columnIds: string[]) => void
}

export const AioProductsContext = createContext<AioProductsContextProps>({
  aioProducts: [],
  setAioProducts: () => {},
  hiddenColumnIds: [],
  saveHiddenColumnIds: () => {},
})
