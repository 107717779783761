import {
  Modal,
  Box,
  Select,
  Button,
  Group,
  LoadingOverlay,
  Loader,
  Text,
  ScrollArea,
} from '@mantine/core'
import { Controller, useForm } from 'react-hook-form'
import { useSearchScripts } from '../../../hooks/api/useScript'
import * as yup from 'yup'
import dayjs from '../../../utils/dayjs.utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAccountStore, usePPCStore } from '../../../hooks'
import {
  useCreateScriptAutomation,
  useDeleteScriptAutomation,
  useUpdateScriptAutomation,
} from '../../../hooks/api/useScriptAutomation'
import { IScriptAutomation } from '../../../shared/interfaces-v2'
import { useSearchScriptAutomationActivities } from '../../../hooks/api/useScriptAutomationActivity'

const IFormSchema = yup.object().shape({
  scriptId: yup.string().required('This field is required'),
})

interface IFormSetupScriptAutomation {
  scriptId: string
}

interface Props {
  campaignId: number
  scriptAutomationId?: string
  scriptId?: string
  onClose: () => void
}

export const ModalSetupScriptAutomation = ({
  campaignId,
  scriptAutomationId,
  scriptId,
  onClose,
}: Props) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<IFormSetupScriptAutomation>({
    defaultValues: {
      scriptId: scriptId ?? '',
    },
    resolver: yupResolver(IFormSchema),
  })
  const { activeAccount } = useAccountStore()
  const { updateRpCampaignSummary } = usePPCStore()
  const { data: scripts, isLoading: isLoadingScripts } = useSearchScripts()
  const { data: scriptAutomationActivities } = useSearchScriptAutomationActivities(
    {
      accountId: activeAccount?.id,
      campaignId,
    },
    {
      enabled: !!activeAccount?.id,
    },
  )
  const { mutateAsync: createScriptAutomation, isPending: isCreating } = useCreateScriptAutomation()
  const { mutateAsync: updateScriptAutomation, isPending: isUpdating } = useUpdateScriptAutomation(
    scriptAutomationId ?? '',
  )
  const { mutate: removeAutocampaign, isPending: isRemoving } = useDeleteScriptAutomation(
    scriptAutomationId ?? '',
  )

  const historyActivities = scriptAutomationActivities
    ?.map((c) => `- ${dayjs(c.createdAt).format('DD-MM-YYYY HH:mm')}: ${c.message}`)
    .join('<br>')
  const scriptsOptions = scripts?.map((s) => s.id)

  async function onSubmit(data: IFormSetupScriptAutomation) {
    if (!campaignId || !activeAccount) return

    try {
      let savedScriptAutomation: IScriptAutomation
      if (scriptAutomationId) {
        savedScriptAutomation = await updateScriptAutomation({
          scriptId: data.scriptId,
        })
      } else {
        savedScriptAutomation = await createScriptAutomation({
          accountId: activeAccount.id,
          campaignId,
          scriptId: data.scriptId,
        })
      }
      updateRpCampaignSummary(campaignId, {
        autoScriptId: data.scriptId,
        scriptAutomationId: savedScriptAutomation.id,
      })
      onClose()
    } catch (error) {}
  }

  function onRemove() {
    if (!scriptAutomationId || !activeAccount) return
    removeAutocampaign(undefined, {
      onSuccess: () => {
        updateRpCampaignSummary(campaignId, {
          autoScriptId: undefined,
          scriptAutomationId: undefined,
        })
        onClose()
      },
    })
  }

  return (
    <Modal
      opened={true}
      onClose={onClose}
      closeOnClickOutside={false}
      title="Setup campaign auto"
      centered
    >
      <LoadingOverlay visible={isLoadingScripts} loaderProps={{ children: <Loader size="xs" /> }} />
      <Box id="form-setup-script-automation" component="form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="scriptId"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Select
                label="Script"
                placeholder="Select script"
                value={value}
                onChange={onChange}
                data={scriptsOptions}
                data-testid="select-script"
                required
                error={error?.message}
              />
            )
          }}
        />
        {historyActivities && (
          <>
            <Text size="sm">History Activities</Text>
            <ScrollArea.Autosize mah={150} type="always">
              <div
                style={{ fontSize: 12 }}
                dangerouslySetInnerHTML={{ __html: historyActivities ?? '' }}
              />
            </ScrollArea.Autosize>
          </>
        )}
        <Group mt={24} justify="flex-end">
          {scriptId && (
            <Button
              variant="default"
              data-testid="btn-remove"
              onClick={onRemove}
              loading={isRemoving}
            >
              Remove
            </Button>
          )}
          <Button
            type="submit"
            data-testid="btn-submit"
            loading={isCreating || isUpdating}
            disabled={!isDirty}
          >
            Save
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}
