import { Controller, useForm } from 'react-hook-form'
import { REGEX } from '../../../shared/constants'
import {
  Alert,
  Box,
  Button,
  Grid,
  Group,
  Modal,
  TagsInput,
  TextInput,
  useMantineTheme,
} from '@mantine/core'
import { useAccountStore } from '../../../hooks'
import {
  CreateFullCampaignsDto,
  CreateFullCampaignsResp,
  useCreateFullCampaigns,
} from '../../../hooks/api/useFullCampaign'
import {
  SponsoredProductsCreateOrUpdateMatchType,
  SponsoredProductsTargetingType,
} from '../../../shared/enums'
import { useState } from 'react'

interface ICreateFullCampaignsForm {
  asin: string
  sku: string
  bid: string
  budget: string
  keywordTexts: string[]
}

interface ModalCreateFullCampaignsProps {
  opened: boolean
  onClose: () => void
}

interface IAlert {
  title: string
  text: string
}

const ModalCreateFullCampaigns = ({ opened, onClose }: ModalCreateFullCampaignsProps) => {
  const theme = useMantineTheme()
  const { activeAccount } = useAccountStore()
  const { handleSubmit, control, reset } = useForm<ICreateFullCampaignsForm>({
    defaultValues: {
      asin: '',
      sku: '',
      bid: '',
      budget: '',
      keywordTexts: [],
    },
  })
  const [successAlerts, setSuccessAlerts] = useState<IAlert[]>([])
  const [failureAlerts, setFailureAlerts] = useState<IAlert[]>([])

  const { mutate: createFullCampaigns, isPending } = useCreateFullCampaigns(activeAccount?.id ?? '')

  const closeAndReset = () => {
    onClose()
    reset()
    setSuccessAlerts([])
    setFailureAlerts([])
  }

  const onSubmit = async (data: ICreateFullCampaignsForm) => {
    const { asin, sku, bid, budget, keywordTexts } = data
    const items: CreateFullCampaignsDto['items'] = [
      {
        targetingType: SponsoredProductsTargetingType.AUTO,
        asin,
        sku,
        bid: Number(bid),
        budget: Number(budget),
      },
      ...keywordTexts.map((keywordText) => {
        return {
          targetingType: SponsoredProductsTargetingType.MANUAL,
          asin,
          sku,
          bid: Number(bid),
          budget: Number(budget),
          keywords: [
            {
              text: keywordText,
              matchType: SponsoredProductsCreateOrUpdateMatchType.EXACT,
            },
          ],
        }
      }),
    ]
    createFullCampaigns(
      { items },
      {
        onSuccess: (response) => {
          const { successAlerts, failureAlerts } = handleCreateFullCampaignResp(response)
          setSuccessAlerts(successAlerts)
          setFailureAlerts(failureAlerts)
        },
      },
    )
  }

  const handleCreateFullCampaignResp = (resp: CreateFullCampaignsResp) => {
    const { createdCampaigns, createdAdGroups, createdProductAds, createdKeywords } = resp
    const { failedCampaigns, failedAdGroups, failedProductAds, failedKeywords } = resp
    const successAlerts: IAlert[] = []
    if (createdCampaigns.length > 0) {
      successAlerts.push({
        title: 'Campaigns',
        text: createdCampaigns.map((item) => item.name).join('<br/>'),
      })
    }
    if (createdAdGroups.length > 0) {
      successAlerts.push({
        title: 'Ad Groups',
        text: createdAdGroups.map((item) => item.name).join('<br/>'),
      })
    }
    if (createdProductAds.length > 0) {
      successAlerts.push({
        title: 'Product Ads',
        text: createdProductAds
          .map((item) => `${item.asin ?? 'Unkown ASIN'} - ${item.sku ?? 'Unkown SKU'}`)
          .join('<br/>'),
      })
    }
    if (createdKeywords.length > 0) {
      successAlerts.push({
        title: 'Keywords',
        text: createdKeywords.map((item) => item.keywordText).join('<br/>'),
      })
    }
    const failureAlerts: IAlert[] = []
    if (failedCampaigns.length > 0) {
      failureAlerts.push({
        title: 'Campaigns',
        text: failedCampaigns.map((item) => item.text).join('<br/>'),
      })
    }
    if (failedAdGroups.length > 0) {
      failureAlerts.push({
        title: 'Ad Groups',
        text: failedAdGroups.map((item) => item.text).join('<br/>'),
      })
    }
    if (failedProductAds.length > 0) {
      failureAlerts.push({
        title: 'Product Ads',
        text: failedProductAds.map((item) => item.text).join('<br/>'),
      })
    }
    if (failedKeywords.length > 0) {
      failureAlerts.push({
        title: 'Keywords',
        text: failedKeywords
          .map((item) => `${item.payloadItem.keywordText}: ${item.text}`)
          .join('<br/>'),
      })
    }
    return {
      successAlerts,
      failureAlerts,
    }
  }

  return (
    <Modal
      opened={opened}
      onClose={closeAndReset}
      closeOnClickOutside={false}
      title="Create full campaigns"
      centered
      size={'xl'}
    >
      <Box id="form-create-full-campaigns" component="form" onSubmit={handleSubmit(onSubmit)}>
        <Group grow>
          <Controller
            name="asin"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="asin"
                label="ASIN"
                onChange={onChange}
                value={value}
                data-testid="input-asin"
                required
              />
            )}
          />
          <Controller
            name="sku"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="sku"
                label="SKU"
                onChange={onChange}
                value={value}
                data-testid="input-sku"
                required
              />
            )}
          />
        </Group>
        <Group grow>
          <Controller
            name="bid"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="bid"
                label="Bid"
                onChange={({ target: { value: inputValue } }) => {
                  if (REGEX.NUMBER.test(inputValue)) {
                    onChange(inputValue)
                  }
                }}
                value={value}
                data-testid="input-bid"
                required
              />
            )}
          />
          <Controller
            name="budget"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="budget"
                label="Budget"
                onChange={({ target: { value: inputValue } }) => {
                  if (REGEX.NUMBER.test(inputValue)) {
                    onChange(inputValue)
                  }
                }}
                value={value}
                data-testid="input-budget"
                required
              />
            )}
          />
        </Group>
        <Controller
          name="keywordTexts"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TagsInput
              name="keywordTexts"
              mt={8}
              label="Keywords"
              value={value}
              onChange={onChange}
              data-testid="input-keywords"
            />
          )}
        />
        <Grid my={8}>
          <Grid.Col span={6}>
            {successAlerts.map((alert) => {
              return (
                <Alert
                  styles={{ root: { padding: 8 } }}
                  key={alert.text}
                  title={alert.title}
                  color={theme.colors.success[6]}
                  my={2}
                >
                  <div dangerouslySetInnerHTML={{ __html: alert.text }}></div>
                </Alert>
              )
            })}
          </Grid.Col>
          <Grid.Col span={6}>
            {failureAlerts.map((alert) => {
              return (
                <Alert
                  styles={{ root: { padding: 8 } }}
                  key={alert.text}
                  title={alert.title}
                  color={theme.colors.warning[6]}
                  my={2}
                >
                  <div dangerouslySetInnerHTML={{ __html: alert.text }}></div>
                </Alert>
              )
            })}
          </Grid.Col>
        </Grid>
        <Group mt={24}>
          <Button type="submit" disabled={isPending} fullWidth>
            Save
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}

export default ModalCreateFullCampaigns
