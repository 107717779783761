import { Button, Menu, MenuItem } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useContext, useState } from 'react'
import { ProductsContext } from '../../contexts/products/products.context'
import { useRestoreSkus, useSkuStatsStore, useSoftDeleteSkus } from '../../hooks'

export default function BulkActionDropdown() {
  const { selectedSkuIds, setSelectedSkuIds } = useContext(ProductsContext)
  const { mutate: softDeleteSkus } = useSoftDeleteSkus()
  const { mutate: restoreSkus } = useRestoreSkus()
  const removeSkusStats = useSkuStatsStore((state) => state.removeSkusStats)
  const restoreSkusStats = useSkuStatsStore((state) => state.restoreSkusStats)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const softDelete = async () => {
    softDeleteSkus(selectedSkuIds, {
      onSuccess: () => {
        removeSkusStats(selectedSkuIds)
        setSelectedSkuIds([])
        handleClose()
      },
    })
  }

  const restore = async () => {
    restoreSkus(selectedSkuIds, {
      onSuccess: () => {
        restoreSkusStats(selectedSkuIds)
        setSelectedSkuIds([])
        handleClose()
      },
    })
  }

  return (
    <>
      <Button
        data-testid="bulk-action-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Actions ({selectedSkuIds.length})
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          data-testid="add-to-hidden-list-button"
          onClick={softDelete}
          sx={{
            minWidth: 150,
          }}
        >
          Move to Trash
        </MenuItem>
        <MenuItem onClick={restore} data-testid="remove-from-hidden-list-button">
          Restore from Trash
        </MenuItem>
      </Menu>
    </>
  )
}
