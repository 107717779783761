import _ from 'lodash'
import Papa from 'papaparse'

export const toJson = (csvText: string) => {
  let parsedData: object[] = []
  Papa.parse(csvText, {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    complete: (result) => {
      if (!_.isNil(result.data) && result.data.length > 0) {
        parsedData = result.data as object[]
      }
    },
    error: (error: any) => {
      console.error('CSV parsing error:', error)
    },
  })
  return parsedData
}
