import dayjs from '../../utils/dayjs.utils'
import { useExecuteTask, useSearchTasks, useAccountStore } from '../../hooks'
import _ from 'lodash'
import { Button, Table, useMantineTheme } from '@mantine/core'
import { TaskStatus } from '../../shared/enums'
import { FaCircleCheck, FaPersonRunning } from 'react-icons/fa6'
import { BiSolidError } from 'react-icons/bi'
import { MdFiberNew } from 'react-icons/md'

export default function TaskManagement() {
  const theme = useMantineTheme()
  const { activeAccount } = useAccountStore()
  const { data: tasks } = useSearchTasks({
    accountId: activeAccount?.id ?? '',
    from: dayjs().subtract(3, 'day').startOf('day').toISOString(),
  })
  const { mutate: executeTask, isPending: isExecuting } = useExecuteTask()

  return (
    <Table stickyHeader stickyHeaderOffset={64} verticalSpacing="sm">
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Task</Table.Th>
          <Table.Th>Executed At</Table.Th>
          <Table.Th style={{ textAlign: 'center' }}>Status</Table.Th>
          <Table.Th style={{ textAlign: 'center' }}>Duration (s)</Table.Th>
          <Table.Th style={{ textAlign: 'center' }}>Actions</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {_.map(tasks, (task) => (
          <Table.Tr key={task.id}>
            <Table.Td>{task.name}</Table.Td>
            <Table.Td>{dayjs(task.executedAt).format('DD-MM-YYYY HH:mm')}</Table.Td>
            <Table.Td align="center">
              {task.status === TaskStatus.NEW && <MdFiberNew color={theme.colors.gray[6]} />}
              {task.status === TaskStatus.PROCESSING && (
                <FaPersonRunning color={theme.colors.information[6]} />
              )}
              {task.status === TaskStatus.SUCCESS && (
                <FaCircleCheck color={theme.colors.success[6]} />
              )}
              {task.status === TaskStatus.FAIL && <BiSolidError color={theme.colors.danger[6]} />}
            </Table.Td>
            <Table.Td align="center">
              {dayjs(task.finishedAt).diff(dayjs(task.executedAt), 'second')}
            </Table.Td>
            <Table.Td align="center">
              {task.status === TaskStatus.FAIL && (
                <Button
                  size="compact-sm"
                  variant="outline"
                  onClick={() => {
                    executeTask(task.id)
                  }}
                  disabled={isExecuting}
                >
                  Run again
                </Button>
              )}
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
}
