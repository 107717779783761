import dayjs from './dayjs.utils'
import _ from 'lodash'

export const isFBA = (shipsFrom: string) => {
  const lowCaseShipsFrom = _.toLower(shipsFrom)
  return _.includes(lowCaseShipsFrom, 'amazon')
}

export const renderPriceUpdatedAt = (priceUpdatedAt: string) => {
  const timeText = dayjs(priceUpdatedAt).format('DD/MM HH:mm')
  return _.isNil(priceUpdatedAt) ? '' : `(${timeText})`
}
