export * from './amz-ads.enum'
export * from './script.enum'

export enum CrawlingStatus {
  NEW = 'NEW',
  IN_PROCESS = 'IN_PROCESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  STOCK_UNAVAILABLE = 'STOCK_UNAVAILABLE',
}

export enum TaskStatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export enum TaskName {
  RECORD_INBOUND_SHIPMENTS_FROM_AMZ = 'RECORD_INBOUND_SHIPMENTS_FROM_AMZ',
  RECORD_INVENTORY_FROM_AMZ = 'RECORD_INVENTORY_FROM_AMZ',
  RECORD_FULFILLED_SHIPMENTS_FROM_AMZ = 'RECORD_FULFILLED_SHIPMENTS_FROM_AMZ',
  RECORD_PRODUCT_METADATA_FROM_AMZ = 'RECORD_PRODUCT_METADATA_FROM_AMZ',
  UPDATE_SALES30D_FOR_SKUS = 'UPDATE_SALES30D_FOR_SKUS',
  PROCESS_AUTOPRICE = 'PROCESS_AUTOPRICE',

  ENQUEUE_ASIN_TO_CRAWL = 'ENQUEUE_ASIN_TO_CRAWL',
  SYNC_ASINS_COMPETITIONS = 'SYNC_ASINS_COMPETITIONS',
}

export enum ScenarioType {
  SCENARIO_1 = 'SCENARIO_1',
  SCENARIO_2 = 'SCENARIO_2',
}
