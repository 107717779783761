import { Control, Controller } from 'react-hook-form'
import { NumberInput } from '@mantine/core'
import { IFormScript } from '../type'

interface Props {
  index: number
  control: Control<IFormScript, any>
}

const ActionConfigPercentage = ({ index, control }: Props) => {
  return (
    <Controller
      control={control}
      name={`cases.${index}.actionConfig.percentage`}
      render={({ field: { onChange, value } }) => (
        <NumberInput
          value={value}
          min={-100}
          onChange={onChange}
          label="Percentage (%)"
          required
          hideControls
        />
      )}
    />
  )
}
export default ActionConfigPercentage
