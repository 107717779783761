import { useState } from 'react'
import { ProductsContext } from './products.context'

export default function ProductsContextProvider(props: { children: React.ReactNode }) {
  const [selectedSkuIds, setSelectedSkuIds] = useState<string[]>([])
  const [hiddenColumnIds, setHiddenColumnIds] = useState<string[]>(
    JSON.parse(localStorage.getItem('hidden_columns_ids') ?? '[]'),
  )

  const saveHiddenColumnIds = (columnIds: string[]) => {
    setHiddenColumnIds(columnIds)
    localStorage.setItem('hidden_columns_ids', JSON.stringify(columnIds))
  }

  return (
    <ProductsContext.Provider
      value={{
        selectedSkuIds,
        setSelectedSkuIds,

        hiddenColumnIds,
        saveHiddenColumnIds,
      }}
    >
      {props.children}
    </ProductsContext.Provider>
  )
}
