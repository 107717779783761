import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import { create } from 'zustand'
import { IJWTPayload } from '../../shared/interfaces'

interface IAuthStore {
  isLoggedIn: boolean
  setLoggedIn: (accessToken: string) => void
  removeAccessToken: () => void
}

const checkIsLoggedIn = (): boolean => {
  const accessToken = localStorage.getItem('accessToken')
  if (_.isNil(accessToken)) return false
  try {
    const jwtPayload: IJWTPayload = jwtDecode(accessToken)
    const isExpired = jwtPayload.exp * 1000 - Date.now() <= 0
    return !isExpired
  } catch (error) {
    return false
  }
}

export const useAuthStore = create<IAuthStore>((set) => ({
  isLoggedIn: checkIsLoggedIn(),
  setLoggedIn: (accessToken: string) => {
    localStorage.setItem('accessToken', accessToken)
    set({ isLoggedIn: true })
  },
  removeAccessToken: () => {
    localStorage.removeItem('accessToken')
    set({ isLoggedIn: false })
  },
}))
