import { Control, Controller } from 'react-hook-form'
import { Select } from '@mantine/core'
import { IFormScript } from '../type'

interface Props {
  index: number
  control: Control<IFormScript, any>
}

const ActionConfigXDays = ({ index, control }: Props) => {
  return (
    <Controller
      control={control}
      name={`cases.${index}.actionConfig.xDays`}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Select
          data={Array.from({ length: 30 }, (_, i) => (i + 1).toString())}
          value={value?.toString()}
          onChange={onChange}
          allowDeselect={false}
          label="X Days"
          required
          error={error?.message}
        />
      )}
    />
  )
}
export default ActionConfigXDays
