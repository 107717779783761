import { useDisclosure } from '@mantine/hooks'
import { createRef, useCallback, useImperativeHandle, useState } from 'react'
import ModalSetupAutocampaign from '../Modals/ModalSetupAutocampaign'

const layerCampaignRef = createRef<any>()

export const useLayerCampaign = () => {
  const openModalSetupAutocampaign = useCallback((campaignId: number) => {
    layerCampaignRef.current?.openModalSetupAutocampaign(campaignId)
  }, [])

  return { openModalSetupAutocampaign }
}

export const LayerCampaign = () => {
  const [openedModalSetup, { open: openModalSetup, close: closeModalSetup }] = useDisclosure(false)
  const [campaignId, setCampaignId] = useState<number>()

  const closeAndResetModalSetup = useCallback(() => {
    setCampaignId(undefined)
    closeModalSetup()
  }, [])
  useImperativeHandle(
    layerCampaignRef,
    () => ({
      openModalSetupAutocampaign: (campaignId: number) => {
        setCampaignId(campaignId)
        openModalSetup()
      },
    }),
    [openModalSetup],
  )

  return campaignId ? (
    <>
      <ModalSetupAutocampaign
        campaignId={campaignId}
        opened={openedModalSetup}
        onClose={closeAndResetModalSetup}
      />
    </>
  ) : null
}
