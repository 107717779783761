import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { Navigate } from 'react-router-dom'
import { ROUTES } from '../../shared/constants'
import { useAuthStore, useLogin } from '../../hooks'

interface IFormInputs {
  username: string
  password: string
}
export default function LoginPage() {
  const { isLoggedIn, setLoggedIn } = useAuthStore()
  const { register, handleSubmit } = useForm<IFormInputs>()
  const { mutate: login, isPending: isLogging } = useLogin()

  const onSubmit = async ({ username, password }: IFormInputs) => {
    login(
      { username, password },
      {
        onSuccess: ({ accessToken }) => {
          setLoggedIn(accessToken)
        },
      },
    )
  }

  return !isLoggedIn ? (
    <Grid container sx={{ p: 3 }} mt={8} spacing={2} justifyContent="center">
      <Grid item md={4}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <Typography align="center" component="h1" variant="h5">
            Login
          </Typography>
          <TextField
            {...register('username', {
              required: true,
            })}
            inputProps={{
              'data-testid': 'username',
            }}
            variant="standard"
            margin="normal"
            required
            fullWidth
            label="User name"
            autoFocus
          />
          <TextField
            {...register('password', {
              required: true,
            })}
            inputProps={{
              'data-testid': 'password',
            }}
            variant="standard"
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLogging}
            data-testid="login-button"
          >
            Login
          </Button>
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Navigate to={ROUTES.HOME.PATH} replace />
  )
}
