import { Box, Button, Menu, MenuItem, Modal, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LinearProgress from '@mui/material/LinearProgress'
import { ChangeEvent, useState } from 'react'
import _ from 'lodash'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { CsvUtils } from '../../utils'
import { UpdateByAsinCodeDto, UpdateByFnskuDto } from '../../hooks/api/dto'
import { useAccountStore, useUpdateAsinByAsinCode, useUpdateSkuByFnsku } from '../../hooks'

const StyledInput = styled('input')({
  display: 'none',
})

enum IMPORT_TYPE {
  ASIN = 'ASIN',
  SKU = 'SKU',
}

export default function CsvImport() {
  const navigate = useNavigate()

  const { activeAccount } = useAccountStore()
  const [openModal, setOpenModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedFile, setSelectedFile] = useState<File>()
  const [importType, setImportType] = useState<IMPORT_TYPE>()
  const [importProgress, setImportProgress] = useState<number>()
  const { mutateAsync: updateAsinByAsinCode } = useUpdateAsinByAsinCode(activeAccount?.id ?? '')
  const { mutateAsync: updateSkuByFnsku } = useUpdateSkuByFnsku(activeAccount?.id ?? '')

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseAnchorEl = () => {
    setAnchorEl(null)
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const file = event.dataTransfer.files?.[0]
    setSelectedFile(file || null)
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    setSelectedFile(file)
  }

  const openImportAsinsModal = () => {
    handleCloseAnchorEl()
    setImportType(IMPORT_TYPE.ASIN)
    setOpenModal(true)
  }

  const openImportSkusModal = () => {
    handleCloseAnchorEl()
    setImportType(IMPORT_TYPE.SKU)
    setOpenModal(true)
  }

  const processImport = () => {
    if (selectedFile) {
      if (!selectedFile) {
        alert('Please select a CSV file')
        return
      }
      const reader = new FileReader()
      reader.onload = async () => {
        setImportProgress(0)
        // handle uploaded data and import here
        const text = reader.result as string
        const parsedData = CsvUtils.toJson(text).slice(1)
        switch (importType) {
          case IMPORT_TYPE.ASIN:
            await processImportAsins(parsedData)
            break
          case IMPORT_TYPE.SKU:
            await processImportSkus(parsedData)
            break
        }
      }
      reader.readAsText(selectedFile)
    }
  }

  const processImportAsins = async (parsedData: object[]) => {
    if (!activeAccount) return
    const asinsToUpdate = mapDataToAsins(parsedData)
    const updatedAsins: UpdateByAsinCodeDto[] = []
    for (let i = 0; i < asinsToUpdate.length; i++) {
      const asinToUpdate = asinsToUpdate[i]
      try {
        await updateAsinByAsinCode(asinToUpdate)
        updatedAsins.push(asinToUpdate)
      } catch (error) {
        alert(
          `Error update ASIN ${asinToUpdate.asinCode}: ${_.get(
            error,
            'response.data.message',
            '',
          )}\n` + JSON.stringify(asinToUpdate),
        )
      }
      setImportProgress((_.size(updatedAsins) / _.size(asinsToUpdate)) * 100)
    }
    navigate(0)
  }

  const processImportSkus = async (parsedData: object[]) => {
    if (!activeAccount) return
    const skusToUpdate = mapDataToSkus(parsedData)
    const updatedSkus: UpdateByFnskuDto[] = []
    for (let i = 0; i < skusToUpdate.length; i++) {
      const skuToUpdate = skusToUpdate[i]
      try {
        await updateSkuByFnsku(skuToUpdate)
        updatedSkus.push(skuToUpdate)
      } catch (error) {
        alert(
          `Error update SKU ${skuToUpdate.fnsku}: ${_.get(error, 'response.data.message', '')}\n` +
            JSON.stringify(skuToUpdate),
        )
        console.log(error)
      }
      setImportProgress((_.size(updatedSkus) / _.size(skusToUpdate)) * 100)
    }
    navigate(0)
  }

  const mapDataToAsins = (data: object[]): UpdateByAsinCodeDto[] => {
    return _.map(data, (object: any): UpdateByAsinCodeDto => {
      return {
        asinCode: object.asinCode ?? '',
        cogs: Number(object.cogs),
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        ship: object.ship ? `${object.ship}` : null,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        notes: object.notes ? `${object.notes}` : null,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        assignee: object.assignee ? `${object.assignee}` : null,
        containerSpec: {
          lSet: object['containerSpec.lSet'],
          wSet: object['containerSpec.wSet'],
          hSet: object['containerSpec.hSet'],
          gwSet: object['containerSpec.gwSet'],
          lCtn: object['containerSpec.lCtn'],
          wCtn: object['containerSpec.wCtn'],
          hCtn: object['containerSpec.hCtn'],
          qtCtn: object['containerSpec.qtCtn'],
          gwCtn: object['containerSpec.gwCtn'],
        },
      }
    })
  }

  const mapDataToSkus = (data: any[]): UpdateByFnskuDto[] => {
    return _.map(data, (object: any): UpdateByFnskuDto => {
      return {
        fnsku: object.fnsku,
        waitForBuying: object.waitForBuying ?? 0,
        goingToFw: object.goingToFw ?? 0,
        estimatedReceivedDate: object.estimatedReceivedDate ? object.estimatedReceivedDate : null,
        inChineseStock: object.inChineseStock ?? 0,
      }
    })
  }

  const LinearProgressWithLabel = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', pb: 2 }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={importProgress} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            importProgress ?? 0,
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Button
        data-testid="import-csv-dropdown-button"
        aria-controls={open ? 'csv-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Import CSV
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="csv-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseAnchorEl}
        MenuListProps={{
          'aria-labelledby': 'csv-button',
        }}
      >
        <MenuItem data-testid="open-modal-import-asin-csv-button" onClick={openImportAsinsModal}>
          Import ASIN
        </MenuItem>
        <MenuItem data-testid="open-modal-import-sku-csv-button" onClick={openImportSkusModal}>
          Import SKU
        </MenuItem>
      </Menu>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
      >
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: 400,
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fff',
            padding: '2rem',
          }}
        >
          <Typography variant="h6">Import {importType}</Typography>
          <StyledInput
            accept=".csv" // Specify the accepted file types
            id="file-input"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="file-input">
            {selectedFile != null ? (
              <p>Selected File: {selectedFile.name}</p>
            ) : (
              <p>Drag and drop file here or click to browse</p>
            )}
          </label>
          {!_.isNil(importProgress) && <LinearProgressWithLabel />}
          <Button
            data-testid="import-csv-button"
            onClick={processImport}
            disabled={!selectedFile || !_.isNil(importProgress)}
            variant="contained"
            color="primary"
          >
            Import
          </Button>
        </div>
      </Modal>
    </>
  )
}
