import { Grid } from '@mui/material'
import CompetitionObjective from './CardCompetionObjective'
import CardCompetitor from './CardCompetitor'
import { ICompetition } from '../../shared/interfaces'
import { memo } from 'react'

interface ICompetitionItemProps {
  competition: ICompetition
}

const CompetitionItem = ({ competition }: ICompetitionItemProps) => {
  return (
    <Grid container spacing={2} my={1}>
      <Grid item xs={12} md={2}>
        <CompetitionObjective competition={competition} />
      </Grid>
      {competition.competitors.map((competitor) => (
        <Grid item xs={12} md={2} key={competitor.id}>
          <CardCompetitor competitor={competitor} />
        </Grid>
      ))}
    </Grid>
  )
}

export default memo(CompetitionItem)
