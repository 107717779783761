import { UseFormReturn, useFieldArray } from 'react-hook-form'
import { Box, Flex, Text, useMantineTheme } from '@mantine/core'
import { IFormCondition, IFormScript } from './type'
import { KeywordAttribute, Operator, SearchTermAttribute } from '../../../shared/enums'
import SetupCondition from './SetupCondition'

interface Props {
  index: number
  formController: UseFormReturn<IFormScript, any>
}

export const DEFAULT_CONDITION: IFormCondition = {
  attribute: '' as SearchTermAttribute | KeywordAttribute,
  // todo: base on attribute to set default value to xDays
  operator: '' as Operator,
  value: '',
}

const ConditionFields = ({ index, formController }: Props) => {
  const theme = useMantineTheme()
  const { control } = formController
  const {
    fields: conditionFields,
    append: appendCondition,
    remove: removeCondition,
  } = useFieldArray({
    control,
    name: `cases.${index}.conditions`,
  })

  return (
    <Box>
      {conditionFields.map((field, idx) => (
        <SetupCondition
          key={field.id}
          caseIdx={index}
          conditionIdx={idx}
          formController={formController}
          allowRemove={conditionFields.length > 1}
          removeCondition={removeCondition}
        />
      ))}
      <Flex justify="flex-end">
        <Text
          fz={14}
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            color: theme.colors.information[6],
          }}
          onClick={() => appendCondition({ ...DEFAULT_CONDITION })}
        >
          Add condition
        </Text>
      </Flex>
    </Box>
  )
}
export default ConditionFields
