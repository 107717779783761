export enum SponsoredProductsCreateOrUpdateNegativeMatchType {
  NEGATIVE_EXACT = 'NEGATIVE_EXACT',
  NEGATIVE_PHRASE = 'NEGATIVE_PHRASE',
  NEGATIVE_BROAD = 'NEGATIVE_BROAD',
}

export enum SponsoredProductsCreateOrUpdateEntityState {
  ENABLED = 'ENABLED',
  PAUSED = 'PAUSED',
}

export enum SponsoredProductsTargetingType {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export enum SponsoredProductsBudgetType {
  DAILY = 'DAILY',
  OTHER = 'OTHER',
}

export enum SponsoredProductsCreateOrUpdateMatchType {
  BROAD = 'BROAD',
  PHRASE = 'PHRASE',
  EXACT = 'EXACT',
}

export enum SponsoredProductsQueryTermMatchType {
  BROAD_MATCH = 'BROAD_MATCH',
  EXACT_MATCH = 'EXACT_MATCH',
}

export enum SponsoredProductsMatchType {
  EXACT = 'EXACT',
  PHRASE = 'PHRASE',
  BROAD = 'BROAD',
  OTHER = 'OTHER',
}

export enum SponsoredProductsEntityState {
  ENABLED = 'ENABLED',
  PAUSED = 'PAUSED',
  ARCHIVED = 'ARCHIVED',
  ENABLING = 'ENABLING',
  USER_DELETED = 'USER_DELETED',
  OTHER = 'OTHER',
}

export enum SponsoredProductsCreateOrUpdateBudgetType {
  DAILY = 'DAILY',
}

export enum SponsoredProductsNegativeMatchType {
  NEGATIVE_EXACT = 'NEGATIVE_EXACT',
  NEGATIVE_PHRASE = 'NEGATIVE_PHRASE',
  NEGATIVE_BROAD = 'NEGATIVE_BROAD',
  OTHER = 'OTHER',
}

export enum SearchTermMatchType {
  BROAD = 'BROAD',
  PHRASE = 'PHRASE',
  EXACT = 'EXACT',
  TARGETING_EXPRESSION = 'TARGETING_EXPRESSION',
  TARGETING_EXPRESSION_PREDEFINED = 'TARGETING_EXPRESSION_PREDEFINED',
}
