import { useQuery } from '@tanstack/react-query'
import { AuthRequest } from './request'
import { QUERY_KEY } from '../../constants'
import { IScriptExecutionActivity } from '../../shared/interfaces-v2'
import { CustomQueryOptions } from './type'

export interface SearchScriptAutomationActivitiesPayload {
  accountId?: string
  campaignId?: number
}

export const useSearchScriptAutomationActivities = (
  payload: SearchScriptAutomationActivitiesPayload,
  queryOptions: CustomQueryOptions = {},
) => {
  const { accountId, campaignId } = payload
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_CAMPAIGN_AUTO_ACTIVITIES, accountId, campaignId],
    queryFn: async (): Promise<IScriptExecutionActivity[]> => {
      const { data } = await AuthRequest.post(`script-automation-activities/search`, payload)
      return data
    },
    ...queryOptions,
  })
}
