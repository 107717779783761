import _ from 'lodash'

export default function ColumnFilter({ column }: any) {
  const filterValue = _.get(column, 'filterValue', '')
  return (
    <input
      value={filterValue}
      onChange={(e) => {
        const value = _.get(e, 'target.value')
        column.setFilter(value)
      }}
      placeholder="Filter..."
      name="filter"
    />
  )
}
