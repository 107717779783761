export const QUERY_KEY = {
  GET_ALL_ACCOUNTS: 'GET_ALL_ACCOUNTS',

  SEARCH_COMPETITIONS: 'SEARCH_COMPETITIONS',
  GET_ONE_COMPETITION: 'GET_ONE_COMPETITION',

  GET_ONE_COMPETITOR: 'GET_ONE_COMPETITOR',

  SEARCH_AUTOPRICE_LOGS: 'SEARCH_AUTOPRICE_LOGS',

  SEARCH_ASINS: 'SEARCH_ASINS',
  GET_ONE_ASIN: 'GET_ONE_ASIN',

  SEARCH_SKUS: 'SEARCH_SKUS',
  SEARCH_SKUS_STATS: 'SEARCH_SKUS_STATS',
  GET_ONE_SKU: 'GET_ONE_SKU',

  SEARCH_TASKS: 'SEARCH_TASKS',

  SEARCH_RP_CAMPAIGN_SUMMARIES: 'SEARCH_RP_CAMPAIGN_SUMMARIES',
  SEARCH_RP_CAMPAIGN_DETAIL_SUMMARIES: 'SEARCH_RP_CAMPAIGN_DETAIL_SUMMARIES',

  SEARCH_AC_CAMPAIGNS: 'SEARCH_AC_CAMPAIGNS',
  FIND_ONE_AC_CAMPAIGN: 'FIND_ONE_AC_CAMPAIGN',

  SEARCH_AUTOCAMPAIGN_LOGS: 'SEARCH_AUTOCAMPAIGN_LOGS',

  SEARCH_SCRIPTS: 'SEARCH_SCRIPTS',
  GET_ONE_SCRIPT: 'GET_ONE_SCRIPT',

  SEARCH_CAMPAIGN_ALERTS: 'SEARCH_CAMPAIGN_ALERTS',

  GET_ONE_CAMPAIGN_AUTO: 'GET_ONE_CAMPAIGN_AUTO',

  SEARCH_CAMPAIGN_AUTO_ACTIVITIES: 'SEARCH_CAMPAIGN_AUTO_ACTIVITIES',
}
