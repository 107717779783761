import { useMutation, useQuery } from '@tanstack/react-query'
import { AuthRequest } from './request'
import { QUERY_KEY } from '../../constants'

export const useGetOneScriptAutomation = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.GET_ONE_CAMPAIGN_AUTO, id],
    queryFn: async () => {
      const { data } = await AuthRequest.get(`script-automation/${id}`)
      return data
    },
    enabled: !!id,
  })
}

interface CreateScriptAutomation {
  accountId: string
  scriptId: string
  campaignId: number
}

export const useCreateScriptAutomation = () => {
  return useMutation({
    mutationFn: async (payload: CreateScriptAutomation) => {
      const { data } = await AuthRequest.post(`script-automation`, payload)
      return data
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}

interface UpdateScriptAutomation {
  scriptId: string
}

export const useUpdateScriptAutomation = (id: string) => {
  return useMutation({
    mutationFn: async (payload: UpdateScriptAutomation) => {
      const { data } = await AuthRequest.patch(`script-automation/${id}`, payload)
      return data
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}

export const useDeleteScriptAutomation = (id: string) => {
  return useMutation({
    mutationFn: async () => {
      const { data } = await AuthRequest.delete(`script-automation/${id}`)
      return data
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })
}
