import { create } from 'zustand'
import { IRpCampaignSummary } from '../../shared/interfaces-v2'

interface IPPCStore {
  rpCampaignSummaries: IRpCampaignSummary[]
  setRpCampaignSummaries: (rpCampaignSummaries: IRpCampaignSummary[]) => void
  replaceRpCampaignSummary: (rpCampaignSummary: IRpCampaignSummary) => void
  updateRpCampaignSummary: (campaignId: number, update: Partial<IRpCampaignSummary>) => void
}

export const usePPCStore = create<IPPCStore>((set) => ({
  rpCampaignSummaries: [],
  setRpCampaignSummaries: (newRpCampaignSummaries: IRpCampaignSummary[]) => {
    set({ rpCampaignSummaries: newRpCampaignSummaries })
  },
  replaceRpCampaignSummary: (rpCampaignSummary: IRpCampaignSummary) => {
    set(({ rpCampaignSummaries }) => {
      const updatedRpCampaignSummaries = rpCampaignSummaries.map((prevRpCampaignSummary) => {
        if (rpCampaignSummary.campaignId === prevRpCampaignSummary.campaignId) {
          return rpCampaignSummary
        }
        return prevRpCampaignSummary
      })
      return { rpCampaignSummaries: updatedRpCampaignSummaries }
    })
  },
  updateRpCampaignSummary: (campaignId: number, update: Partial<IRpCampaignSummary>) => {
    set(({ rpCampaignSummaries }) => {
      const updatedRpCampaignSummaries = rpCampaignSummaries.map((prevRpCampaignSummary) => {
        if (campaignId === prevRpCampaignSummary.campaignId) {
          return { ...prevRpCampaignSummary, ...update }
        }
        return prevRpCampaignSummary
      })
      return { rpCampaignSummaries: updatedRpCampaignSummaries }
    })
  },
}))
