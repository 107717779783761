import { createTheme } from '@mantine/core'

export const mantineTheme = createTheme({
  colors: {
    neutral: [
      '#F9FAFB',
      '#F3F4F6',
      '#E5E7EB',
      '#D1D5DB',
      '#9CA3AF',
      '#6B7280',
      '#4B5563',
      '#374151',
      '#1F2937',
      '#111827',
    ],
    information: [
      '#EAF1FB',
      '#D5E3F6',
      '#C0D5F2',
      '#ABC7ED',
      '#97B9E9',
      '#82AAE4',
      '#2E72D2',
      '#255BA8',
      '#1C447E',
      '#122E54',
    ],
    success: [
      '#EDFDF4',
      '#D1FAE3',
      '#A5F3C7',
      '#6EE7A3',
      '#36D37A',
      '#0EA44F',
      '#08873F',
      '#047234',
      '#06602D',
      '#064C24',
    ],
    warning: [
      '#FFF9EB',
      '#FFF3D6',
      '#FEE6A9',
      '#FDD672',
      '#FBC53C',
      '#DB8112',
      '#B26609',
      '#965A0F',
      '#804C0D',
      '#663F0F',
    ],
    danger: [
      '#FEF1F1',
      '#FEE1E1',
      '#FEC8C8',
      '#FCA6A6',
      '#F87272',
      '#EF4343',
      '#E02D2D',
      '#BA2525',
      '#981B1B',
      '#861313',
    ],
  },
})
