import { useQuery } from '@tanstack/react-query'
import { AuthRequest } from './request'
import { QUERY_KEY } from '../../constants'
import { SearchAutopriceLogsDto } from './dto'
import { IAutopriceLog } from '../../shared/interfaces'

export const useSearchAutopriceLogs = (dto: SearchAutopriceLogsDto) => {
  const { accountId, from } = dto
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_AUTOPRICE_LOGS, accountId, from],
    queryFn: async (): Promise<IAutopriceLog[]> => {
      const { data } = await AuthRequest.post(`autoprice-logs/search`, dto)
      return data
    },
    enabled: !!accountId,
  })
}
