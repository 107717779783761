import _ from 'lodash'
import { IContainerSpecs } from '../shared/interfaces'
import { IInboundItem, ISku } from '../shared/interfaces-v2'

export const sum = (values: Array<number | undefined>) => {
  const sum = values.reduce((partialSum: number, num: number | undefined) => {
    return _.isNil(num) ? partialSum : partialSum + num
  }, 0)
  return sum
}

export const calcVwExpSet = (containerSpecs: IContainerSpecs | undefined): number | undefined => {
  if (_.isNil(containerSpecs)) {
    return undefined
  }
  let { lCtn, wCtn, hCtn, qtCtn, lSet, wSet, hSet } = containerSpecs
  if ([lCtn, wCtn, hCtn, qtCtn].some((value) => _.isNil(value))) {
    lCtn = lSet
    wCtn = wSet
    hCtn = hSet
    qtCtn = 1
  }
  if (!_.isNil(lCtn) && !_.isNil(wCtn) && !_.isNil(hCtn) && !_.isNil(qtCtn)) {
    return (lCtn * wCtn * hCtn) / (5000 * qtCtn)
  }
}

export const calcVwSeaSet = (containerSpecs: IContainerSpecs | undefined): number | undefined => {
  if (_.isNil(containerSpecs)) {
    return undefined
  }
  let { lCtn, wCtn, hCtn, qtCtn, lSet, wSet, hSet } = containerSpecs
  if ([lCtn, wCtn, hCtn, qtCtn].some((value) => _.isNil(value))) {
    lCtn = lSet
    wCtn = wSet
    hCtn = hSet
    qtCtn = 1
  }
  if (!_.isNil(lCtn) && !_.isNil(wCtn) && !_.isNil(hCtn) && !_.isNil(qtCtn)) {
    return (lCtn * wCtn * hCtn) / (6000 * qtCtn)
  }
}

export const calcCbmSet = (containerSpecs: IContainerSpecs | undefined): number | undefined => {
  if (_.isNil(containerSpecs)) {
    return undefined
  }
  let { lCtn, wCtn, hCtn, qtCtn, lSet, wSet, hSet } = containerSpecs
  if ([lCtn, wCtn, hCtn, qtCtn].some((value) => _.isNil(value))) {
    lCtn = lSet
    wCtn = wSet
    hCtn = hSet
    qtCtn = 1
  }
  if (!_.isNil(lCtn) && !_.isNil(wCtn) && !_.isNil(hCtn) && !_.isNil(qtCtn)) {
    return (lCtn * wCtn * hCtn) / (1000000 * qtCtn)
  }
}

export const calcVwExp = (sku: ISku): number | undefined => {
  const inChineseStock = sku.inChineseStock
  const vwExpSet = calcVwExpSet(sku.asin.containerSpec)
  if (!_.isNil(inChineseStock) && !_.isNil(vwExpSet)) {
    return inChineseStock * vwExpSet
  }
}

export const calcVwSea = (sku: ISku): number | undefined => {
  const inChineseStock = sku.inChineseStock
  const vwSeaSet = calcVwSeaSet(sku.asin.containerSpec)
  if (!_.isNil(inChineseStock) && !_.isNil(vwSeaSet)) {
    return inChineseStock * vwSeaSet
  }
}

export const calcCbm = (sku: ISku): number | undefined => {
  const inChineseStock = sku.inChineseStock
  const cbmSet = calcCbmSet(sku.asin.containerSpec)
  if (!_.isNil(inChineseStock) && !_.isNil(cbmSet)) {
    return inChineseStock * cbmSet
  }
}

export const calcGw = (sku: ISku): number | undefined => {
  const inChineseStock = sku.inChineseStock
  const gwSet = sku.asin.containerSpec?.gwSet
  if (!_.isNil(inChineseStock) && !_.isNil(gwSet)) {
    return inChineseStock * gwSet
  }
}

export const calcPendingInboundShipmentQuantity = (inboundItems: IInboundItem[]) => {
  return inboundItems.reduce((sum: number, inboundItem) => {
    return sum + inboundItem.quantityShipped - inboundItem.quantityReceived
  }, 0)
}

export const calcAvaFC = (sku: ISku): number => {
  return sku.fulfillableQuantity + sku.pendingTransshipmentQuantity
}

export const calcInShip3 = (sku: ISku): number => {
  const sales30d = sku.sales30d
  const avaFC = calcAvaFC(sku)
  const quantityOrderedTotal = calcPendingInboundShipmentQuantity(sku.inboundItems)
  const inChineseStock = sku.inChineseStock
  return sales30d * 3 - avaFC - quantityOrderedTotal - inChineseStock
}

export const calcCloseValue = (sku: ISku, coefficient: number): number => {
  const sales30d = sku.sales30d
  const avaFC = calcAvaFC(sku)
  const quantityOrderedTotal = calcPendingInboundShipmentQuantity(sku.inboundItems)
  const inChineseStock = sku.inChineseStock
  const waitForBuying = sku.waitForBuying
  const goingToFw = sku.goingToFw
  return (
    sales30d * coefficient -
    avaFC -
    quantityOrderedTotal -
    inChineseStock -
    waitForBuying -
    goingToFw
  )
}

export const calcCloseFwValue = (sku: ISku, coefficient: number) => {
  const sales30d = sku.sales30d
  const avaFC = calcAvaFC(sku)
  const quantityOrderedTotal = calcPendingInboundShipmentQuantity(sku.inboundItems)
  return sales30d * coefficient - avaFC - quantityOrderedTotal
}

export const generateShipmentUrl = (shipmentId: string) => {
  return `https://sellercentral.amazon.com/fba/inbound-shipment/summary/${shipmentId}/contents`
}
