import _ from 'lodash'
import { useState, useEffect, useContext } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box, Button, IconButton, InputBase, TablePagination, Toolbar, styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import SettingsIcon from '@mui/icons-material/Settings'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useGlobalFilter, usePagination, useTable, useSortBy, useFilters } from 'react-table'
import { PRODUCT_LABEL, PRODUCT_TABLE_COLUMNS, STICKY_COLUMNS } from './constants'
import ProductRow from './RowProduct'
import { grey } from '@mui/material/colors'
import { AioProductsContext } from '../../contexts/aio-products/aio-products.context'
import TableSettingsModal from './ModalTableSettings'
import { IAioSku } from '../../shared/interfaces-v2'
import { AccountUtils } from '../../utils'
import { useSearchSkus } from '../../hooks'

const TitleHeaderTableCell = styled(TableCell)`
  position: sticky;
  top: 0px;
  height: 50px;
  padding: 4px;
  border-right: 1px solid ${grey[100]};
  background: ${grey[50]};
`

const FilterHeaderTableCell = styled(TableCell)`
  position: sticky;
  padding: 4px;
  border-right: 1px solid ${grey[100]};
  background: ${grey[50]};
  top: 58.5px;
`

export default function ProductDashboard() {
  const [keyword, setKeyword] = useState<string>('')
  const [openTableSettingsModal, setOpenTableSettingsModal] = useState<boolean>(false)
  const [matchedAioProducts, setMatchedAioProducts] = useState<IAioSku[]>([])

  const { aioProducts, setAioProducts, hiddenColumnIds } = useContext(AioProductsContext)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    page,
    gotoPage,
    setPageSize,
    setHiddenColumns,
    state: {
      // globalFilter,
      pageIndex,
      pageSize,
    },
  } = useTable(
    {
      // @ts-ignore
      columns: PRODUCT_TABLE_COLUMNS,
      data: matchedAioProducts,
      initialState: {
        pageSize: 50,
        hiddenColumns: hiddenColumnIds,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  )

  const { data: fetchedAioSkus = [] } = useSearchSkus<IAioSku>(
    {
      isDeleted: false,
    },
    {
      populate: ['asin', 'inboundItems'],
      groupBy: 'asin.asinCode',
    },
  )

  useEffect(() => {
    setAioProducts(fetchedAioSkus)
  }, [fetchedAioSkus])

  useEffect(() => {
    const newMatchedAioProducts = aioProducts.filter((aioSku: IAioSku): boolean => {
      const asinFieldsForSearching = ['asinCode', 'name']
      const skuFieldsForSearching = ['fnsku', 'sku']
      const shipmentFieldsForSearching = ['shipmentId']

      const isMatchedAccount = (_aioSku: IAioSku): boolean => {
        return AccountUtils.getAccountName(_aioSku.asin.accountId).includes(keyword)
      }

      const isMatchedAsinField = (_aioSku: IAioSku): boolean => {
        return asinFieldsForSearching.some((field) => {
          const valueToCheckMatch = _.get(_aioSku.asin, field, '').toLocaleLowerCase()
          return valueToCheckMatch.includes(keyword)
        })
      }

      const isMatchedSkusField = (_aioSku: IAioSku): boolean => {
        const skus = [_aioSku, ..._aioSku.others]
        return skuFieldsForSearching.some((field) => {
          return skus.some((sku) => {
            const valueToCheckMatch = _.get(sku, field, '').toLocaleLowerCase()
            return valueToCheckMatch.includes(keyword)
          })
        })
      }

      const isMatchedShipmentsField = (_aioSku: IAioSku): boolean => {
        return shipmentFieldsForSearching.some((field) => {
          return _aioSku.inboundItems.some((inboundItem) => {
            const valueToCheckMatch = _.get(inboundItem, field, '').toLocaleLowerCase()
            return valueToCheckMatch.includes(keyword)
          })
        })
      }
      return (
        _.isEmpty(keyword) ||
        isMatchedAccount(aioSku) ||
        isMatchedAsinField(aioSku) ||
        isMatchedSkusField(aioSku) ||
        isMatchedShipmentsField(aioSku)
      )
    })
    setMatchedAioProducts(newMatchedAioProducts)
  }, [aioProducts, keyword])

  useEffect(() => {
    setHiddenColumns(hiddenColumnIds)
  }, [hiddenColumnIds])

  const handleChangeKeyword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setKeyword(event.target.value.toLocaleLowerCase())
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setGlobalFilter(keyword)
  }

  return (
    <>
      <Toolbar disableGutters>
        <Paper
          id="search-form"
          onSubmit={handleSubmit}
          component="form"
          sx={{ display: 'flex', alignItems: 'center', width: 400 }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            value={keyword}
            onChange={handleChangeKeyword}
            data-testid="search-form-input"
          />
          <IconButton
            type="submit"
            sx={{ p: '10px' }}
            aria-label="search"
            onClick={() => {
              setGlobalFilter(keyword)
            }}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        <Box sx={{ flexGrow: 1 }} />
        <TablePagination
          component="div"
          count={rows.length}
          page={pageIndex}
          onPageChange={(event, newPageIndex) => {
            gotoPage(newPageIndex)
          }}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(event) => {
            setPageSize(parseInt(event.target.value))
          }}
          rowsPerPageOptions={[25, 50, 100]}
          data-testid="pagination"
        />
        <Button
          id="open-table-settings-button"
          onClick={() => {
            setOpenTableSettingsModal(true)
          }}
        >
          <SettingsIcon />
        </Button>
      </Toolbar>
      <TableContainer sx={{ height: 'calc(100vh - 170px)' }}>
        <Table id="table-products" {...getTableProps()} stickyHeader>
          <TableHead>
            {headerGroups.map((headerGroup, idx) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={idx}>
                {headerGroup.headers.map((column, idx) => {
                  return (
                    <TitleHeaderTableCell
                      {...column.getHeaderProps(
                        column.disableSortBy ? {} : column.getSortByToggleProps(),
                      )}
                      size="small"
                      align="center"
                      {...column.getHeaderProps()}
                      sx={
                        idx < STICKY_COLUMNS.length
                          ? {
                              left: STICKY_COLUMNS[idx].left,
                              zIndex: 100,
                              minWidth: STICKY_COLUMNS[idx].width,
                              maxWidth: STICKY_COLUMNS[idx].width,
                            }
                          : {
                              minWidth: _.get(column, 'fixedWidth') ?? 100,
                              maxWidth: _.get(column, 'fixedWidth') ?? 100,
                            }
                      }
                      data-testid={`table-header-${column.id}`}
                    >
                      {_.get(PRODUCT_LABEL, column.id)}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KeyboardArrowDownIcon
                            sx={{ verticalAlign: 'top' }}
                            data-testid={`arrow-down-${column.id}`}
                          />
                        ) : (
                          <KeyboardArrowUpIcon
                            sx={{ verticalAlign: 'top' }}
                            data-testid={`arrow-up-${column.id}`}
                          />
                        )
                      ) : (
                        ''
                      )}
                    </TitleHeaderTableCell>
                  )
                })}
              </TableRow>
            ))}
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, idx) => {
                  return (
                    <FilterHeaderTableCell
                      size="small"
                      align="center"
                      {...column.getHeaderProps()}
                      sx={
                        idx < STICKY_COLUMNS.length
                          ? {
                              left: STICKY_COLUMNS[idx].left,
                              zIndex: 100,
                              minWidth: STICKY_COLUMNS[idx].width,
                              maxWidth: STICKY_COLUMNS[idx].width,
                            }
                          : {}
                      }
                      data-testid={`filter-header-${column.id}`}
                    >
                      {column.Filter ? column.render('Filter') : null}
                    </FilterHeaderTableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return <ProductRow row={row} key={row.original.asin.asinCode} />
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableSettingsModal
        openHandler={{
          value: openTableSettingsModal,
          set: setOpenTableSettingsModal,
        }}
      />
    </>
  )
}
