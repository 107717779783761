import { IconButton, InputBase, Paper } from '@mui/material'
import { ICompetition } from '../../shared/interfaces'
import { useEffect, useState } from 'react'
import CompetitionItem from './CompetitionItem'
import SearchIcon from '@mui/icons-material/Search'
import _ from 'lodash'
import { useAccountStore, useCompetitionStore, useSearchCompetitions } from '../../hooks'

export default function Competition() {
  const { activeAccount } = useAccountStore()
  const { competitions, setCompetitions } = useCompetitionStore()

  const [matchedCompetitions, setMatchedCompetitions] = useState<ICompetition[]>([])
  const [keyword, setKeyword] = useState<string>('')

  const { data: fetchedCompetitions } = useSearchCompetitions(
    {
      accountId: activeAccount?.id ?? '',
    },
    {
      populate: ['crAsin', 'competitors', 'competitors.crAsin'],
    },
    !!activeAccount,
  )

  useEffect(() => {
    if (_.isNil(fetchedCompetitions)) return
    setCompetitions(fetchedCompetitions)
  }, [fetchedCompetitions])

  const handleChangeKeyword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setKeyword(event.target.value)
  }

  useEffect(() => {
    if (_.isNil(competitions)) return
    if (_.isEmpty(keyword)) {
      setMatchedCompetitions(competitions)
      return
    }
    const newMatchedCompetitions = competitions.filter((competition) => {
      const poolValues = [
        competition.asinCode,
        competition.name,
        ..._.map(competition.competitors, 'asin'),
        ..._.map(competition.competitors, 'name'),
      ]
      return _.some(poolValues, (value) => _.includes(_.toLower(value), _.toLower(keyword)))
    })

    setMatchedCompetitions(newMatchedCompetitions)
  }, [competitions, keyword])

  return (
    <>
      <Paper
        id="search-form"
        component="form"
        sx={{ display: 'flex', alignItems: 'center', width: 400 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          value={keyword}
          onChange={handleChangeKeyword}
          data-testid="keyword-input"
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      {matchedCompetitions.map((competition) => (
        <CompetitionItem competition={competition} key={competition.asinCode} />
      ))}
    </>
  )
}
