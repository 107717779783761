import { Table } from '@mantine/core'
import _ from 'lodash'
import dayjs from '../../utils/dayjs.utils'
import { DATE_FORMAT } from '../../shared/constants'
import { useAccountStore, useSearchRpCampaignDetailSummaries } from '../../hooks'
import { RxTriangleRight } from 'react-icons/rx'
import { TiMinus } from 'react-icons/ti'

interface RowRpAdGroupProps {
  campaignId: number
  dateRange: [Date, Date]
}

const RpCampaignDetail = ({ campaignId, dateRange }: RowRpAdGroupProps) => {
  const activeAccount = useAccountStore((state) => state.activeAccount)
  const { data: keywordSummaries } = useSearchRpCampaignDetailSummaries({
    startDate: dayjs(dateRange[0]).format(DATE_FORMAT.API),
    endDate: dayjs(dateRange[1]).format(DATE_FORMAT.API),
    accountId: activeAccount?.id ?? '',
    campaignId,
  })
  return (
    <>
      {keywordSummaries
        ? keywordSummaries.map((item) => (
            <>
              <Table.Tr key={item.keyword}>
                <Table.Td />
                <Table.Td
                  style={{ display: 'flex', alignItems: 'center', textAlign: 'left', gap: 8 }}
                >
                  <RxTriangleRight />
                  {
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    `${item.keyword} (${item.keywordType})`
                  }
                </Table.Td>
                <Table.Td />
                <Table.Td>{item.profit}</Table.Td>
                <Table.Td />
                <Table.Td>{item.status}</Table.Td>
                <Table.Td>{item.spend}</Table.Td>
                <Table.Td>{item.keywordBid}</Table.Td>
                <Table.Td />
                <Table.Td />
                <Table.Td />
                <Table.Td>{item.topOfSearchIS}</Table.Td>
                <Table.Td>{item.impressions}</Table.Td>
                <Table.Td>{item.clicks}</Table.Td>
                <Table.Td>
                  {item.impressions ? _.round((item.clicks / item.impressions) * 100, 2) : '-'}
                </Table.Td>
                <Table.Td>{item.clicks ? _.round(item.cost / item.clicks, 2) : '-'}</Table.Td>
                <Table.Td>{item.sales}</Table.Td>
                <Table.Td>{item.purchases}</Table.Td>
                <Table.Td>{item.unitsSoldClicks}</Table.Td>
                <Table.Td>{item.totalReferralFee}</Table.Td>
                <Table.Td>{item.totalFbaFee}</Table.Td>
                <Table.Td>{item.totalCogs}</Table.Td>
                <Table.Td>{item.sales ? _.round(item.spend / item.sales, 2) : '-'}</Table.Td>
                <Table.Td />
                <Table.Td>{item.spend ? _.round(item.sales / item.spend, 2) : '-'}</Table.Td>
                <Table.Td />
                <Table.Td />
                <Table.Td />
              </Table.Tr>
              {item.searchTermSummaries.map((searchTermSummary: any) => (
                <Table.Tr key={searchTermSummary.keywordId}>
                  <Table.Td />
                  <Table.Td
                    style={{ display: 'flex', alignItems: 'center', textAlign: 'left', gap: 8 }}
                    pl={24}
                  >
                    <TiMinus /> {searchTermSummary.searchTerm}
                  </Table.Td>
                  <Table.Td />
                  <Table.Td>{searchTermSummary.profit}</Table.Td>
                  <Table.Td />
                  <Table.Td>{searchTermSummary.status}</Table.Td>
                  <Table.Td>{searchTermSummary.spend}</Table.Td>
                  <Table.Td>{searchTermSummary.keywordBid}</Table.Td>
                  <Table.Td>{searchTermSummary.campaignBiddingStrategy}</Table.Td>
                  <Table.Td>{searchTermSummary.campaignBudgetAmount}</Table.Td>
                  <Table.Td />
                  <Table.Td>{searchTermSummary.topOfSearchIS}</Table.Td>
                  <Table.Td>{searchTermSummary.impressions}</Table.Td>
                  <Table.Td>{searchTermSummary.clicks}</Table.Td>
                  <Table.Td>
                    {searchTermSummary.impressions
                      ? _.round((searchTermSummary.clicks / searchTermSummary.impressions) * 100, 2)
                      : '-'}
                  </Table.Td>
                  <Table.Td>
                    {searchTermSummary.clicks
                      ? _.round(searchTermSummary.cost / searchTermSummary.clicks, 2)
                      : '-'}
                  </Table.Td>
                  <Table.Td>{searchTermSummary.sales}</Table.Td>
                  <Table.Td>{searchTermSummary.purchases}</Table.Td>
                  <Table.Td>{searchTermSummary.unitsSoldClicks}</Table.Td>
                  <Table.Td>{searchTermSummary.totalReferralFee}</Table.Td>
                  <Table.Td>{searchTermSummary.totalFbaFee}</Table.Td>
                  <Table.Td>{searchTermSummary.totalCogs}</Table.Td>
                  <Table.Td>
                    {searchTermSummary.sales
                      ? _.round(searchTermSummary.spend / searchTermSummary.sales, 2)
                      : '-'}
                  </Table.Td>
                  <Table.Td />
                  <Table.Td>
                    {searchTermSummary.spend
                      ? _.round(searchTermSummary.sales / searchTermSummary.spend, 2)
                      : '-'}
                  </Table.Td>
                  <Table.Td />
                  <Table.Td />
                  <Table.Td />
                </Table.Tr>
              ))}
            </>
          ))
        : null}
    </>
  )
}

export default RpCampaignDetail
