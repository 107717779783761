import { useQuery } from '@tanstack/react-query'
import { AuthRequest } from './request'
import { QUERY_KEY } from '../../constants'
import { IAccount } from '../../shared/interfaces'

export const useGetAllAccounts = () => {
  return useQuery({
    queryKey: [QUERY_KEY.GET_ALL_ACCOUNTS],
    queryFn: async (): Promise<IAccount[]> => {
      const { data } = await AuthRequest.get('accounts')
      return data
    },
  })
}
