import { Controller, useForm } from 'react-hook-form'
import { Box, Button, Flex, Group, Modal, Select, TextInput, useMantineTheme } from '@mantine/core'
import {
  ActionType,
  KeywordAttribute,
  Operator,
  ScriptType,
  SearchTermAttribute,
} from '../../../shared/enums'
import { IScript } from '../../../shared/interfaces-v2'
import CaseFields from './CaseFields'
import { IFormScript } from './type'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  CreateScriptPayload,
  useCreateScript,
  useDeleteScript,
  useUpdateScript,
} from '../../../hooks/api/useScript'
import { XDAYS_SUPPORTED_ATTRIBUTES } from '../../../shared/constants'
import { DEFAULT_CONDITION } from './ConditionFields'

interface Props {
  script?: IScript
  opened: boolean
  onClose: () => void
}

const IScriptSchema = yup.object().shape({
  id: yup
    .string()
    .matches(/^[A-Z0-9]{6}$/, 'Script ID must be 6 uppercase letters and numbers')
    .required(),
  name: yup.string().required('This field is required'),
  description: yup.string(),
  scriptType: yup.string().oneOf(Object.values(ScriptType)).required('This field is required'),
  cases: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        enabledAlert: yup.boolean().required('This field is required'),
        actionType: yup.string().oneOf(Object.values(ActionType)).nullable().optional(),
        actionConfig: yup.object().when('actionType', ([actionType], schema) => {
          switch (actionType) {
            case ActionType.UPDATE_BID_ACOS_XDAYS:
              return schema.shape({
                xDays: yup.number().required('This field is required'),
                minBid: yup.number().required('This field is required'),
                maxBid: yup.number().required('This field is required'),
              })
            case ActionType.UPDATE_BID_FIXED_DELTA:
              return schema.shape({
                delta: yup.number().required('This field is required'),
                minBid: yup.number().required('This field is required'),
                maxBid: yup.number().required('This field is required'),
                minDelta: yup.number().required('This field is required'),
              })
            case ActionType.UPDATE_BID_FIXED_VALUE:
              return schema.shape({
                value: yup.number().required('This field is required'),
              })
            case ActionType.UPDATE_BID_PERCENTAGE:
              return schema.shape({
                percentage: yup.number().required('This field is required'),
                minBid: yup.number().required('This field is required'),
                maxBid: yup.number().required('This field is required'),
                minDelta: yup.number().required('This field is required'),
              })
            default:
              return schema
          }
        }),
        conditions: yup
          .array()
          .min(1)
          .of(
            yup.object().shape({
              attribute: yup
                .string()
                .oneOf(
                  [...Object.values(SearchTermAttribute), ...Object.values(KeywordAttribute)],
                  'This field is required',
                )
                .required(),
              xDays: yup.number().when('attribute', {
                is: (val: any) => XDAYS_SUPPORTED_ATTRIBUTES.includes(val),
                then: (schema) => schema.required('This field is required'),
              }),
              operator: yup
                .string()
                .oneOf(Object.values(Operator), 'This field is required')
                .required(),
              value: yup.string().required('This field is required'),
            }),
          )
          .required(),
      }),
    )
    .required(),
})

const ModalScript = ({ script, opened, onClose }: Props) => {
  const theme = useMantineTheme()
  const defaultValues: IFormScript = {
    id: script?.id ?? '',
    name: script?.name ?? '',
    description: script?.description ?? '',
    scriptType: script?.type as ScriptType,
    cases: script?.cases.map((caseScript) => {
      return {
        enabledAlert: caseScript.enabledAlert,
        actionType: caseScript.action?.type,
        actionConfig: caseScript.action?.config,
        conditions: caseScript.conditions.map((condition) => {
          return {
            attribute: condition.attribute,
            xDays: condition.xDays,
            operator: condition.operator,
            value: condition.value,
          }
        }),
      }
    }) ?? [
      {
        enabledAlert: false,
        actionConfig: {},
        conditions: [DEFAULT_CONDITION],
      },
    ],
  }
  const formController = useForm<IFormScript>({
    defaultValues,
    resolver: yupResolver(IScriptSchema),
  })
  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = formController
  const scriptType = watch('scriptType')

  const { mutateAsync: createScript, isPending: isCreating } = useCreateScript()
  const { mutateAsync: updateScript, isPending: isUpdating } = useUpdateScript(script?.id ?? '')
  const { mutate: deleteScript, isPending: isDeleting } = useDeleteScript(script?.id ?? '')

  async function onSubmit(data: IFormScript) {
    const cases: CreateScriptPayload['cases'] = data.cases.map((caseScript) => {
      return {
        enabledAlert: caseScript.enabledAlert,
        action: caseScript.actionType
          ? {
              type: caseScript.actionType,
              config: caseScript.actionConfig,
            }
          : undefined,
        conditions: caseScript.conditions
          ? caseScript.conditions.map((condition) => {
              return {
                attribute: condition.attribute,
                xDays: condition.xDays,
                operator: condition.operator,
                value: condition.value,
              }
            })
          : undefined,
      }
    })
    try {
      if (script) {
        await updateScript({
          name: data.name,
          description: data.description,
          type: data.scriptType,
          cases,
        })
      } else {
        await createScript({
          id: data.id,
          name: data.name,
          description: data.description,
          type: data.scriptType,
          cases,
        })
      }
      onClose()
    } catch (error) {}
  }

  function onDelete() {
    if (window.confirm('Are you sure you want to delete this script?')) {
      deleteScript(undefined, {
        onSuccess: () => {
          onClose()
        },
      })
    }
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnClickOutside={false}
      title={script ? 'Edit Script' : 'Create Script'}
      size={'xl'}
      centered
      styles={{
        inner: {
          paddingTop: 100,
        },
        body: {
          paddingBottom: 0,
        },
      }}
      id="form-setup-script"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box pb={24}>
        <Flex gap={8}>
          <Controller
            name="id"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextInput
                name="id"
                label="ID"
                onChange={(e) => onChange(e.target.value.toUpperCase())}
                value={value}
                data-testid="input-id"
                required
                disabled={!!script}
                error={error?.message}
                flex={1}
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="name"
                label="Name"
                onChange={onChange}
                value={value}
                data-testid="input-name"
                required
                flex={1}
              />
            )}
          />
          <Controller
            name="scriptType"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                name="scriptType"
                label="Type"
                placeholder="Select type"
                value={value}
                onChange={onChange}
                data={[...Object.values(ScriptType)]}
                data-testid="select-script-type"
                disabled={!!value}
                required
                flex={1}
                error={error?.message}
              />
            )}
          />
        </Flex>

        <Controller
          name="description"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              name="description"
              label="Description"
              onChange={onChange}
              value={value}
              data-testid="input-description"
            />
          )}
        />
        {scriptType && <CaseFields formController={formController} />}
      </Box>
      <Group
        py={16}
        bg="white"
        justify="flex-end"
        style={{
          position: 'sticky',
          bottom: 0,
          zIndex: 2,
        }}
      >
        {script && (
          <Button
            variant="outline"
            color={theme.colors.danger[6]}
            data-testid="btn-remove-script"
            mr="auto"
            onClick={onDelete}
            loading={isDeleting}
          >
            Remove
          </Button>
        )}
        <Button
          type="submit"
          data-testid="btn-save-script"
          disabled={!isDirty}
          loading={isCreating || isUpdating}
        >
          Save
        </Button>
      </Group>
    </Modal>
  )
}

export default ModalScript
