import { useQuery } from '@tanstack/react-query'
import { AuthRequest } from './request'
import { QUERY_KEY } from '../../constants'
import { IScriptExecutionLog } from '../../shared/interfaces'

export interface SearchScriptExecutionLogsDto {
  accountId: string
  from: string
}

export const useSearchScriptExecutionLogs = (dto: SearchScriptExecutionLogsDto) => {
  const { accountId, from } = dto
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_AUTOCAMPAIGN_LOGS, accountId, from],
    queryFn: async (): Promise<IScriptExecutionLog[]> => {
      const { data } = await AuthRequest.post(`script-execution-logs/search`, dto)
      return data
    },
    enabled: !!accountId,
  })
}
